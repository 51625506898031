function locale_check_imperial() {
	return Intl.DateTimeFormat().resolvedOptions().locale == 'en-US';
}

export enum CosecConfig {
	// MAP_DISPLAY_NODES_A,
	// MAP_DISPLAY_NODES_B,
	// MAP_DISPLAY_NODES_C,
	// MAP_DISPLAY_NODES_D,
	// MAP_DISPLAY_NODES_E,
	LOCALE_USE_IMPERIAL,
	FORCE_DARK_MODE,
	FORCE_LIGHT_MODE
}

export const CONFIG_NAMES = new Map([
	// [CosecConfig.MAP_DISPLAY_NODES_A, "MAP_DISPLAY_NODES_A"],
	// [CosecConfig.MAP_DISPLAY_NODES_B, "MAP_DISPLAY_NODES_B"],
	// [CosecConfig.MAP_DISPLAY_NODES_C, "MAP_DISPLAY_NODES_C"],
	// [CosecConfig.MAP_DISPLAY_NODES_D, "MAP_DISPLAY_NODES_D"],
	// [CosecConfig.MAP_DISPLAY_NODES_E, "MAP_DISPLAY_NODES_E"],
	[CosecConfig.LOCALE_USE_IMPERIAL, "LOCALE_USE_IMPERIAL"],
	[CosecConfig.FORCE_DARK_MODE, "FORCE_DARK_MODE"],
	[CosecConfig.FORCE_LIGHT_MODE, "FORCE_LIGHT_MODE"]
]);

export interface CosecConfigCookie {
	settings: {[key: string]: boolean}
}

class CosecConfigContainer {
	#config:Map<CosecConfig,boolean>;

	// constructor(data:string = document.cookie) {
	constructor() {
		//XXX: Config defaults
		this.#config = new Map([
			// [CosecConfig.MAP_DISPLAY_NODES_A, false],
			// [CosecConfig.MAP_DISPLAY_NODES_B, false],
			[CosecConfig.FORCE_DARK_MODE, false],
			[CosecConfig.FORCE_LIGHT_MODE, false],
			[CosecConfig.LOCALE_USE_IMPERIAL, locale_check_imperial()],
		]);

		// this.from_json(data);

		this.load_from_storage();

		window.matchMedia(
			"(prefers-color-scheme: dark)"
		).addEventListener(
			"change",
			this.#update_color_scheme.bind(this)
		);
	}

	#update_color_scheme(e:Event) {
		CommonLib.check_color_scheme_override();
	}

	load_from_storage() {
		for(const [key, val] of CONFIG_NAMES.entries()) {
			const setting = localStorage.getItem(val);
			if(setting != null)
				this.#config.set(key, Boolean(setting));
		}
	}

	// to_json() {
	// 	let j:CosecConfigCookie = {settings: {}};

	// 	for(const [key, val] of CONFIG_NAMES.entries())
	// 		j.settings[val] = this.#config.get(key);

	// 	return j;
	// 	// { "settings": {
	// 	// 	"MAP_DISPLAY_NODES_A": this.#config.get(CosecConfig.MAP_DISPLAY_NODES_A),
	// 	// 	"MAP_DISPLAY_NODES_B": this.#config.get(CosecConfig.MAP_DISPLAY_NODES_B),
	// 	// 	"LOCALE_USE_IMPERIAL": this.#config.get(CosecConfig.LOCALE_USE_IMPERIAL),
	// 	// 	"FORCE_DARK_MODE": this.#config.get(CosecConfig.FORCE_DARK_MODE),
	// 	// 	"FORCE_LIGHT_MODE": this.#config.get(CosecConfig.FORCE_LIGHT_MODE),
	// 	// 	}};
	// }

	// from_json(data:string) {
	// 	let stored_settings = null;
	// 	try{
	// 		const stored_cookie_raw = JSON.parse(data);
	// 		if(stored_cookie_raw && stored_cookie_raw.settings)
	// 			stored_settings = stored_cookie_raw.settings;
	// 	}
	// 	catch(e){
	// 		console.warn("Cannot read settings!");
	// 	}

	// 	if(stored_settings) {
	// 		const stored_keys = Object.keys(stored_settings);

	// 		for(const [key, val] of CONFIG_NAMES.entries()) {
	// 			if(stored_keys.includes(val))
	// 				this.#config.set(key, stored_settings[val]);
	// 		}


	// 		// if(stored_settings.MAP_DISPLAY_NODES_A)
	// 		// 	this.#config.set(CosecConfig.MAP_DISPLAY_NODES_A, stored_settings.MAP_DISPLAY_NODES_A);

	// 		// if(stored_settings.MAP_DISPLAY_NODES_B)
	// 		// 	this.#config.set(CosecConfig.MAP_DISPLAY_NODES_B, stored_settings.MAP_DISPLAY_NODES_B);

	// 		// if(stored_settings.FORCE_DARK_MODE)
	// 		// 	this.#config.set(CosecConfig.FORCE_DARK_MODE, stored_settings.FORCE_DARK_MODE);

	// 		// if(stored_settings.FORCE_LIGHT_MODE)
	// 		// 	this.#config.set(CosecConfig.FORCE_LIGHT_MODE, stored_settings.FORCE_LIGHT_MODE);

	// 		// if(stored_settings.LOCALE_USE_IMPERIAL)
	// 		// 	this.#config.set(CosecConfig.LOCALE_USE_IMPERIAL, stored_settings.LOCALE_USE_IMPERIAL);

	// 	}
	// }

	get(key:CosecConfig){
		return this.#config.has(key) ? this.#config.get(key) : null;
	}

	set(key:CosecConfig, value:boolean){
		this.#config.set(key, value);
		localStorage.setItem(CONFIG_NAMES.get(key), value.toString());
		// document.cookie = JSON.stringify(this.to_json());
	}

	has(key:CosecConfig){
		return this.#config.has(key);
	}
}

export class CommonLib {
    static t_zero = new Date(0);
    static t_one_week = 1000 * 60 * 60 * 24 * 7;
	static lastId = 0;

	static config = new CosecConfigContainer();

    static set_locale_use_imperial(onoff:boolean) {
        CommonLib.config.set(CosecConfig.LOCALE_USE_IMPERIAL, onoff);
    }

    static get_locale_use_imperial() {
        // return CommonLib._use_imperial;
		return CommonLib.config.get(CosecConfig.LOCALE_USE_IMPERIAL);
    }

    static locale_get_distance(metric_dist:number) {
        return CommonLib.get_locale_use_imperial() ? 3.28084 * metric_dist : metric_dist;
    }

    static locale_get_distance_unit() {
        return CommonLib.get_locale_use_imperial() ? "ft" : "m";
    }

    static locale_get_temperature(metric_temp:number) {
        return CommonLib.get_locale_use_imperial() ? (metric_temp * 9 / 5) + 32 : metric_temp;
    }

    static locale_get_temperature_unit() {
        return CommonLib.get_locale_use_imperial() ? "°F" : "°C";
    }

    static locale_get_pressure(metric_press:number) {
        return CommonLib.get_locale_use_imperial() ? metric_press * 0.000145038 : metric_press;
    }

    static locale_get_pressure_unit() {
        return CommonLib.get_locale_use_imperial() ? "psi" : "Pa";
    }

	static check_color_scheme_override() {
		// var currentTheme = document.documentElement.getAttribute("data-theme");
		// var targetTheme = (currentTheme === "light") ? "dark" : "light";
		const preferred_theme = (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");

		if(CommonLib.config.get(CosecConfig.FORCE_DARK_MODE)) {
			document.documentElement.setAttribute('data-theme',  "dark");
		} else if(CommonLib.config.get(CosecConfig.FORCE_LIGHT_MODE)) {
			document.documentElement.setAttribute('data-theme',  "light");
		} else if (preferred_theme) {
			//Follow system theme
			document.documentElement.setAttribute('data-theme', preferred_theme);
		} else {
			//Default to light mode if not supported
			document.documentElement.setAttribute('data-theme',  "light");
		}
		// localStorage.setItem('theme', targetTheme);


		// if (CommonLib.config.get(CosecConfig.FORCE_DARK_MODE)) {
		// 	document.documentElement.classList.add('force-prefers-dark');
		// } else {
		// 	document.documentElement.classList.remove('force-prefers-dark');
		// }
	}


	//TODO: Document
	static zero_pad(num:number, places:number) {
		return String(num).padStart(places, '0');
	}

	//TODO: Document
	static CosecUID(prefix='cuid-') {
		CommonLib.lastId++;
		return `${prefix}${CommonLib.lastId}`;
	}

	//TODO: Document
	static mm_to_px(mm:number) {
		const default_dpi = 96;
		return (mm / 25.4) * window.devicePixelRatio * default_dpi;
	}

	//TODO: Type
	//@ts-ignore
	static create_refresh_observer(element_to_observe, callback_refresh, start_observing) {
		// Callback function to execute when mutations are observed
		//@ts-ignore
		const callback = function(mutationsList, observer) {
			// Use traditional 'for loops' for IE 11
			for(const mutation of mutationsList) {
				if (mutation.type === 'attributes') {
					if ( (mutation.attributeName == 'style') && (mutation.target.style.display == 'inline')) {
						callback_refresh();
					}

					//console.log('The ' + mutation.attributeName + ' attribute was modified.');
					//console.log(mutation);
				}
			}
		};

		// Create an observer instance linked to the callback function
		let observer = new MutationObserver(callback);

		if(start_observing) {
			const targetNode = document.getElementById(element_to_observe);
			const config = { attributes: true, childList: false, subtree: false };

			observer.observe(targetNode, config);
		}

		return observer;
	}

	static rand_color(str:string):string {
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}
		let color = '#';
		for (let j = 0; j < 3; j++) {
			let value = (hash >> (j * 8)) & 0xFF;
			let c = '00' + value.toString(16);
			color += c.substring(c.length-2);
		}
		return color;
	}

	static time_since(date:Date, date_now:Date = new Date()):string {
		const time_now = date_now.valueOf();
		const time_other = date.valueOf();
		let seconds = Math.floor((time_now - time_other) / 1000);
		let interval = seconds / 31536000;

		if (interval > 1) {
			return Math.floor(interval) + " years ago";
		}
		interval = seconds / 2592000;
		if (interval > 1) {
			return Math.floor(interval) + " months ago";
		}
		interval = seconds / 86400;
		if (interval > 1) {
			return Math.floor(interval) + " days ago";
		}
		interval = seconds / 3600;
		if (interval > 1) {
			return Math.floor(interval) + " hours ago";
		}
		interval = seconds / 60;
		if (interval > 1) {
			return Math.floor(interval) + " minutes ago";
		}

		if (seconds > 1) {
			return Math.floor(seconds) + " seconds ago";
		}

		return "just now";
	}

	static time_from_ms(duration:number): string {
		let milliseconds = Math.floor((duration % 1000) / 100);
		let seconds = Math.floor((duration / 1000) % 60);
		let minutes = Math.floor((duration / (1000 * 60)) % 60);
		let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

		let str_hours = (hours < 10) ? "0" + hours : hours;
		let str_minutes = (minutes < 10) ? "0" + minutes : minutes;
		let str_seconds = (seconds < 10) ? "0" + seconds : seconds;
		let str_milliseconds = milliseconds.toString();

		return str_hours + ":" + str_minutes + ":" + str_seconds + "." + str_milliseconds;
	}

	static add_months(date:Date, months:number) {
		let new_date = new Date(date);
		new_date.setMonth(date.getMonth() + months);

		if (new_date.getDate() != date.getDate()) {
			new_date.setDate(0);
		}

		return new_date;
	}

	static add_days(date:Date, days:number) {
		let new_date = new Date(date);
		new_date.setDate(date.getDate() + days);

		return new_date;
	}

	static add_milliseconds(date:Date, ms:number) {
		return new Date(date.getTime() + ms);
	}

	static add_seconds(date:Date, sec:number) {
		return CommonLib.add_milliseconds(date, sec*1000);
	}

	/**
	 * @param  {any} dataset Enumeration of the page dataset variables
	 *
	 */
	static keys_from_dataset(dataset:any) {
		return Object.keys(dataset).filter(key => isNaN(Number(key)));
	}

	/**
	 * @param  {any} dataset Enumeration of the page dataset variables
	 * @param  {string[]} definitions List of comparative variables to match against the enum lookup
	 * @param  {string} label Label to display in error message
	 */
	static assert_dataset_layout(dataset:any, definitions:any[], label:string) {
		console.assert(definitions.length == CommonLib.keys_from_dataset(dataset).length, `Mismatch in variable definitions (${label})`);
	}

	/** Generates a generic file name based on the current time and date
	 * @param  {string} ext file extension to give the file
	 */
	static get_filename(ext:string) {
		return `cosec-${Date.now()}.${ext}`;
	}

	/** Initiates a file download for a given data blob
	 * @param  {string} filename Filename preset of the downloaded file
	 * @param  {Blob} data File data to be downloaded
	 */
	static download_file(filename:string, data:Blob) {
		var element = document.createElement('a');
		const burl = URL.createObjectURL(data);
		element.setAttribute('href', burl);
		element.setAttribute('download', filename);

		element.style.display = 'none';
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	}
}

/** Generates a generic file name based on the current time and date
 * @param  {string} ext file extension to give the file
 */
 export function get_filename(ext:string, suffix="") {
    return `my_dte_${Date.now()}${suffix}.${ext}`;
}

/** Initiates a file download for a given data blob
 * @param  {string} filename Filename preset of the downloaded file
 * @param  {Blob} data File data to be downloaded
 */
export async function download_file(filename:string, data:Blob) {
    var element = document.createElement('a');
    const burl = URL.createObjectURL(data);
    element.setAttribute('href', burl);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export class CosecGeoPoint {
    latitude:number = 0.0;
    longitude:number = 0.0;
	altitude:number = null;

    constructor(latitude = 0.0, longitude = 0.0, altitude:number = null) {
        this.latitude = latitude;
        this.longitude = longitude;
		this.altitude = altitude;
    }
}
