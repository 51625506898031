// import { DBTools } from "./db_tools";

import * as fireapp from 'firebase/app';
import * as firestore from 'firebase/firestore';
import * as fireauth from 'firebase/auth';
import { InterfaceDatabase, InterfaceDeviceSensor, InterfaceDeviceFeatures, InterfaceHub, InterfaceHubFeatures, InterfaceHubHardware, InterfaceReading, InterfaceReadingAmbience, InterfaceReadingInfo, InterfaceReadingPower, InterfaceReadingRange, InterfaceReadingThresholds, InterfaceUserProfile, InterfaceDeviceType, InterfaceDeviceBase, InterfaceDeviceStatic } from './interface_database';
import { DATASET_ID, DBTools, InterfaceHubGeoFeatures } from './db_tools';
import { DocumentData } from 'firebase/firestore';
import { CosecGeoPoint } from './common';
import { WidgetMinNomMaxType } from './dom_factory';

//Set up all of our core firebase connections
export const cosec_firebase_config:fireapp.FirebaseOptions = {
    // cspell:disable-next-line
    apiKey: "AIzaSyBol-6a4Y_JF3UCYDzxii6AOuP4DQLhaSI",
    authDomain: "cosec-74166.firebaseapp.com",
    projectId: "cosec-74166",
    storageBucket: "cosec-74166.appspot.com",
    messagingSenderId: "940758559592",
    // cspell:disable-next-line
    appId: "1:940758559592:web:da9a061e5aa9188d3b84dd",
    // cspell:disable-next-line
    measurementId: "G-KL123YZ6MR",
};

function _date_from_stamp(stamp:firestore.Timestamp) {
    const date = new Date (stamp.seconds * 1000 + Math.round(stamp.nanoseconds / 1000000));
    return date;
}

function _clean_geopoint(data:firestore.GeoPoint) {
    return new CosecGeoPoint(data.latitude, data.longitude);
}

function _clean_hub_data(data:DocumentData) {
    const features:InterfaceHubFeatures = {
        GNET: data.features ? Boolean(data.features.GNET) : false,
        MESH: data.features ? Boolean(data.features.MESH) : false,
        OTA_UPDATE: data.features ? Boolean(data.features.OTA_UPDATE) : false,
        USER_IO: data.features ? Boolean(data.features.USER_IO) : false,
        WIFI: data.features ? Boolean(data.features.WIFI) : false
    };

    const hardware:InterfaceHubHardware = {
        model: (data.hardware && data.hardware.model) ? data.hardware.model.toString() : "",
        uuid: (data.hardware && data.hardware.uuid) ? data.hardware.uuid.toString() : ""
    }

    const geo_features:InterfaceHubGeoFeatures[] = [];
    for(const f of data.geo_features) {
        const key:InterfaceHubGeoFeatures = InterfaceHubGeoFeatures[f as keyof typeof InterfaceHubGeoFeatures];
        geo_features.push(key);
    }

    const hub:InterfaceHub = {
        admin: data.admin ? data.admin.toString() : "",
        users: (data.users && data.users.length) ? data.users : [],
        owner: data.owner ? data.owner.toString() : "",
        name: data.name ? data.name.toString() : "",
        activation_secret: data.activation_secret ? data.activation_secret.toString() : "",
        archived: Boolean(data.archived),
        identify: Boolean(data.identify),
        location: data.location ? _clean_geopoint(data.location) : new CosecGeoPoint(),
        date_allocated: data.date_allocated ? _date_from_stamp(data.date_allocated) : DBTools.date_invalid,
        date_activated: data.date_activated ? _date_from_stamp(data.date_activated) : DBTools.date_invalid,
        date_boot: data.date_boot ? _date_from_stamp(data.date_boot) : DBTools.date_invalid,
        date_contacted: data.date_contacted ? _date_from_stamp(data.date_contacted) : DBTools.date_invalid,
        date_updated: data.date_updated ? _date_from_stamp(data.date_updated) : DBTools.date_invalid,
        features: features,
        hardware: hardware,
        geo_features: geo_features,
    };

    return hub;
}

function _clean_device_static_data(data:DocumentData, type:InterfaceDeviceType) {

    const device:InterfaceDeviceStatic = {
        type: type,
        archived: Boolean(data.archived),
        timestamp: data.timestamp ? _date_from_stamp(data.timestamp) : DBTools.date_invalid,
        uuid: data.uuid ? data.uuid.toString() : "",
        location: data.location ? _clean_geopoint(data.location) : new CosecGeoPoint(),
        name: data.name ? data.name.toString() : "",
        date_install: data.date_install ? _date_from_stamp(data.date_install) : DBTools.date_invalid,
        date_review: data.date_review ? _date_from_stamp(data.date_review) : DBTools.date_invalid,
        review: data.review ? Number(data.review) : 0,
        warranty: data.warranty ? Number(data.warranty) : 0,
        renewal: data.renewal ? Number(data.renewal) : 0,
    };

    return device;
}

function _clean_device_sensor_data(data:DocumentData) {
    const features:InterfaceDeviceFeatures = {
        ANGLE: data.features ? Boolean(data.features.ANGLE) : false,
        DISTANCE: data.features ? Boolean(data.features.DISTANCE) : false,
        HUMIDITY: data.features ? Boolean(data.features.HUMIDITY) : false,
        IDENTIFY: data.features ? Boolean(data.features.IDENTIFY) : false,
        PRESSURE: data.features ? Boolean(data.features.PRESSURE) : false,
        RELEASE: data.features ? Boolean(data.features.RELEASE) : false,
        SOLAR_V: data.features ? Boolean(data.features.SOLAR_V) : false,
        TEMPERATURE: data.features ? Boolean(data.features.TEMPERATURE) : false,
        VOLTAGE: data.features ? Boolean(data.features.VOLTAGE) : false
    };

    const thresholds:InterfaceReadingThresholds = {
        min: data.thresholds && data.thresholds.min ? _clean_reading_data(data.thresholds.min) : null,
        nom: data.thresholds && data.thresholds.nom ? _clean_reading_data(data.thresholds.nom) : null,
        max: data.thresholds && data.thresholds.max ? _clean_reading_data(data.thresholds.max) : null,
    }

    const device:InterfaceDeviceSensor = {
        type: InterfaceDeviceType.ROTASENSOR,
        archived: Boolean(data.archived),
        identify: Boolean(data.identify),
        release: Boolean(data.release),
        date_boot: data.date_boot ? _date_from_stamp(data.date_boot) : DBTools.date_invalid,
        date_contacted: data.date_contacted ? _date_from_stamp(data.date_contacted) : DBTools.date_invalid,
        timestamp: data.timestamp ? _date_from_stamp(data.timestamp) : DBTools.date_invalid,
        uuid: data.uuid ? data.uuid.toString() : "",
        location: data.location ? _clean_geopoint(data.location) : new CosecGeoPoint(),
        name: data.name ? data.name.toString() : "",
        msg_type: Number(data.msg_type),
        features: features,
        thresholds: thresholds
    };

    return device;
}


function _clean_reading_info_data(data:DocumentData) {
    let features:InterfaceDeviceFeatures = {
        ANGLE: data.features ? Boolean(data.features.ANGLE) : false,
        DISTANCE: data.features ? Boolean(data.features.DISTANCE) : false,
        HUMIDITY: data.features ? Boolean(data.features.HUMIDITY) : false,
        IDENTIFY: data.features ? Boolean(data.features.IDENTIFY) : false,
        PRESSURE: data.features ? Boolean(data.features.PRESSURE) : false,
        RELEASE: data.features ? Boolean(data.features.RELEASE) : false,
        SOLAR_V: data.features ? Boolean(data.features.SOLAR_V) : false,
        TEMPERATURE: data.features ? Boolean(data.features.TEMPERATURE) : false,
        VOLTAGE: data.features ? Boolean(data.features.VOLTAGE) : false
    };

    const info:InterfaceReadingInfo = {
        features: features,
        identify: Boolean(data.identify),
        msg_type: Number(data.msg_type),
        release: Boolean(data.release),
        timestamp: data.timestamp ? _date_from_stamp(data.timestamp) : DBTools.date_invalid,
        uuid: data.uuid ? data.uuid.toString() : "",
    };

    return info
}

function _clean_reading_data(data:DocumentData) {
    let info:InterfaceReadingInfo = _clean_reading_info_data(data.info ? data.info : {});

    const ambience:InterfaceReadingAmbience = {
        humidity: data.ambience ? Number(data.ambience.humidity) : Number.NaN,
        pressure: data.ambience ? Number(data.ambience.pressure) : Number.NaN,
        temperature: data.ambience ? Number(data.ambience.temperature) : Number.NaN,
    };

    const power:InterfaceReadingPower = {
        voltage_batt: data.power ? Number(data.power.voltage_batt) : Number.NaN,
        voltage_solar: data.power ? Number(data.power.voltage_solar) : Number.NaN
    };

    const range:InterfaceReadingRange = {
        angle: data.range ? Number(data.range.angle) : Number.NaN,
        distance_adj: data.range ? Number(data.range.distance_adj) : Number.NaN,
        distance_raw: data.range ? Number(data.range.distance_raw) : Number.NaN
    }

    const reading:InterfaceReading = {
        info: info,
        ambience: ambience,
        power: power,
        range: range
    };

    return reading;
}

function _clean_user_profile(user:fireauth.User) {
    let names:string[] = [];
    if(user.displayName) {
        names = user.displayName.split(' ');
    } else {
        names.push("Anonymous");
    }

    let time_created = user.metadata.creationTime ? new Date(user.metadata.creationTime) : DBTools.date_invalid;

    return <InterfaceUserProfile>{
        "name_first": names[0],
        "name_last": names[names.length - 1],
        "mobile": user.phoneNumber,
        "email": user.email,
        "datetime_created": time_created,
        "username": user.email,
    };
}

export class DatabaseFirebaseConnection {
    static _auth_emulator_set = false;

    #is_emulated:boolean
    #app:fireapp.FirebaseApp;
    #auth:fireauth.Auth;
    #user_id:string;
    #user_data:InterfaceUserProfile;
    #unsubscribe_auth:() =>void;

    constructor(config:fireapp.FirebaseOptions, use_emulator:boolean) {
        this.#app = fireapp.initializeApp(config);
        this.#auth = fireauth.getAuth(this.#app);
        this.#user_id = null;
        this.#user_data = null;

        //Once it is set, default to emulator (scoping issue with firebase auth)
        if(use_emulator || DatabaseFirebaseConnection._auth_emulator_set) {
            if(!DatabaseFirebaseConnection._auth_emulator_set) {
                //XXX: This is a global configuration for the auth system (?) only do it once!
                DatabaseFirebaseConnection._auth_emulator_set = true;
                fireauth.connectAuthEmulator(this.#auth, "http://localhost:9099");
            }
            console.log("Created Firebase Emulator interface!");
        } else {
            console.log("Created Firebase interface!");
        }

        this.#unsubscribe_auth = this.on_auth_changed(this.#update_user.bind(this));
    }

    #update_user(user_id:string, user_data:InterfaceUserProfile) {
        this.#user_id = user_id;
        this.#user_data = user_data;
    }

    // shutdown() {
    //     if(this.#unsubscribe_auth)
    //         this.#unsubscribe_auth();

    //     // if(this.#app)
    //     //     fireapp.deleteApp(this.#app);
    //     this.#app = null;
    //     this.#auth = null;

    //     console.log("Database auth connector shut down");
    // }

    on_auth_changed(callback:(user_id:string, user_data:InterfaceUserProfile)=>void): ()=> void {
        return fireauth.onAuthStateChanged(this.#auth, (user) => {
            let user_id = null;
            let user_data = null;
            if (user) {
                console.log(`Signed in as user: ${user.uid} (${user.email})`);
                user_id = user.uid;
                user_data = _clean_user_profile(user);
            } else {
                console.log(`Signed out`);
            }

            callback(user_id, user_data);
        });
    }

    get_app() {
        return this.#app;
    }

    get_auth() {
        return this.#auth;
    }

    has_user() {
        return this.#user_id != null;
    }

    get_user_id() {
        return this.#user_id;
    }

    get_user_profile() {
        return this.#user_data;
    }
}

let database_connector:DatabaseFirebaseConnection = null;
export function get_database_connector() {
    if(!database_connector)
       database_connector = new DatabaseFirebaseConnection(cosec_firebase_config, DBTools.is_local());

    return database_connector;
}


export class DatabaseFirebase implements InterfaceDatabase {
    #current_hub:string;
    // #user:fireauth.User;
    #db:firestore.Firestore;
    // #is_emulated:boolean;
    // #auth_changed:()=>void;
    // #storage;
    // #storage_prefix;
    #app:DatabaseFirebaseConnection;

    constructor() {
        this.#current_hub = null;
        // this.#user = null;
        this.#app = get_database_connector();
        // this.#is_emulated = use_emulator;
        // this.#storage = null;
        // this.#storage_prefix = 'gs://cosec-74166.appspot.com';

        //Connect the rest of the firestore services
        this.#db = firestore.getFirestore(this.#app.get_app());

        // this.#storage = getStorage();
    }

    async user_logout() {
        await fireauth.signOut(this.#app.get_auth());
        // this.#app.shutdown();
    }

    async user_login(email:string, password:string, login_callback:(success:boolean, failure_reason:string) => void) {
        let success = false;
        let feedback = "";
        if(!email && !password && this.#app.has_user()) {
            success = true;
            feedback = "Switching to previous valid session";
        } else {
            //console.log(`Signing in with: ${email}; ${password}`);
            try {
                let userCredential = await fireauth.signInWithEmailAndPassword(this.#app.get_auth(), email, password);

                // Signed in
                //this.#user = userCredential.user;
                // console.log(`Signed in as user: ${userCredential.user.uid} (${this.#user.email})`);
                success = true;
                //window.load_app_data();
            }
            catch(error) {
                const errorCode = error.code;
                const errorMessage = error.message;

                switch(errorCode) {
                    case 'auth/user-not-found': {
                        feedback = 'Username not found';
                        break;
                    }
                    case 'auth/wrong-password': {
                        feedback = 'Invalid password';
                        break;
                    }
                    case 'auth/too-many-requests': {
                        feedback = 'Account temporarily disabled';
                        break
                    }
                    default: {
                        //console.error(errorCode);
                        feedback = 'An unknown error has occurred';
                        console.error(`${errorCode}: ${errorMessage}`);
                    }
                }
            }
        }

        login_callback(success, feedback);
    }

    async user_register(email:string, password:string) {
        let success = false;
        console.log(`Registering in with: ${email}; ${password}`);
        try {
            let userCredential = await fireauth.createUserWithEmailAndPassword(this.#app.get_auth(), email, password);

            // Signed in
            //this.#user = userCredential.user;
            // console.log(`Registered user: ${userCredential.user.uid} (${this.#user.email})`);
            success = true;
            //window.load_app_data();
        }
        catch(error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            let user_message = 'An unknown error has occurred';

            switch(errorCode) {
                case 'auth/email-already-in-use': {
                    user_message = 'Account already exists';
                    break;
                }
                case 'auth/weak-password': {
                    user_message = 'Password must be stronger';
                    break;
                }
                default: {
                    //console.error(errorCode);
                    console.error(errorMessage);
                }
            }

            document.getElementById('loader-dots').style.display = 'none';
            document.getElementById('loader-user-area').style.display = 'flex';

            var e = document.getElementById('loader-register-error');
            e.innerText = '';
            e.appendChild(document.createTextNode(user_message));
            e.style.display = 'flex';
        }

        return success;
    }

    load() {
        if(DatabaseFirebaseConnection._auth_emulator_set) {
            console.log("Loading from Firebase Emulator...");
            firestore.connectFirestoreEmulator(this.#db, 'localhost', 8080);
        } else {
            console.log("Loading from Firebase...");
        }

        firestore.enableIndexedDbPersistence(this.#db)
        .catch((err) => {
            if (err.code == 'failed-precondition') {
                console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
            } else if (err.code == 'unimplemented') {
                console.warn('The current browser does not support all of the features required to enable persistence');
            }
        });
    }

    // --== User Profile ==--
    get_user_id() {
        return this.#app.get_user_id();
    }

    get_user_profile() {
        return this.#app.get_user_profile();
    }

    get_user_real_name() {
        const profile = this.get_user_profile();
        return profile['name_first'] + ' ' + profile['name_last'];
    }

    get_user_real_name_initials() {
        const profile = this.get_user_profile();
        return profile['name_first'][0] + profile['name_last'][0];
    }

    // --== Hub ==--
    async get_hub_ids(get_archived=false) {
        var hubs:string[] = [];
        try {
            const qa = get_archived ?
                firestore.query(
                    firestore.collection(this.#db, "hubs"),
                    firestore.where("admin", "==", this.get_user_id()))
                :
                firestore.query(
                    firestore.collection(this.#db, "hubs"),
                    firestore.where("admin", "==", this.get_user_id()),
                    firestore.where("archived", "==", false));


            const qu = get_archived ?
                firestore.query(
                    firestore.collection(this.#db, "hubs"),
                    firestore.where("users", "array-contains", this.get_user_id()))
                :
                firestore.query(
                    firestore.collection(this.#db, "hubs"),
                    firestore.where("users", "array-contains", this.get_user_id()),
                    firestore.where("archived", "==", false));

            const querySnapshotAdmin = await firestore.getDocs(qa);
            const querySnapshotUser = await firestore.getDocs(qu);

            querySnapshotAdmin.forEach((doc) => {
                hubs.push(doc.id);
                //console.log(doc.id, " => ", doc.data());
            });

            querySnapshotUser.forEach((doc) => {
                hubs.push(doc.id);
                //console.log(doc.id, " => ", doc.data());
            });
        }
        catch(error) {
            console.error(error);
        }

        return hubs;
    }

    async get_default_hub_id() {
        const ids = await this.get_hub_ids();
        return ids.length ? ids[0] : null;
    }

    clear_current_hub() {
        this.#current_hub = null;
    }

    async set_current_hub(id:string) {
        const ids = await this.get_hub_ids();
        if(ids.includes(id)) {
            this.#current_hub = id;
        } else {
            throw new Error('Hub ID is not valid: db-interface/hub-invalid');
        }
    }

    async get_current_hub() {
        if(this.#current_hub == null) {
            const default_hub = await this.get_default_hub_id();
            await this.set_current_hub(default_hub).catch((error) => {
                if(default_hub) {
                    console.log(error.message);
                }
                this.clear_current_hub();
            });
        }

        return this.#current_hub;
    }

    async get_current_hub_data() {
        // await this.check_update_current_hub();
        return await this.get_hub_data(await this.get_current_hub());
    }

    async get_current_hub_name() {
        return (await this.get_current_hub_data())['name'];
    }

    // async get_hub_devices(hub_id) {
    //     return (await this.get_hub(hub_id))['devices'];
    // }

    async #get_device_collection(hub_id:string = null) {
        if(!hub_id)
            hub_id = await this.get_current_hub();
        return (hub_id) ? firestore.collection(this.#db, `hubs/${hub_id}/devices`) : null;
    }

    // async get_device_ids(hub_id = null, get_archived=false) {
    async get_device_ids(hub_id:string = null) {
        var devices:string[] = [];
        const collection = await this.#get_device_collection(hub_id);

        if(collection) {
            try {
                // const q = get_archived ?
                //     collection
                //     : firestore.query(
                //         collection,
                //         firestore.where("archived", "==", false))
                //     ;
                const querySnapshot = await firestore.getDocs(collection);
                querySnapshot.forEach((doc) => {
                    devices.push(doc.id);
                });
            }
            catch(error) {
                console.error(error);
            }
        }

        return devices;
    }

    // --== Readings ==--

    async get_readings(dev_id:string, count:number = 0, hub_id:string = null) {
        const edb = await this.#get_readings_collection(dev_id, hub_id);
        let o = firestore.orderBy("info.timestamp", "desc");
        let l = (count > 0) ? firestore.limit(count) : null;

        let q = (count > 0) ? firestore.query(edb, o, l) : firestore.query(edb, o);
        return await this.#get_readings_using_query(q);
    }

    async #get_readings_after(dev_id:string, stamp:Date, count:number, hub_id:string, direction:firestore.OrderByDirection) {
        const edb = await this.#get_readings_collection(dev_id, hub_id);
        let o = firestore.orderBy("info.timestamp", direction);
        let s = firestore.startAfter(stamp);
        let l = (count > 0) ? firestore.limit(count) : null;

        let q = (count > 0) ? firestore.query(edb, o, s, l) : firestore.query(edb, o, s);
        return await this.#get_readings_using_query(q);
    }

    async get_readings_after_asc(dev_id:string, stamp:Date, count:number = 0, hub_id:string = null) {
        return await this.#get_readings_after(dev_id, stamp, count, hub_id, "asc");
    }

    async get_readings_after_desc(dev_id: string, stamp: Date, count = 0, hub_id:string = null) {
        return await this.#get_readings_after(dev_id, stamp, count, hub_id, "desc");
    }

    async #get_readings_before(dev_id:string, stamp:Date, count:number, hub_id:string, direction:firestore.OrderByDirection) {
        const edb = await this.#get_readings_collection(dev_id, hub_id);
        let o = firestore.orderBy("info.timestamp", direction);
        let e = firestore.endBefore(stamp);
        let l = (count > 0) ? firestore.limitToLast(count) : null;

        let q = (count > 0) ? firestore.query(edb, o, e, l) : firestore.query(edb, o, e);
        return await this.#get_readings_using_query(q);
    }

    async get_readings_before_asc(dev_id:string, stamp:Date, count:number = 0, hub_id:string = null) {
        return await this.#get_readings_before(dev_id, stamp, count, hub_id, "asc");
    }

    async get_readings_before_desc(dev_id: string, stamp: Date, count:number = 0, hub_id:string = null) {
        return await this.#get_readings_before(dev_id, stamp, count, hub_id, "desc");
    }

    async #get_readings_between(dev_id:string, stamp_start:Date, stamp_end:Date, count:number, hub_id:string, direction:firestore.OrderByDirection) {
        const edb = await this.#get_readings_collection(dev_id, hub_id);
        let o = firestore.orderBy("info.timestamp", direction);
        let s = firestore.startAfter(stamp_start);
        let e = firestore.endBefore(stamp_end);
        let l = (count > 0) ? firestore.limitToLast(count) : null;

        let q = (count > 0) ? firestore.query(edb, o, s, e, l) : firestore.query(edb, o, s, e);
        return await this.#get_readings_using_query(q);
    }

    async get_readings_between_desc(dev_id:string, stamp_start:Date, stamp_end:Date, count:number = 0, hub_id:string = null) {
        return await this.#get_readings_between(dev_id, stamp_start, stamp_end, count, hub_id, "desc");
    }

    async get_readings_between_asc(dev_id:string, stamp_start:Date, stamp_end:Date, count:number = 0, hub_id:string = null) {
        return await this.#get_readings_between(dev_id, stamp_start, stamp_end, count, hub_id, "asc");
    }

    async #get_readings_using_query(q:firestore.Query<firestore.DocumentData>) {
        var readings:Map<string,InterfaceReading> = new Map();

        //Always try to pull from the cache first as readings should never be modified (assume readonly)
        let querySnapshot = await firestore.getDocsFromCache(q);
        //But fallback to the server if we cannot get them from cache
        if(querySnapshot.empty) {
            //But fallback to the server if we cannot get them from cache
            querySnapshot = await firestore.getDocs(q);
        //     console.log('Loading new reading data...');
        // } else {
        //     console.log('Loaded reading from cache!');
        }

        //If we had a success in some form
        querySnapshot.forEach((d) => {
            // d.data() is never undefined for query doc snapshots
            let reading = _clean_reading_data(d.data());
            readings.set(d.id, reading);
        });

        return readings;
    }

    async #get_readings_collection(dev_id:string, hub_id:string = null) {
        if(!hub_id)
            hub_id = await this.get_current_hub();

        return (hub_id) ? firestore.collection(this.#db, `hubs/${hub_id}/devices/${dev_id}/readings`) : null;
    }

    async get_latest_reading(dev_id:string, hub_id:string = null) {
        const data = await this.get_readings(dev_id, 1, hub_id);
        const latest = data.values().next().value;

        //Return the latest event if it exists or null (instead of undefined)
        return latest ? latest : null;
    }

    async watch_readings(dev_id:string, callback:()=>void, hub_id:string = null) {
        const collection = await this.#get_readings_collection(dev_id, hub_id);
        return (collection) ? firestore.onSnapshot(collection, callback) : null;
    }

    async watch_devices(callback:()=>void, hub_id:string = null) {
        const collection = await this.#get_device_collection(hub_id);
        return(collection) ? firestore.onSnapshot(collection, callback) : null;
    }

    async watch_hub_data(callback:(hub_id:string, data:InterfaceHub)=>void, hub_id:string = null) {
        if(hub_id == null)
            hub_id = await this.get_current_hub();

        return (hub_id) ? firestore.onSnapshot(firestore.doc(this.#db, "hubs", hub_id), (doc) => {
                callback(doc.id, doc.exists() ?  _clean_hub_data(doc.data()) : null);
            }) :
            null;
    }

    async watch_device_data(dev_id:string, callback:(dev_id:string, data:InterfaceDeviceBase|InterfaceDeviceStatic|InterfaceDeviceSensor)=>void, hub_id:string = null) {
        const collection = await this.#get_device_collection(hub_id);
        return (collection) ? firestore.onSnapshot(firestore.doc(collection, dev_id), (doc) => {
                let data = null;
                if (doc.exists()) {
                    let raw = doc.data();
                    switch(raw.type) {
                        case InterfaceDeviceType.ROTAFLAG: {
                            data = _clean_device_static_data(raw, InterfaceDeviceType.ROTAFLAG);
                            break;
                        }
                        case InterfaceDeviceType.ROTAMARKER_RW: {
                            data = _clean_device_static_data(raw, InterfaceDeviceType.ROTAMARKER_RW);
                            break;
                        }
                        case InterfaceDeviceType.ROTAMARKER_BW: {
                            data = _clean_device_static_data(raw, InterfaceDeviceType.ROTAMARKER_BW);
                            break;
                        }
                        case InterfaceDeviceType.ROTASENSOR: {
                            data = _clean_device_sensor_data(raw);
                            break;
                        }
                        default: {  //InterfaceDeviceType.GENERIC
                            //TODO: Clean this up, but we default to assuming it's a rota-sensor for backwards compat
                            data = _clean_device_sensor_data(raw);
                            break;
                        }
                    }
                }

                callback(doc.id, doc.exists() ? data: null);
            }) :
            null;
    }

    // --== Settings ==--
    // get_setting_ids() {
    //     return [];
    // }

    // --== Utility ==--
    async lookup_user(id:string) {
        if (id == this.get_user_id()) {
            return this.get_user_profile();
        } else {
            //TODO
            return null;
        }
    }

    async get_hub_data(id:string) {
        var data:InterfaceHub = null;

        if(id != null) {
            try{
                const docRef = firestore.doc(this.#db, "hubs", id);
                const d = await firestore.getDoc(docRef);

                if (d.exists())
                    data = _clean_hub_data(d.data());
            }
            catch(error) {
                console.error(error);
            }
        }

        return data;
    }

    async get_device_data(dev_id:string, hub_id:string=null) {
        var data:InterfaceDeviceBase|InterfaceDeviceStatic|InterfaceDeviceSensor = null;
        const collection = await this.#get_device_collection(hub_id);

        if(dev_id && collection) {
            try{
                const docRef = firestore.doc(collection, dev_id);
                const d = await firestore.getDoc(docRef);

                if (d.exists()) {
                    let raw = d.data();
                    switch(raw.type) {
                        case InterfaceDeviceType.ROTAFLAG: {
                            data = _clean_device_static_data(raw, InterfaceDeviceType.ROTAFLAG);
                            break;
                        }
                        case InterfaceDeviceType.ROTAMARKER_RW: {
                            data = _clean_device_static_data(raw, InterfaceDeviceType.ROTAMARKER_RW);
                            break;
                        }
                        case InterfaceDeviceType.ROTAMARKER_BW: {
                            data = _clean_device_static_data(raw, InterfaceDeviceType.ROTAMARKER_BW);
                            break;
                        }
                        case InterfaceDeviceType.ROTASENSOR: {
                            data = _clean_device_sensor_data(raw);
                            break;
                        }
                        default: {  //InterfaceDeviceType.GENERIC
                            //TODO: Clean this up, but we default to assuming it's a rota-sensor for backwards compat
                            data = _clean_device_sensor_data(raw);
                            break;
                        }
                    }
                }
            }
            catch(error) {
                console.error(error);
            }
        }

        return data;
    }

    async archive_hub(hub_id:string) {
        let success = false;
        const selecting_current_hub = await this.get_current_hub() == hub_id;

        try{
            let hubs  = firestore.collection(this.#db, 'hubs');
            let ref = firestore.doc(hubs, hub_id);
            console.log(`Archiving hub ${hub_id}`);
            let h =  await firestore.getDoc(ref);

            if (h.exists()) {
                // if(success) {
                await firestore.updateDoc(ref, {
                    "archived": true
                });

                success = true;
                // }
            }
        }
        catch(error) {
            console.error(error);
        }

        //Specific case to reset current hub
        if(success && selecting_current_hub) {
            this.clear_current_hub();
        }

        return success;
    }

    async identify_hub(hub_id:string) {
        let success = false;

        try{
            let hubs  = firestore.collection(this.#db, 'hubs');
            let ref = firestore.doc(hubs, hub_id);
            let h =  await firestore.getDoc(ref);

            if (h.exists()) {
                await firestore.updateDoc(ref, {
                    "identify": true
                });

                success = true;
            }
        }
        catch(error) {
            console.error(error);
        }

        return success;
    }

    async add_hub(hub_data:InterfaceHub) {
        let id = null;
        try{
            //Set the main document data
            const hub = firestore.collection(this.#db, "hubs");
            const ref = firestore.doc(hub);

            //Shallow copy and clean any needed variables
            let data = Object.assign(hub_data);
            if(data.location) {
                data.location = new firestore.GeoPoint(data.location.latitude, data.location.longitude)
            }
            await firestore.setDoc(ref, data);
            id = ref.id;
        }
        catch(error) {
            console.error(error);
        }

        return id;
    }

    async add_device_to_hub(hub_id:string, device_data:InterfaceDeviceBase|InterfaceDeviceStatic|InterfaceDeviceSensor) {
        // const id = await this.add_device(device_data);

        var id = null;
        // if(id){
            try{
                var hub_ref = firestore.doc(this.#db, 'hubs/' + hub_id);

                const devices = firestore.collection(hub_ref, "devices/");
                const ref = firestore.doc(devices);
                //Shallow copy and clean any needed variables
                let data = Object.assign(device_data);
                if(data.location) {
                    data.location = new firestore.GeoPoint(data.location.latitude, data.location.longitude)
                }
                await firestore.setDoc(ref, data);
                id = ref.id;

                // //Add a single item to an array
                // await firestore.updateDoc(hub_ref, {
                //     "devices": firestore.arrayUnion(id)
                // });
            }
            catch(error) {
                console.error(error);
            }
        // }

        return id;
    }

    async set_device_identify(dev_id:string, hub_id:string=null) {
        let success = false;

        try{
            const collection = await this.#get_device_collection(hub_id);
            const ref = firestore.doc(collection, dev_id);

            //Sync new date
            await firestore.updateDoc(ref, {
                "identify": true
            });

            success = true;
        }
        catch(error) {
            console.error(error);
        }

        return success;
    }

    async update_hub_sub_data(id:string, data:any) {
        let success = false;

        try{
            var hub_ref = firestore.doc(this.#db, 'hubs/' + id);
            //Sync new data
            await firestore.updateDoc(hub_ref, data);
            success = true;
        }
        catch(error) {
            console.error(error);
        }

        return success;
    }

    async update_hub_name(id:string, name:string) {
        const success = await this.update_hub_sub_data(id, { "name": name });
        if(success)
            console.log(`Updated name for: ${id}`);

        return success;
    }

    async update_hub_owner(id:string, owner:string) {
        const success = await this.update_hub_sub_data(id, { "owner": owner });
        if(success)
            console.log(`Updated owner for: ${id}`);

        return success;
    }

    async update_hub_location(id:string, latitude:number, longitude:number) {
        const success = await this.update_hub_sub_data(id, { "location": new firestore.GeoPoint(latitude, longitude) });
        if(success)
            console.log(`Updated location for: ${id}`);

        return success;
    }

    async update_hub_overlays(id:string, overlays:InterfaceHubGeoFeatures[]) {
        const success = await this.update_hub_sub_data(id, { "geo_features": overlays });
        if(success)
            console.log(`Updated geo_features for: ${id}`);

        return success;
    }

    async update_device_sub_data(hub_id:string, dev_id:string, data:any) {
        let success = false;

        try{
            const collection = await this.#get_device_collection(hub_id);
            var dev_ref = firestore.doc(collection, dev_id);
            //Sync new data
            await firestore.updateDoc(dev_ref, data);
            success = true;
        }
        catch(error) {
            console.error(error);
        }

        return success;
    }

    async update_device_location(hub_id:string, dev_id:string, latitude:number, longitude:number) {
        const success = await this.update_device_sub_data(hub_id, dev_id, { "location": new firestore.GeoPoint(latitude, longitude) });
        if(success)
            console.log(`Updated location for: ${hub_id}/${dev_id}`);

        return success;
    }

    async update_device_name(hub_id:string, dev_id:string, name:string) {
        const success = await this.update_device_sub_data(hub_id, dev_id, { "name": name });
        if(success)
            console.log(`Updated nickname for: ${hub_id}/${dev_id}`);

        return success;
    }

    async update_device_date_review(hub_id:string, dev_id:string, review:Date) {
        const success = await this.update_device_sub_data(hub_id, dev_id, { "date_review": review });
        if(success)
            console.log(`Updated review date for: ${hub_id}/${dev_id}`);

        return success;
    }

    async update_device_date_install(hub_id:string, dev_id:string, install:Date) {
        const success = await this.update_device_sub_data(hub_id, dev_id, { "date_install": install });
        if(success)
            console.log(`Updated install date for: ${hub_id}/${dev_id}`);

        return success;
    }

    async update_device_warranty(hub_id: string, dev_id: string, warranty: number) {
        const success = await this.update_device_sub_data(hub_id, dev_id, { "warranty": warranty });
        if(success)
            console.log(`Updated warranty for: ${hub_id}/${dev_id}`);

        return success;
    }

    async update_device_review(hub_id: string, dev_id: string, review: number) {
        const success = await this.update_device_sub_data(hub_id, dev_id, { "review": review });
        if(success)
            console.log(`Updated review for: ${hub_id}/${dev_id}`);

        return success;
    }

    async update_device_renewal(hub_id: string, dev_id: string, renewal: number) {
        const success = await this.update_device_sub_data(hub_id, dev_id, { "renewal": renewal });
        if(success)
            console.log(`Updated renewal for: ${hub_id}/${dev_id}`);

        return success;
    }

    async update_device_lifespan(hub_id: string, dev_id: string, lifespan: number) {
        const success = await this.update_device_sub_data(hub_id, dev_id, { "lifespan": lifespan });
        if(success)
            console.log(`Updated lifespan for: ${hub_id}/${dev_id}`);

        return success;
    }

    async update_device_threshold(dev_id: string, parameter_type: WidgetMinNomMaxType, data_type: DATASET_ID, value: number, hub_id?: string) {
        let success = false;

        const key_thresholds = 'thresholds';
        let key_parameter = '';
        let key_group = '';
        let key_data = '';

        switch(parameter_type) {
            case WidgetMinNomMaxType.MINIMUM: {
                key_parameter = 'min';
                break;
            }
            case WidgetMinNomMaxType.NOMINAL: {
                key_parameter = 'nom';
                break;
            }
            case WidgetMinNomMaxType.MAXIMUM: {
                key_parameter = 'max';
                break;
            }
        }

        switch(data_type) {
            case DATASET_ID.TEMPERATURE: {
                key_group = 'ambience';
                key_data = 'temperature';
                break;
            }
            case DATASET_ID.PRESSURE: {
                key_group = 'ambience';
                key_data = 'pressure';
                break;
            }
            case DATASET_ID.HUMIDITY: {
                key_group = 'ambience';
                key_data = 'humidity';
                break;
            }
            case DATASET_ID.VBATT: {
                key_group = 'power';
                key_data = 'voltage_batt';
                break;
            }
            case DATASET_ID.VBATT: {
                key_group = 'power';
                key_data = 'voltage_solar';
                break;
            }
            case DATASET_ID.ANGLE: {
                key_group = 'range';
                key_data = 'angle';
                break;
            }
            case DATASET_ID.DISTADJ: {
                key_group = 'range';
                key_data = 'distance_adj';
                break;
            }
            case DATASET_ID.DISTRAW: {
                key_group = 'range';
                key_data = 'distance_raw';
                break;
            }
        }

        //Trigger the data change
        if(key_parameter && key_group && key_data) {
            if(!hub_id)
                hub_id = await this.get_current_hub();

            let data:any = {};
            data[`${key_thresholds}.${key_parameter}.${key_group}.${key_data}`] = value;

            if(hub_id && await this.update_device_sub_data(hub_id, dev_id, data)) {
                success = true;
            }
        }

        return success;
    }
}
