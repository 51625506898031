import { DOMFactory } from "./dom_factory";
import { CommonLib } from "./common";
import { InterfacePage } from "./interface_page";
import { InterfaceDatabase } from "./interface_database";
import { Chart, ChartConfiguration, ChartData, ChartDataset, ChartOptions, ChartType } from "chart.js";
import { CosecCorePages } from "./core";

class CosecNetworkDevice {
    #dev_id;
    #refresh_watcher;
    #latest_reading;  //XXX: Cleared when the watcher is cleared

    constructor(dev_id:string, watcher:()=>void=null) {
        this.#dev_id = dev_id;
        this.#refresh_watcher = watcher;
        this.#latest_reading = CommonLib.t_zero;
    }

    get_id() {
        return this.#dev_id;
    }

    has_recent_reading() {
        return this.#latest_reading != CommonLib.t_zero;
    }

    set_reading_stamp(stamp:Date) {
        this.#latest_reading = new Date(stamp);
    }

    get_reading_stamp() {
        return this.#latest_reading;
    }

    get_watcher() {
        return this.#refresh_watcher;
    }

    set_watcher(watcher:()=>void) {
        this.clear_watcher();
        this.#refresh_watcher = watcher;
    }

    clear_watcher() {
        if(this.#refresh_watcher) {
            this.#refresh_watcher();
            this.#refresh_watcher = null;
        }

        this.#latest_reading = CommonLib.t_zero;
    }

    destroy() {
        this.clear_watcher();
    }

}

const DATASET_AMBIENCE = {
    "TEMPERATURE":  0,
    "HUMIDITY":     1,
    "PRESSURE":     2,
    "COUNT":        3,  //XXX: Don't forget to update this!
};

const DATASET_POWER = {
    "VBATT":        0,
    "VSOLAR":       1,
    "COUNT":        2,  //XXX: Don't forget to update this!
};

const DATASET_RANGE = {
    "ANGLE":        0,
    "DISTRAW":      1,
    "DISTADJ":      2,
    "COUNT":        3,  //XXX: Don't forget to update this!
};

const dataset_labels_ambience = [
    "Temperature (°)",
    "Humidity (%)",
    "Pressure (dPa)",
];

const dataset_labels_power = [
    "Battery Voltage (V)",
    "Solar Voltage (V)",
];

const dataset_labels_range = [
    "Angle (rad)",
    "Raw Distance (m)",
    "Adj. Distance (m)",
];

const dataset_colors_ambience = [
    'rgba(255,  50,  50, 1.0)',
    'rgba(  0, 200, 200, 1.0)',
    'rgba(  0, 255, 100, 1.0)',
];

const dataset_colors_power = [
    'rgba(255, 100,   0, 1.0)',
    'rgba(255, 200,   0, 1.0)',
];

const dataset_colors_range = [
    'rgba(  0, 200, 255, 1.0)',
    'rgba( 50, 100, 255, 1.0)',
    'rgba(  0,  50, 255, 1.0)',
];

console.assert(dataset_labels_ambience.length == DATASET_AMBIENCE.COUNT, "Mismatch in variable definitions (ambience labels)");
console.assert(dataset_colors_ambience.length == DATASET_AMBIENCE.COUNT, "Mismatch in variable definitions (ambience colors)");
console.assert(dataset_labels_power.length == DATASET_POWER.COUNT, "Mismatch in variable definitions (power labels)");
console.assert(dataset_colors_power.length == DATASET_POWER.COUNT, "Mismatch in variable definitions (power colors)");
console.assert(dataset_labels_range.length == DATASET_RANGE.COUNT, "Mismatch in variable definitions (range labels)");
console.assert(dataset_colors_range.length == DATASET_RANGE.COUNT, "Mismatch in variable definitions (range colors)");

export class CosecNetwork implements InterfacePage {
    #page_id:CosecCorePages;
    #refresh_watcher:()=>void;
    #db:InterfaceDatabase;
    #hub_id:string;
    #devices:CosecNetworkDevice[];
    #plot_ambience:Chart;
    #plot_power:Chart;
    #plot_range:Chart;

    constructor(page_id:CosecCorePages, db_interface:InterfaceDatabase) {
        this.#page_id = page_id;
        this.#db = db_interface;
        this.#hub_id = null;
        this.#devices = [];
        this.#refresh_watcher = null;
        this.#plot_ambience = null;
        this.#plot_power = null;
        this.#plot_range = null;
    }

    clear_devices(ids:string[]) {
        for(const id of ids) {
            const ind = this.#devices.findIndex(x => x.get_id() == id);

            //Remove items from data structures
            if(ind || ind === 0) {
                const dev = this.#devices.splice(ind, 1)[0];
                //Stop watchers and remove and associated DOM
                if(dev)
                    dev.destroy();
                //Clean plots
                this.#plot_ambience.data.datasets.splice(ind, 1);
                this.#plot_power.data.datasets.splice(ind, 1);
                this.#plot_range.data.datasets.splice(ind, 1);
            } else {
                console.warn("Could not clean up device id: " + id)
            }
        }

        //Update all plots afterwards
        this.#plot_ambience.update();
        this.#plot_power.update();
        this.#plot_range.update();
    }

    // cyclic_push_datapoint(dataset, point, max_points) {
    //     //Push in the newest element
    //     dataset.push(point);
    //     //Drop the first element (oldest) if it exists and we have enough points
    //     if(dataset.length > max_points)
    //         dataset.shift()
    // }

    async update_device_graph(hub_id:string, dev_id:string) {
        // console.log(`New data for: ${hub_id}/${dev_id}`);
        const ind = this.#devices.findIndex(x => x.get_id() == dev_id);

        //If there is no match, this is an old device
        if(ind || ind === 0) {
        // if(ind === 0) {
            //Get the latest reading
            const device = this.#devices[ind];
            const r = await this.#db.get_latest_reading(dev_id, hub_id);   //We're starting fresh, get the latest reading

            //Make sure that we only update if this reading is newer than any previous ones
            if(r) {
                //TODO: Check for NANs?

                if(r.ambience) {
                    // this.#plot_ambience.data.datasets[DATASET_AMBIENCE.TEMPERATURE].data[ind] = r.ambience.temperature;
                    // this.#plot_ambience.data.datasets[DATASET_AMBIENCE.HUMIDITY].data[ind] = r.ambience.humidity*100;
                    // this.#plot_ambience.data.datasets[DATASET_AMBIENCE.PRESSURE].data[ind] = r.ambience.pressure;
                    this.#plot_ambience.data.datasets[ind].data = [
                        r.ambience.temperature,
                        r.ambience.humidity*100,
                        r.ambience.pressure
                    ]

                    // this.#plot_ambience.data.datasets[DATASET_AMBIENCE.TEMPERATURE].data = this.#plot_ambience_data_raw;
                    this.#plot_ambience.update();
                }

                if(r.power) {
                    this.#plot_power.data.datasets[ind].data = [
                        r.power.voltage_batt,
                        r.power.voltage_solar
                    ]
                    // this.#plot_power.data.datasets[DATASET_POWER.VBATT].data[ind] = r.power.voltage_batt;
                    // this.#plot_power.data.datasets[DATASET_POWER.VSOLAR].data[ind] = r.power.voltage_solar;

                    this.#plot_power.update();
                }

                if(r.range) {
                    this.#plot_range.data.datasets[ind].data = [
                        r.range.angle,
                        r.range.distance_raw,
                        r.range.distance_adj
                    ]

                    // this.#plot_range.data.datasets[DATASET_RANGE.ANGLE].data[ind] = r.range.angle;
                    // this.#plot_range.data.datasets[DATASET_RANGE.DISTRAW].data[ind] = r.range.distance_raw;
                    // this.#plot_range.data.datasets[DATASET_RANGE.DISTADJ].data[ind] = r.range.distance_adj;

                    this.#plot_range.update();
                }

                //Try get the first reading (latest reading)
                if(r.info && r.info.timestamp)
                    device.set_reading_stamp(r.info.timestamp);
            }
        }
    }

    create_bar_graph(canvas:HTMLCanvasElement, title:string, labels:string[]) {
        let ctx = canvas.getContext('2d');

        let chart_type:ChartType = 'bar';
        // let datasets = [];
        // for(let i=0; i<dataset.COUNT; i++)
        //     datasets.push({
        //         data: [],	//XXX: These get filled out later with the device data
        //         label: [],
        //         // backgroundColor: colors[i],
        //         // borderColor: colors[i],
        //     });

        let data:ChartData = {
            datasets: [],
            labels: labels,
        };
        let title_info = title ? {
                text: title,
                display: true,
                padding: {
                    top: 10,
                    bottom: 30
                }
            } :
            {
                display: false,
            };
        let options:ChartOptions = {
            plugins: {
                title: title_info,
                legend: {
                    position: 'top',
                },
            },
            aspectRatio: 0,
            scales: {
                x: {
                    title: {
                        text: 'Devices',
                        display: true
                    }
                }
            },
            onClick: (e, elements, chart) => {
                let datasetIndex = elements[0].datasetIndex;
                // let dataIndex = activeEls[0].index;
                let datasetLabel = chart.data.datasets[datasetIndex].label;
                // let value = e.chart.data.datasets[datasetIndex].data[dataIndex];
                // let label = e.chart.data.labels[dataIndex];
                // console.log("In click", datasetLabel, label, value);
                window.cosec_core.open_page(CosecCorePages.MAP, datasetLabel);
            }
        };

        let config:ChartConfiguration = {
            type: chart_type,
            data: data,
            options: options,
        };

        return new Chart(ctx, config); //eslint-disable-line no-undef
    }

    async load_overview() {
        const dom_graph_list = document.getElementById('network-graph-list');

        if(!this.#plot_ambience) {
            const graph = DOMFactory.get_block_graph('network', 'Sensor Ambience Statistics', 'ambience', 1);
            this.#plot_ambience = this.create_bar_graph(graph['canvas_items'].shift(), "", dataset_labels_ambience);
            dom_graph_list.appendChild(graph['dom']);
        }

        if(!this.#plot_power) {
            const graph = DOMFactory.get_block_graph('network', 'Sensor Power Statistics', 'power', 1);
            this.#plot_power = this.create_bar_graph(graph['canvas_items'].shift(), "", dataset_labels_power);
            dom_graph_list.appendChild(graph['dom']);
        }

        if(!this.#plot_range) {
            const graph = DOMFactory.get_block_graph('network', 'Sensor Range Statistics', 'range', 1);
            this.#plot_range = this.create_bar_graph(graph['canvas_items'].shift(), "", dataset_labels_range);
            dom_graph_list.appendChild(graph['dom']);
        }

        //Step 1: Figure out if we have any new markers
        const hub_id = await this.#db.get_current_hub();
        const dev_ids = await this.#db.get_device_ids();

        //If hub marker exists and it's different, or if the hub ID exists and the marker doesn't
        const hub_changed = (this.#hub_id != hub_id);

        //If our hub marker has reset or changed
        if(!hub_id || hub_changed) {
            //We've changed hub ID, clear everything
            this.clear_devices(this.#devices.map(x => x.get_id()));
        }

        //If we have a valid hub selected, and we have reason to regenerate the marker
        if(hub_id && hub_changed) {
            this.#hub_id = hub_id;
        }

        const current_ids = this.#devices.map(function(device){
            return device.get_id();
        });

        const old_ids = current_ids.filter(x => !dev_ids.includes(x));
        // const old_devices = this.#devices.filter(x => old_ids.includes(x.dev_id));
        if(old_ids.length)
            this.clear_devices(old_ids);
        // }

        const new_ids = dev_ids.filter(x => !current_ids.includes(x));
        for(const id of new_ids) {
            const dev = new CosecNetworkDevice(id);
            this.#devices.push(dev);
            const color = CommonLib.rand_color(id);

            this.#plot_ambience.data.datasets.push({
                data: [0,0,0],
                label: id,
                backgroundColor: color,
                borderColor: color,
            });
            this.#plot_power.data.datasets.push({
                data: [0,0,0],
                label: id,
                backgroundColor: color,
                borderColor: color,
            });
            this.#plot_range.data.datasets.push({
                data: [0,0,0],
                label: id,
                backgroundColor: color,
                borderColor: color,
            });

            // this.#plot_ambience.data.labels.push(id);
            // this.#plot_ambience.data.datasets[DATASET_AMBIENCE.TEMPERATURE].data.push(0);
            // this.#plot_ambience.data.datasets[DATASET_AMBIENCE.HUMIDITY].data.push(0);
            // this.#plot_ambience.data.datasets[DATASET_AMBIENCE.PRESSURE].data.push(0);

            // this.#plot_power.data.labels.push(id);
            // this.#plot_power.data.datasets[DATASET_POWER.VBATT].data.push(0);
            // this.#plot_power.data.datasets[DATASET_POWER.VSOLAR].data.push(0);

            // this.#plot_range.data.labels.push(id);
            // this.#plot_range.data.datasets[DATASET_RANGE.ANGLE].data.push(0);
            // this.#plot_range.data.datasets[DATASET_RANGE.DISTRAW].data.push(0);
            // this.#plot_range.data.datasets[DATASET_RANGE.DISTADJ].data.push(0);
        }

        //TODO: Get an initial set of data?

        //XXX: This will also trigger our marker update
        await this.update_graph_data_watchers(hub_id);
    }

    async update_graph_data_watchers(hub_id:string) {
        for(const device of this.#devices) {
            if(!device.get_watcher())
                device.set_watcher(await this.#db.watch_readings(device.get_id(), this.update_device_graph.bind(this, hub_id, device.get_id()), hub_id));
        }
    }

    async reset() {
        let graph_list = document.getElementById('network-graph-list');
        let graph_list_loading = document.getElementById('network-graph-list-loading');
        graph_list.style.display = 'none';
        graph_list_loading.style.display = 'flex';
    }

    async refreshPage() {
        // this.reset();

        await this.load_overview();
    }

    clearRefreshWatchers() {
        if (this.#refresh_watcher) {
            this.#refresh_watcher(); //Stop event listener
            this.#refresh_watcher = null;
        }

        for(const d of this.#devices) {
            d.clear_watcher();
        }
    }

    async refresh(device_in_focus:string=null) {
		const focus_str = device_in_focus != null ? ` (focus: ${device_in_focus})` : "";
		console.log(`Refreshing ${this.#page_id}${focus_str}!`);

        this.reset();

        this.clearRefreshWatchers();
        //XXX: This will also trigger our refresh
        this.#refresh_watcher = await this.#db.watch_devices(this.refreshPage.bind(this));
        //Final catch to force a refresh if the "watch devices" fails
        if(!this.#refresh_watcher)
            await this.refreshPage();

        let graph_list = document.getElementById('network-graph-list');
        let graph_list_loading = document.getElementById('network-graph-list-loading');
        graph_list_loading.style.display = 'none';
        graph_list.style.display = 'flex';
    }

	async hub_changed(old_current_id:string, new_current_id:string) {
		this.deactivate();
		this.refresh();
	}

    async deactivate() {
        console.log("Deactivating network!");
        this.clearRefreshWatchers();
    }
}
