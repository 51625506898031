import { CosecCore, CosecCorePages } from "./core";
import { DATASET_ID, DBTools, InterfaceHubGeoFeatures } from "./db_tools";
import { DeviceBaseInfoCallbacks, DeviceSensorInfoCallbacks, DeviceStaticInfoCallbacks, DOMFactory, HubInfoCallbacks, WidgetMinNomMaxType } from "./dom_factory";
import { InterfaceDatabase, InterfaceDeviceBase, InterfaceDeviceSensor, InterfaceDeviceStatic, InterfaceDeviceType, InterfaceHub } from "./interface_database";
import { InterfacePage } from "./interface_page";

export class CosecManage implements InterfacePage {
	#page_id:CosecCorePages;
	#db;
	#core;
	#but_back:HTMLButtonElement;
	#hub_list_loading:HTMLDivElement;
	#hub_list:HTMLDivElement;
	#dev_list:HTMLDivElement;
	// #hub_list_watcher:()=>void;
	#hub_watchers:(()=>void)[];
	#device_list_watcher:()=>void;
	#device_watchers:Map<string,()=>void>;

	constructor(page_id:CosecCorePages, db_interface:InterfaceDatabase, core:CosecCore) {
		this.#page_id = page_id;
		this.#db = db_interface;
		this.#core = core;
		this.#hub_watchers = []
		this.#device_list_watcher = null;
		this.#device_watchers = new Map();
		this.#but_back = <HTMLButtonElement>document.getElementById(`hub-list-back`);
		this.#but_back.onclick = this.#show_hub_list.bind(this);
		this.#hub_list_loading = <HTMLDivElement>document.getElementById(`hub-list-loading`);
		this.#hub_list = <HTMLDivElement>document.getElementById(`hub-list`);
		this.#dev_list = <HTMLDivElement>document.getElementById(`hub-dev-list`);
	}

	async show_loading_list() {
		this.#dev_list.classList.remove('show');
		this.#hub_list.classList.remove('show');
		this.#dev_list.style.display = 'none';
		this.#hub_list.style.display = 'none';

		this.#hub_list_loading.style.display = 'flex';
	}

	async #clear_device_list() {
		this.#clear_device_watchers();

		this.#dev_list.innerHTML = '';
	}

	async #show_hub_list() {
		this.#but_back.disabled = true;

		this.#dev_list.classList.remove('show');
		this.#hub_list.classList.add('show');

		this.#hub_list_loading.style.display = 'none';
		this.#dev_list.style.display = 'flex';
		this.#hub_list.style.display = 'flex';

		await this.#clear_device_list();
	}

	async show_device_list(hub_id:string, is_admin:boolean) {
		this.#but_back.disabled = false;

		await this.show_loading_list();
		await this.#clear_device_list();

		//Add in associated device data

		this.#device_list_watcher = await this.#db.watch_devices(this.#device_list_changed.bind(this, hub_id, is_admin), hub_id);
		// const dev_ids = await this.#db.get_device_ids(hub_id);
		// for(const dev_id of dev_ids) {
		// 	const dev_data = await this.#db.get_device_data(dev_id, hub_id);
		// 	if(dev_data) {
		// 		const el = await this.#get_device_dom_from_data(hub_id, dev_id, is_admin, dev_data);
		// 		if(el != null) {
		// 			this.#dev_list.appendChild(el);
		// 		}
		// 		else {
		// 			console.warn(`Error determining device data type for: ${dev_id}`);
		// 		}
		// 	} else {
		// 		console.warn(`Error getting device data for: ${dev_id}`);
		// 	}
		// }

		let but_group = document.createElement('div');
		but_group.id = 'hub-dev-btn-group-add';
		but_group.appendChild(DOMFactory.get_button_device_add("RotaFlag", this.add_device.bind(this, hub_id, InterfaceDeviceType.ROTAFLAG)));
		but_group.appendChild(DOMFactory.get_button_device_add("RotaMarka", this.add_device.bind(this, hub_id, InterfaceDeviceType.ROTAMARKER_RW)));
		but_group.appendChild(DOMFactory.get_button_device_add("RotaMarka", this.add_device.bind(this, hub_id, InterfaceDeviceType.ROTAMARKER_BW)));
		but_group.appendChild(DOMFactory.get_button_device_add("RotaSensor", this.add_device.bind(this, hub_id, InterfaceDeviceType.ROTASENSOR)));
		// this.#dev_list.appendChild(DOMFactory.get_button_device_add("Generic device", this.add_device.bind(this, hub_id, InterfaceDeviceType.GENERIC)));
		this.#dev_list.appendChild(but_group);

		this.#hub_list.classList.remove('show');
		this.#dev_list.classList.add('show');

		this.#hub_list_loading.style.display = 'none';
		this.#dev_list.style.display = 'flex';
		this.#hub_list.style.display = 'flex';

	}

	// async #get_hub_dom(hub_id:string) {
	// 	const hub_data = await this.#db.get_hub_data(hub_id);
	// 	return await this.#get_hub_dom_from_data(hub_id, hub_data);
	// }


	async #get_device_dom_from_data(hub_id:string, dev_id:string, is_admin:boolean, dev_data:InterfaceDeviceBase|InterfaceDeviceStatic|InterfaceDeviceSensor) {
		let el = null;
		switch(dev_data.type) {
			case InterfaceDeviceType.ROTAFLAG: {
				let callbacks:DeviceStaticInfoCallbacks = {
					'update_device_nickname': this.update_device_nickname.bind(this, hub_id, dev_id),
					'update_device_location': this.update_device_location.bind(this, hub_id, dev_id),
					'locate_device_on_map': this.locate_device_on_map.bind(this, dev_id),
					'update_device_date_install': this.update_device_date_install.bind(this, hub_id, dev_id),
					'update_device_date_review': this.update_device_date_review.bind(this, hub_id, dev_id),
					'update_device_review': this.update_device_review.bind(this, hub_id, dev_id),
					'update_device_warranty': this.update_device_warranty.bind(this, hub_id, dev_id),
					'update_device_renewal': this.update_device_renewal.bind(this, hub_id, dev_id),
				};

				el = DOMFactory.get_block_device_static_info(
					hub_id,
					dev_id,
					dev_data as InterfaceDeviceStatic,
					callbacks,
					is_admin
				)
				break;
			}
			case InterfaceDeviceType.ROTAMARKER_RW: {
				let callbacks:DeviceStaticInfoCallbacks = {
					'update_device_nickname': this.update_device_nickname.bind(this, hub_id, dev_id),
					'update_device_location': this.update_device_location.bind(this, hub_id, dev_id),
					'locate_device_on_map': this.locate_device_on_map.bind(this, dev_id),
					'update_device_date_install': this.update_device_date_install.bind(this, hub_id, dev_id),
					'update_device_date_review': this.update_device_date_review.bind(this, hub_id, dev_id),
					'update_device_review': this.update_device_review.bind(this, hub_id, dev_id),
					'update_device_warranty': this.update_device_warranty.bind(this, hub_id, dev_id),
					'update_device_renewal': this.update_device_renewal.bind(this, hub_id, dev_id),
				};

				el = DOMFactory.get_block_device_static_info(
					hub_id,
					dev_id,
					dev_data as InterfaceDeviceStatic,
					callbacks,
					is_admin
				)
				break;
			}
			case InterfaceDeviceType.ROTAMARKER_BW: {
				let callbacks:DeviceStaticInfoCallbacks = {
					'update_device_nickname': this.update_device_nickname.bind(this, hub_id, dev_id),
					'update_device_location': this.update_device_location.bind(this, hub_id, dev_id),
					'locate_device_on_map': this.locate_device_on_map.bind(this, dev_id),
					'update_device_date_install': this.update_device_date_install.bind(this, hub_id, dev_id),
					'update_device_date_review': this.update_device_date_review.bind(this, hub_id, dev_id),
					'update_device_review': this.update_device_review.bind(this, hub_id, dev_id),
					'update_device_warranty': this.update_device_warranty.bind(this, hub_id, dev_id),
					'update_device_renewal': this.update_device_renewal.bind(this, hub_id, dev_id),
				};

				el = DOMFactory.get_block_device_static_info(
					hub_id,
					dev_id,
					dev_data as InterfaceDeviceStatic,
					callbacks,
					is_admin
				)
				break;
			}
			case InterfaceDeviceType.ROTASENSOR: {
				let callbacks:DeviceSensorInfoCallbacks = {
					'update_device_nickname': this.update_device_nickname.bind(this, hub_id, dev_id),
					'update_device_location': this.update_device_location.bind(this, hub_id, dev_id),
					'identify_device': this.identify_device.bind(this, hub_id, dev_id),
					'locate_device_on_map': this.locate_device_on_map.bind(this, dev_id),
					'update_hub_threshold': this.update_hub_threshold.bind(this, hub_id, dev_id),
				};

				el = DOMFactory.get_block_device_sensor_info(
					hub_id,
					dev_id,
					dev_data as InterfaceDeviceSensor,
					callbacks,
					is_admin
				)

				break;
			}
			default: {  //InterfaceDeviceType.GENERIC
				let callbacks:DeviceBaseInfoCallbacks = {
					'update_device_nickname': this.update_device_nickname.bind(this, hub_id, dev_id),
					'update_device_location': this.update_device_location.bind(this, hub_id, dev_id),
					'locate_device_on_map': this.locate_device_on_map.bind(this, dev_id),
				};

				el = DOMFactory.get_block_device_generic_info(
					hub_id,
					dev_id,
					dev_data,
					callbacks,
					is_admin
				)
				break;
			}
		}

		return el;
	}


	async #get_hub_dom_from_data(hub_id:string, hub_data:InterfaceHub) {
		const current_hub_id = await this.#db.get_current_hub();

		//Administrator data
		const is_admin = hub_data['admin'] == this.#db.get_user_id();
		const admin_data = await this.#db.lookup_user(hub_data['admin']);
		const name_admin = admin_data ? admin_data.name_first + ' ' + admin_data.name_last : "[Private user]";
		//Other user data
		const names_users = [];
		if(hub_data.users) {
			for (const value of hub_data.users) {
				const user_data = await this.#db.lookup_user(value);
				names_users.push(user_data ? user_data.name_first + ' ' + user_data.name_last : "[Private user]");
			}
		}

		const callbacks:HubInfoCallbacks = {
			'update_hub_rename': this.update_hub_rename.bind(this, hub_id),
			'update_hub_owner': this.update_hub_owner.bind(this, hub_id),
			'update_hub_location': this.update_hub_location.bind(this, hub_id),
			'update_hub_overlays': this.update_hub_overlays.bind(this, hub_id),
			'show_device_list': this.show_device_list.bind(this, hub_id, is_admin),
			'identify_hub': this.identify_hub.bind(this, hub_id),
			'archive_hub': this.archive_hub.bind(this, hub_id),
		}

		//Get the DOM for the HUB
		const dom = DOMFactory.get_block_hub_info(
			hub_id,
			hub_data,
			name_admin,
			names_users,
			callbacks,
			(hub_id == current_hub_id) && (current_hub_id != null),
			is_admin
		);

		// //Add in associated device data
		// const dom_dev = <HTMLDivElement>dom.getElementsByClassName(`device-list`)[0];
		// if(dom_dev) {
		// 	const dev_ids = await this.#db.get_device_ids(hub_id);
		// 	if(dev_ids.length) {
		// 		dom_dev.style.display = 'flex';
		// 		for(const dev_id of dev_ids) {
		// 			const dev_data = await this.#db.get_device_data(dev_id, hub_id);
		// 			if(dev_data) {
		// 				dom_dev.appendChild(DOMFactory.get_block_device_info(
		// 					hub_id,
		// 					dev_id,
		// 					dev_data,
		// 					this.update_device_nickname.bind(this, hub_id, dev_id),
		// 					this.update_device_location.bind(this, hub_id, dev_id),
		// 					this.identify_device.bind(this, hub_id, dev_id),
		// 					this.locate_device_on_map.bind(this, dev_id),
		// 					this.update_hub_threshold.bind(this, hub_id, dev_id),
		// 					is_admin
		// 				));
		// 			} else {
		// 				console.warn(`Error getting device data for: ${dev_id}`);
		// 			}
		// 		}
		// 	} else {
		// 		dom_dev.style.display = 'none';
		// 	}
		// } else {
		// 	console.warn(`Could not find device list for hub: ${hub_id}`)
		// }

		return dom;
	}

	async locate_device_on_map(dev_id:string) {
		this.#core.open_page(CosecCorePages.MAP, dev_id);
	}

	async identify_device(hub_id:string, dev_id:string) {
		await this.#db.set_device_identify(dev_id, hub_id);
	}

	async update_hub_threshold(hub_id:string, dev_id:string, data_type:DATASET_ID, context:HTMLInputElement, parameter_type:WidgetMinNomMaxType, event:Event) {
		let value = context.valueAsNumber;
		if(data_type == DATASET_ID.HUMIDITY) {
			value /= 100.0;
		}

		await this.#db.update_device_threshold(dev_id, parameter_type, data_type, value, hub_id);
	}

	async update_hub_rename(hub_id:string) {
		const nickname = <HTMLInputElement>document.getElementById(`hub-${hub_id}-nickname-input`);
		await this.#db.update_hub_name(hub_id, nickname.value);
		await this.#core.hub_list_update_names(hub_id);
	}

	async update_hub_owner(hub_id:string) {
		const owner = <HTMLInputElement>document.getElementById(`hub-${hub_id}-owner-input`);
		await this.#db.update_hub_owner(hub_id, owner.value);
	}

	async update_hub_location(hub_id:string) {
		const lat = <HTMLInputElement>document.getElementById(`hub-${hub_id}-lat`);
		const lon = <HTMLInputElement>document.getElementById(`hub-${hub_id}-lon`);
		await this.#db.update_hub_location(hub_id, lat.valueAsNumber, lon.valueAsNumber);
	}

	async update_hub_overlays(hub_id:string) {
		const overlay = <HTMLInputElement>document.getElementById(`hub-${hub_id}-overlays-select`);
		const key = InterfaceHubGeoFeatures[overlay.value as keyof typeof InterfaceHubGeoFeatures];
		await this.#db.update_hub_overlays(hub_id, [key]);
	}

	async update_device_nickname(hub_id:string, dev_id:string) {
		const nickname = <HTMLInputElement>document.getElementById(`hub-${hub_id}-dev-${dev_id}-nickname-input`);
		await this.#db.update_device_name(hub_id, dev_id, nickname.value);
	}

	async update_device_location(hub_id:string, dev_id:string) {
		const lat = <HTMLInputElement>document.getElementById(`hub-${hub_id}-dev-${dev_id}-lat`);
		const lon = <HTMLInputElement>document.getElementById(`hub-${hub_id}-dev-${dev_id}-lon`);
		await this.#db.update_device_location(hub_id, dev_id, lat.valueAsNumber, lon.valueAsNumber);
	}

	async update_device_date_review(hub_id:string, dev_id:string) {
		const review = <HTMLInputElement>document.getElementById(`hub-${hub_id}-dev-${dev_id}-review-date`);
		await this.#db.update_device_date_review(hub_id, dev_id, review.valueAsDate);
	}

	async update_device_date_install(hub_id:string, dev_id:string) {
		const install = <HTMLInputElement>document.getElementById(`hub-${hub_id}-dev-${dev_id}-install-date`);
		await this.#db.update_device_date_install(hub_id, dev_id, install.valueAsDate);
	}

	async update_device_review(hub_id:string, dev_id:string) {
		const review = <HTMLInputElement>document.getElementById(`hub-${hub_id}-dev-${dev_id}-review-num`);
		await this.#db.update_device_review(hub_id, dev_id, review.valueAsNumber);
	}

	async update_device_warranty(hub_id:string, dev_id:string) {
		const warranty = <HTMLInputElement>document.getElementById(`hub-${hub_id}-dev-${dev_id}-warranty-num`);
		await this.#db.update_device_warranty(hub_id, dev_id, warranty.valueAsNumber);
	}

	async update_device_renewal(hub_id:string, dev_id:string) {
		const renewal = <HTMLInputElement>document.getElementById(`hub-${hub_id}-dev-${dev_id}-renewal-num`);
		await this.#db.update_device_renewal(hub_id, dev_id, renewal.valueAsNumber);
	}

	async update_device_lifespan(hub_id:string, dev_id:string) {
		const lifespan = <HTMLInputElement>document.getElementById(`hub-${hub_id}-dev-${dev_id}-lifespan-num`);
		await this.#db.update_device_lifespan(hub_id, dev_id, lifespan.valueAsNumber);
	}

	async identify_hub(hub_id:string) {
		await this.#db.identify_hub(hub_id);
	}

	async archive_hub(hub_id:string) {
		// var cf = sender.id.split('-');
		// const hub_id = cf.pop();
		// await this.#db.archive_hub(hub_id);
		await this.#core.archive_hub(hub_id);
	}

	async add_hub() {
		const hub_data = DBTools.create_hub(this.#db.get_user_id());
		const hub_id = await this.#db.add_hub(hub_data);
		if(hub_id) {
			console.log(`Created hub "${hub_id}"`);
			// this.#reload_specific_hub(hub_id, hub_data);
			const un_sub = await this.#db.watch_hub_data(this.#reload_specific_hub.bind(this), hub_id);
			this.#hub_watchers.push(un_sub);
		}
		this.#core.hub_list_changed();
	}

	async add_device(hub_id:string, type:InterfaceDeviceType) {
		const device_data = DBTools.create_device(type);
		const dev_id = await this.#db.add_device_to_hub(hub_id, device_data);
		if(dev_id) {
			console.log(`Created device ${dev_id} for hub ${hub_id}"`);
			//XXX: Watcher created by device list watcher
		}
		// this.#core.hub_list_changed();
	}

	// async archive_device(sender) {
	// 	var cf = sender.id.split('-');
	// 	const dev_id = cf.pop();
	// 	const hub_id = cf.pop();
	// 	this.#db.set_device_archive(hub_id, dev_id);
	// }

	async #device_list_changed(hub_id:string, is_admin:boolean) {
		const current_ids = Array.from(this.#device_watchers.keys());
		const db_ids = await this.#db.get_device_ids(hub_id);

		const old_ids = current_ids.filter(x => !db_ids.includes(x));
		const new_ids = db_ids.filter(x => !current_ids.includes(x));

		for(const id of old_ids) {
			if(this.#device_watchers.has(id)) {
				this.#clear_watcher(this.#device_watchers.get(id));
				this.#device_watchers.delete(id);
			}
		}

		for(const id of new_ids) {
			//XXX: This will fire cause reload_specific_device() to fire again, but not a huge deal
			const un_sub = await this.#db.watch_device_data(id, this.#reload_specific_device.bind(this, hub_id, is_admin), hub_id);
			this.#device_watchers.set(id, un_sub);
		}
	}

	async #reload_specific_device(hub_id:string, is_admin:boolean, dev_id:string, data:InterfaceDeviceBase|InterfaceDeviceStatic|InterfaceDeviceSensor) {
		//Reload our hub data
		console.log(`Refreshing device: ${hub_id}/${dev_id}`);
		let dom_dev = document.getElementById(`hubs-${hub_id}-dev-${dev_id}`);
		const dev_dom = await this.#get_device_dom_from_data(hub_id, dev_id, is_admin, data);
		if(dom_dev) {
			//Update the DOM
			dom_dev.replaceWith(dev_dom);
		} else {
			//Add new DOM
			this.#dev_list.insertBefore(dev_dom, document.getElementById('hub-dev-btn-group-add'));
		}
	}

	async #reload_specific_hub(hub_id:string, data:InterfaceHub) {
		//Reload our hub data
		console.log(`Refreshing hub: ${hub_id}`);
		let dom_hub = document.getElementById('hub-' + hub_id);
		if(dom_hub) {
			//Update the DOM
			if(!data.archived || this.show_archived()) {
				dom_hub.replaceWith(await this.#get_hub_dom_from_data(hub_id, data));
			} else {
				this.#hub_list.removeChild(dom_hub);
				if(this.#hub_list.childElementCount <= 1)
					this.#hub_list.insertBefore(this.#get_dom_no_hubs_message(), document.getElementById('hub-btn-add'));
			}
		} else {
			//Add new DOM
			this.#hub_list.insertBefore(await this.#get_hub_dom_from_data(hub_id, data), document.getElementById('hub-btn-add'));
			let no_hub = document.getElementById('special-hub-none');
			if(no_hub)
				no_hub.remove();
		}
	}

	// debugCreateEvent = async (prem_id, dev_id) => {
	// 	const ev_data = DBTools.create_event(dev_id);
	// 	const ev_id = await this.#db.debug_register_event(prem_id, ev_data);
	// 	console.log(`Registered event "${ev_id}"`);

	// 	this.openPage(this.current_page);
	// }

	async reset() {
		// let hub_list = document.getElementById('hub-list');
		// let hub_list_loading = document.getElementById('hub-list-loading');
		this.show_loading_list();
		// hub_list.style.display = 'none';
		// hub_list_loading.style.display = 'flex';
	}

	show_archived() {
		return (<HTMLInputElement>document.getElementById('hub-list-option-archived')).checked;
	}

	async #refresh_internal() {
		// let hub_list = document.getElementById('hub-list');
		this.#clear_watcher_list(this.#hub_watchers);
		this.#hub_watchers = [];

		this.#hub_list.innerHTML = '';

		// let hub_list_loading = document.getElementById('hub-list-loading');
		// hub_list_loading.style.display = 'none';
		// hub_list.style.display = 'flex';

		let hub_ids = await this.#db.get_hub_ids(this.show_archived());

		if(hub_ids.length > 0) {
			for (const id of hub_ids) {
				// this.#hub_list.appendChild(await this.#get_hub_dom(id));
				const un_sub = await this.#db.watch_hub_data(this.#reload_specific_hub.bind(this), id);
				this.#hub_watchers.push(un_sub);
			}
		} else {
			this.#hub_list.appendChild(this.#get_dom_no_hubs_message());
		}

		this.#hub_list.appendChild(DOMFactory.get_button_hub_add(this.add_hub.bind(this)));

		this.#show_hub_list();
	}

	#get_dom_no_hubs_message() {
		//Make sure there is only ever one
		let no_hub = document.getElementById('special-hub-none');
		if(no_hub)
			no_hub.remove();

		let el = document.createElement("div");
		let p = document.createElement("P");
		p.appendChild(document.createTextNode('There are no registered hubs!'));
		el.id = 'special-hub-none'
		el.className = 'hub-none device'
		el.appendChild(p);
		return el;
	}

	#clear_watcher_list(watchers:(() => void)[]) {
		for(let watcher of watchers) {
			this.#clear_watcher(watcher);
		}
	}

	#clear_watcher(watcher:()=>void) {
		if(watcher) {
			watcher();
		}
	}

	#clear_device_watchers() {
		this.#clear_watcher(this.#device_list_watcher);
		this.#device_list_watcher = null;

		this.#clear_watcher_list(Array.from(this.#device_watchers.values()));
		this.#device_watchers.clear();
	}

	#clear_watchers() {
		this.#clear_watcher_list(this.#hub_watchers);
		this.#hub_watchers = [];

		this.#clear_device_watchers();
	}

	async refresh(device_in_focus:string=null) {
		const focus_str = device_in_focus != null ? ` (focus: ${device_in_focus})` : "";
		console.log(`Refreshing ${this.#page_id}${focus_str}!`);

		this.#clear_watchers();
		await this.reset();

		// //XXX: This will also trigger our refresh
		// this.#hub_list_watcher = await this.#db.watch_hubs(this.#refresh_internal.bind(this));
		// //Final catch to force a refresh if the "watch devices" fails
		// if(!this.#hub_list_watcher)
		await this.#refresh_internal();
	}

	async hub_changed(old_current_id:string, new_current_id:string) {

		let old_hub = document.getElementById('hub-' + old_current_id);
		let new_hub = document.getElementById('hub-' + new_current_id);

		if(old_hub)
			old_hub.classList.remove('hub-item-current');

		if(new_hub)
			new_hub.classList.add('hub-item-current');
	}

	async deactivate() {
		console.log("Deactivating manage!");
		this.#clear_watchers();
	}
}
