import { map } from "leaflet";
import { CosecGeoPoint } from "./common"
import { DATASET_ID, InterfaceHubGeoFeatures, SEVERITY } from "./db_tools";
import { WidgetMinNomMaxType } from "./dom_factory";

export interface InterfaceUserProfile {
    name_first:string;
    name_last:string;
    mobile:string;
    email:string;
    datetime_created:Date;
    username:string;
}

export interface InterfaceDeviceFeatures {
    ANGLE:boolean;
    DISTANCE:boolean;
    HUMIDITY:boolean;
    IDENTIFY:boolean;
    PRESSURE:boolean;
    RELEASE:boolean;
    SOLAR_V:boolean;
    TEMPERATURE:boolean;
    VOLTAGE:boolean;
}

export interface InterfaceReadingInfo {
    features:InterfaceDeviceFeatures;
    identify:boolean;
    msg_type:number;
    release:boolean;
    timestamp: Date;
    uuid:string;
}

export interface InterfaceReadingAmbience {
    humidity:number;
    pressure:number;
    temperature:number;
}

export interface InterfaceReadingRange {
    angle:number;
    distance_adj:number;
    distance_raw:number;
}

export interface InterfaceReadingPower {
    voltage_batt:number;
    voltage_solar:number;
}


export interface InterfaceReadingAmbienceWarning {
    humidity:SEVERITY;
    pressure:SEVERITY;
    temperature:SEVERITY;
}

export interface InterfaceReadingRangeWarning {
    angle:SEVERITY;
    distance_adj:SEVERITY;
    distance_raw:SEVERITY;
}

export interface InterfaceReadingPowerWarning {
    voltage_batt:SEVERITY;
    voltage_solar:SEVERITY;
}

export interface InterfaceReading {
    info:InterfaceReadingInfo;
    ambience:InterfaceReadingAmbience;
    power:InterfaceReadingPower;
    range:InterfaceReadingRange;
}

export interface InterfaceReadingKeys {
    info?:InterfaceReadingInfo;
    ambience?:InterfaceReadingAmbience;
    power?:InterfaceReadingPower;
    range?:InterfaceReadingRange;
}

export interface InterfaceReadingWarnings {
    ambience:InterfaceReadingAmbienceWarning;
    power:InterfaceReadingPowerWarning;
    range:InterfaceReadingRangeWarning;
}

export interface InterfaceReadingWarningsSummary {
    high:InterfaceReadingWarnings;
    low:InterfaceReadingWarnings;
    max:SEVERITY;
}

export interface InterfaceReadingThresholds {
    min?:InterfaceReading;
    nom?:InterfaceReading;
    max?:InterfaceReading;
}

export enum InterfaceDeviceType {
    GENERIC="",
    ROTAFLAG="ROTAFLAG",
    ROTAMARKER_RW="ROTAMARKER_RW",
    ROTAMARKER_BW="ROTAMARKER_BW",
    ROTASENSOR="ROTASENSOR",
}


export const InterfaceDeviceTypeNames = new Map([
    [InterfaceDeviceType.GENERIC, "Generic"],
    [InterfaceDeviceType.ROTAFLAG, "Rota-Flag"],
    [InterfaceDeviceType.ROTAMARKER_RW, "Rota-Marka (R/W)"],
    [InterfaceDeviceType.ROTAMARKER_BW, "Rota-Marka (B/W)"],
    [InterfaceDeviceType.ROTASENSOR, "Rota-Sensor"],
]);

export class InterfaceDeviceBase {
    archived: boolean;
    type:InterfaceDeviceType;

    timestamp:Date;
    uuid:string;

    location:CosecGeoPoint;
    name:string;
}

export class InterfaceDeviceStatic extends InterfaceDeviceBase {
    date_install:Date;  //Date of installation
    date_review:Date;   //Date of last review
    review:number;      //Review flag of the device in days from last review date
    warranty:number;    //Warranty of the device in days from install date
    renewal:number;     //Renewal of the device in days from install date
}

export class InterfaceDeviceSensor extends InterfaceDeviceBase {
    identify:boolean;
    release:boolean;

    date_boot:Date;
    date_contacted:Date;
    msg_type:number;

    features:InterfaceDeviceFeatures;

    thresholds?:InterfaceReadingThresholds;
}

export class InterfaceDeviceKeys {
    archived?: boolean;

    identify?:boolean;
    release?:boolean;

    date_install?:Date;
    date_review?:Date;
    review?: number;
    renewal?: number;
    warranty?: number;
    lifespan?: number;

    date_boot?:Date;
    date_contacted?:Date;
    timestamp?:Date;
    uuid?:string;

    location?:CosecGeoPoint;
    name?:string;
    msg_type?:number;
    features?:InterfaceDeviceFeatures;

    thresholds?:InterfaceReadingThresholds;
}

export interface InterfaceHubFeatures {
    GNET:boolean;
    MESH:boolean;
    OTA_UPDATE:boolean;
    USER_IO:boolean;
    WIFI:boolean;
}

export interface InterfaceHubHardware {
    model:string;
    uuid:string;
}

export interface InterfaceHub {
    admin:string;
    users:string[];
    owner:string;
    name:string;

    activation_secret:string;
    archived:boolean;
    identify:boolean;
    location:CosecGeoPoint;

    date_allocated:Date;
    date_activated:Date;
    date_boot:Date;
    date_contacted:Date;
    date_updated:Date;

    features:InterfaceHubFeatures;
    hardware:InterfaceHubHardware;
    geo_features:InterfaceHubGeoFeatures[];
}

export interface InterfaceHubKeys {
    admin?:string;
    users?:string[];
    owner?:string;
    name?:string;

    activation_secret?:string;
    archived?:boolean;
    identify?:boolean;
    location?:CosecGeoPoint;

    date_allocated?:Date;
    date_activated?:Date;
    date_boot?:Date;
    date_contacted?:Date;
    date_updated?:Date;

    features?:InterfaceHubFeatures;
    hardware?:InterfaceHubHardware;
    geo_features?:InterfaceHubGeoFeatures[];
}

export interface InterfaceDatabase {
    load():void;

    // --== User Profile ==--
    user_logout():Promise<void>;
    user_login(email:string, password:string, login_callback:(success:boolean, failure_reason:string) => void):Promise<void>
    user_register(email:string, password:string):Promise<boolean>

    get_user_id():string;
    get_user_profile():InterfaceUserProfile;
    get_user_real_name():string;
    get_user_real_name_initials():string;

    // --== Hub ==--
    get_hub_ids(get_archived?:boolean):Promise<string[]>;
    get_default_hub_id():Promise<string>;
    clear_current_hub():void;
    set_current_hub(id:string):Promise<void>;

    get_current_hub():Promise<string>;
    get_current_hub_data():Promise<InterfaceHub>;
    get_current_hub_name():Promise<string>;

    get_device_ids(hub_id?:string):Promise<string[]>;

    // --== Readings ==--

    /**Get latest reading
     * @param  {string} dev_id device to get readings from
     * @param  {string} hub_id? hub id (if not for the current hub)
     * @returns Promise<InterfaceReading>
     */
    get_latest_reading(dev_id:string, hub_id?:string):Promise<InterfaceReading>;

    /**Get latest readings in descending order (latest first)
     * @param  {string} dev_id device to get readings from
     * @param  {number} count? number of samples to try and collect
     * @param  {string} hub_id? hub id (if not for the current hub)
     * @returns Promise<Map<string,InterfaceReading>>
     */
    get_readings(dev_id:string, count?:number, hub_id?:string):Promise<Map<string,InterfaceReading>>;

    /**Get readings older than stamp in descending order (latest first)
     * @param  {string} dev_id device to get readings from
     * @param  {Date} stamp timestamp to use for the comparison epoch
     * @param  {number} count? number of samples to try and collect
     * @param  {string} hub_id? hub id (if not for the current hub)
     * @returns Promise<Map<string,InterfaceReading>>
     */
    get_readings_after_desc(dev_id:string, stamp:Date, count?:number, hub_id?:string):Promise<Map<string,InterfaceReading>>;

    /**Get readings older than stamp in ascending order (oldest first)
     * @param  {string} dev_id device to get readings from
     * @param  {Date} stamp timestamp to use for the comparison epoch
     * @param  {number} count? number of samples to try and collect
     * @param  {string} hub_id? hub id (if not for the current hub)
     * @returns Promise<Map<string,InterfaceReading>>
     */
    get_readings_after_asc(dev_id:string, stamp:Date, count?:number, hub_id?:string):Promise<Map<string,InterfaceReading>>;

    /**Get readings younger than stamp in descending order (latest first)
     * @param  {string} dev_id device to get readings from
     * @param  {Date} stamp timestamp to use for the comparison epoch
     * @param  {number} count? number of samples to try and collect
     * @param  {string} hub_id? hub id (if not for the current hub)
     * @returns Promise<Map<string,InterfaceReading>>
     */
    get_readings_before_desc(dev_id:string, stamp:Date, count?:number, hub_id?:string):Promise<Map<string,InterfaceReading>>;

    /**Get readings younger than stamp in ascending order (oldest first)
     * @param  {string} dev_id device to get readings from
     * @param  {Date} stamp timestamp to use for the comparison epoch
     * @param  {number} count? number of samples to try and collect
     * @param  {string} hub_id? hub id (if not for the current hub)
     * @returns Promise<Map<string,InterfaceReading>>
     */
    get_readings_before_asc(dev_id:string, stamp:Date, count?:number, hub_id?:string):Promise<Map<string,InterfaceReading>>;

    /**Get readings younger than stamp_end and older than stamp_start in descending order (youngest first)
     * @param  {string} dev_id device to get readings from
     * @param  {Date} stamp_start timestamp to use for starting comparison epoch
     * @param  {Date} stamp_end timestamp to use for ending comparison epoch
     * @param  {number} count? number of samples to try and collect
     * @param  {string} hub_id? hub id (if not for the current hub)
     * @returns Promise<Map<string,InterfaceReading>>
     */
    get_readings_between_desc(dev_id:string, stamp_start:Date, stamp_end:Date, count?:number, hub_id?:string):Promise<Map<string,InterfaceReading>>;

    /**Get readings younger than stamp_end and older than stamp_start in ascending order (oldest first)
     * @param  {string} dev_id device to get readings from
     * @param  {Date} stamp_start timestamp to use for starting comparison epoch
     * @param  {Date} stamp_end timestamp to use for ending comparison epoch
     * @param  {number} count? number of samples to try and collect
     * @param  {string} hub_id? hub id (if not for the current hub)
     * @returns Promise<Map<string,InterfaceReading>>
     */
    get_readings_between_asc(dev_id:string, stamp_start:Date, stamp_end:Date, count?:number, hub_id?:string):Promise<Map<string,InterfaceReading>>;

    watch_readings(dev_id:string, callback:()=>void, hub_id?:string):Promise<() => void>;
    watch_devices(callback:()=>void, hub_id?:string):Promise<() => void>;
    watch_hub_data(callback:(hub_id:string, data:InterfaceHub)=>void, hub_id?:string):Promise<() => void>;
    watch_device_data(dev_id:string, callback:(dev_id:string, data:InterfaceDeviceBase|InterfaceDeviceStatic|InterfaceDeviceSensor)=>void, hub_id?:string):Promise<() => void>;

    // --== Utility ==--
    lookup_user(id:string):Promise<InterfaceUserProfile>;
    get_hub_data(id:string):Promise<InterfaceHub>;
    get_device_data(dev_id:string, hub_id?:string):Promise<InterfaceDeviceBase|InterfaceDeviceStatic|InterfaceDeviceSensor>;

    archive_hub(id:string):Promise<boolean>;
    identify_hub(id:string):Promise<boolean>;
    add_hub(hub_data:InterfaceHub):Promise<string>;
    add_device_to_hub(hub_id:string, device_data:InterfaceDeviceBase|InterfaceDeviceStatic|InterfaceDeviceSensor):Promise<string>;

    update_hub_sub_data(id:string, data:any):Promise<boolean>;
    update_hub_name(id:string, name:string):Promise<boolean>;
    update_hub_owner(id:string, owner:string):Promise<boolean>;
    update_hub_location(id:string, latitude:number, longitude:number):Promise<boolean>
    update_hub_overlays(id:string, overlays:InterfaceHubGeoFeatures[]):Promise<boolean>

    update_device_sub_data(hub_id:string, dev_id:string, data:any):Promise<boolean>;
    update_device_location(hub_id:string, dev_id:string, latitude:number, longitude:number):Promise<boolean>;
    update_device_name(hub_id:string, dev_id:string, name:string):Promise<boolean>;
    update_device_date_review(hub_id:string, dev_id:string, review:Date):Promise<boolean>;
    update_device_date_install(hub_id:string, dev_id:string, renewal:Date):Promise<boolean>;
    update_device_review(hub_id:string, dev_id:string, review:number):Promise<boolean>;
    update_device_renewal(hub_id:string, dev_id:string, renewal:number):Promise<boolean>;
    update_device_warranty(hub_id:string, dev_id:string, warranty:number):Promise<boolean>;
    update_device_lifespan(hub_id:string, dev_id:string, lifespan:number):Promise<boolean>;
    set_device_identify(dev_id:string, hub_id?:string):Promise<boolean>;
    update_device_threshold(dev_id:string, parameter_type:WidgetMinNomMaxType, data_type:DATASET_ID, value:number, hub_id?:string):Promise<boolean>;
}
