// import { DBTools } from "./db_tools";

// import L from 'leaflet'
// import { L } from 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.js';

import { CosecCorePages } from "./core";
import { CommonLib, CosecConfig } from "./common";
import { CosecGeoFeatureFiles, CosecGeoFeatureNames, DBTools, InterfaceHubGeoFeatureClass, InterfaceHubGeoFeatureClassColors, InterfaceHubGeoFeatures, SEVERITY } from "./db_tools";
import { DOMFactory } from "./dom_factory";
import { InterfaceDatabase, InterfaceDeviceBase, InterfaceDeviceSensor, InterfaceDeviceStatic, InterfaceDeviceType, InterfaceHub } from "./interface_database";
import { Leaflet, get_cosec_map_marker_severity, get_cosec_map_marker_for_device, get_cosec_map_marker_for_hub, DEFAULT_MAP_ICON_SIZE } from "./interface_leaflet"
import { InterfacePage } from "./interface_page";

interface CosecMapLayers {
    [id: string]: L.TileLayer;
}

interface CosecGeoJSON extends Leaflet.GeoJSON {
	cosec_has_tried_load?:boolean;
}

interface CosecGeoLayers {
    [id: string]: CosecGeoJSON;
}

// export CosecGeoFeaturePaths:string[] = [

// ];
// 	NODES_A = 'transpower/Transmission_Lines.geojson',
// 	NODES_B = 'transpower/Cook_Strait_Protection_Zone.geojson',
// 	// NODES_C = 'transpower/Structures.geojson',
// 	NODES_C = 'energy_queensland/tr.geojson',
// 	NODES_D = 'energy_queensland/high_voltage.geojson',
// 	NODES_E = 'energy_queensland/low_voltage.geojson',
// }

export enum CosecMapOverlay {
	NODES_A = 'transpower/Transmission_Lines.geojson',
	NODES_B = 'transpower/Cook_Strait_Protection_Zone.geojson',
	// NODES_C = 'transpower/Structures.geojson',
	NODES_C = 'energy_queensland/tr.geojson',
	NODES_D = 'energy_queensland/high_voltage.geojson',
	NODES_E = 'energy_queensland/low_voltage.geojson',
}

class CosecMapMarker {
	#dev_id:string;
	#dev_data:InterfaceDeviceBase|InterfaceDeviceStatic|InterfaceDeviceSensor;
	#marker:Leaflet.Marker;
	#refresh_watcher:()=>void;

	/**
	 * @param  {string} dev_id device id from firebase
	 * @param  {Leaflet.Marker} marker marker object from leaflet displayed on the map
	 * @param  {()=>void=null} watcher=null data subscriber to firebase backend (does the business from firebase)
	 */
	constructor(dev_id:string, marker:Leaflet.Marker, watcher:()=>void=null) {
		this.#dev_data = null;
		this.#dev_id = dev_id;
		this.#marker = marker;
		this.#refresh_watcher = watcher;
	}

	get_id() {
		return this.#dev_id;
	}

	get_marker() {
		return this.#marker;
	}

	get_watcher() {
		return this.#refresh_watcher;
	}

	set_latest_data(data:InterfaceDeviceBase|InterfaceDeviceStatic|InterfaceDeviceSensor) {
		this.#dev_data = data;
	}

	get_latest_data() {
		return this.#dev_data;
	}

	set_watcher(watcher:()=>void) {
		this.clear_watcher();
		this.#refresh_watcher = watcher;
	}

	clear_watcher() {
		if(this.#refresh_watcher) {
			this.#refresh_watcher();
			this.#refresh_watcher = null;
		}
	}
}

export class CosecMap implements InterfacePage {
	static t_zero = new Date(0);
	static t_one_week = 1000 * 60 * 60 * 24 * 7;
	#page_id:CosecCorePages;
	#refresh_watcher:()=>void;
	#focus_device:string;
	#map:Leaflet.Map;
	#db:InterfaceDatabase;
	#hub_marker:CosecMapMarker;
	#markers:CosecMapMarker[];
	#first_location_set:boolean;
	#last_valid_marker_count:number;
    #map_layers:CosecMapLayers;
    #line_layers:CosecGeoLayers;
    #map_layer_control:L.Control.Layers;
	#powerline_data_test:Map<CosecMapOverlay,Leaflet.GeoJSON>;

	constructor(page_id:CosecCorePages, db_interface:InterfaceDatabase) {
		this.#page_id = page_id;
		this.#db = db_interface;
		this.#refresh_watcher = null;
		this.#focus_device = null;
		this.#map = null;
		this.#map_layers = null;
		this.#line_layers = null;
		this.#map_layer_control = null;
		this.#markers = [];
		this.#hub_marker = null;
		this.#first_location_set = false;
		this.#last_valid_marker_count = 0;
		this.#powerline_data_test = new Map();
	}

	clear_marker(m:CosecMapMarker) {
		this.#map.removeLayer(m.get_marker());
		m.clear_watcher();
	}

	clear_markers(markers:CosecMapMarker[]) {
		for(const m of markers) {
			this.clear_marker(m);
		}
	}

	async update_hub_marker(hub_id:string, data:InterfaceHub) {
		if(data) {
			if(this.#hub_marker) {
				const marker = this.#hub_marker.get_marker();
				marker.setLatLng([
					data.location.latitude,
					data.location.longitude
				]);
				marker.bindPopup(`<b>${data.name}</b><br>${data.owner}`);

				if(!this.#map.hasLayer(marker)) {
					// marker.update();
				// } else {
					let hub_icon = get_cosec_map_marker_for_hub(DEFAULT_MAP_ICON_SIZE);
					marker.setIcon(hub_icon);
					marker.addTo(this.#map);
					// Leaflet.DomUtil.addClass(marker._icon, 'marker-hub');
					// .classList.add("marker-hub");
				}

				this.auto_zoom_map();
			}

			if(this.#map) {
				//Pop any loaded lines off of our map
				for(const line of Object.values(this.#line_layers)) {
					if(this.#map.hasLayer(line))
						line.remove();
				}

				//Load in any relevant lines to our map
				for(const feature of data.geo_features) {
					const key:InterfaceHubGeoFeatures = InterfaceHubGeoFeatures[feature as keyof typeof InterfaceHubGeoFeatures];
					const line = this.#line_layers[CosecGeoFeatureNames.get(key)];

					if(!this.#map.hasLayer(line))
						line.addTo(this.#map);
				}
			}
		} else {
			if(this.#hub_marker) {
				this.clear_marker(this.#hub_marker);
				this.#hub_marker = null;
			}
		}
	}

	async handle_device_marker_callback(device_id:string) {
		window.cosec_core.open_page(CosecCorePages.HISTORY, device_id);
	}

	max_severity(severities:SEVERITY[]) {
		let max = SEVERITY.NONE;
		for(const s of severities) {
			if(s > max)
				max = s;
		}

		return max;
	}

	severity_shortname(severity:SEVERITY) {
		let name = 'none';
		switch(severity) {
			case SEVERITY.INFO: {
				name = 'info';
				break;
			}
			case SEVERITY.WARN: {
				name = 'warn';
				break;
			}
			case SEVERITY.CRIT: {
				name = 'crit';
				break;
			}
			//default: none
		}

		return name;
	}

	async populate_device_marker_popup(marker:CosecMapMarker, ev:Event=null) {
		const data = marker.get_latest_data();
		const id = marker.get_id();
		const m = marker.get_marker();

		let extra_info:HTMLElement = null;
		let warnings:HTMLElement[] = [];
		let max_warning:SEVERITY = SEVERITY.NONE;

		let icon = null;

		switch(data.type) {
			case InterfaceDeviceType.ROTAFLAG: {
				const static_data = (data as InterfaceDeviceStatic);
				let review = DBTools.get_time_span_severity(static_data.date_review, static_data.review);
				let renewal = DBTools.get_time_span_severity(static_data.date_install, static_data.renewal);
				let warranty = DBTools.get_time_span_severity(static_data.date_install, static_data.warranty);
				warnings = DOMFactory.get_device_marker_popup_static_warnings(static_data.date_install, review, renewal, warranty);
				max_warning = this.max_severity([review.severity, renewal.severity, warranty.severity]);

				icon = get_cosec_map_marker_for_device(data.type, DEFAULT_MAP_ICON_SIZE, 'pulse-' + this.severity_shortname(max_warning));

				break;
			}
			case InterfaceDeviceType.ROTAMARKER_BW: {
				const static_data = (data as InterfaceDeviceStatic);
				let review = DBTools.get_time_span_severity(static_data.date_review, static_data.review);
				let renewal = DBTools.get_time_span_severity(static_data.date_install, static_data.renewal);
				let warranty = DBTools.get_time_span_severity(static_data.date_install, static_data.warranty);
				warnings = DOMFactory.get_device_marker_popup_static_warnings(static_data.date_install, review, renewal, warranty);
				max_warning = this.max_severity([review.severity, renewal.severity, warranty.severity]);

				icon = get_cosec_map_marker_for_device(data.type, DEFAULT_MAP_ICON_SIZE, 'pulse-' + this.severity_shortname(max_warning));

				break;
			}
			case InterfaceDeviceType.ROTAMARKER_RW: {
				const static_data = (data as InterfaceDeviceStatic);
				let review = DBTools.get_time_span_severity(static_data.date_review, static_data.review);
				let renewal = DBTools.get_time_span_severity(static_data.date_install, static_data.renewal);
				let warranty = DBTools.get_time_span_severity(static_data.date_install, static_data.warranty);
				warnings = DOMFactory.get_device_marker_popup_static_warnings(static_data.date_install, review, renewal, warranty);
				max_warning = this.max_severity([review.severity, renewal.severity, warranty.severity]);

				icon = get_cosec_map_marker_for_device(data.type, DEFAULT_MAP_ICON_SIZE, 'pulse-' + this.severity_shortname(max_warning));

				break;
			}
			case InterfaceDeviceType.ROTASENSOR: {
				const sensor_data = (data as InterfaceDeviceSensor);
				let reading = await this.#db.get_latest_reading(id);
				const summary = DBTools.get_reading_warnings(reading, sensor_data.thresholds);
				max_warning = summary.max;
				warnings = DOMFactory.get_device_marker_popup_sensor_warnings(summary);
				extra_info = DOMFactory.get_device_marker_popup_reading(
					data ? sensor_data.date_contacted : DBTools.date_invalid,
					reading);

				icon = get_cosec_map_marker_severity(max_warning);

				break;
			}
			default: { //InterfaceDeviceType.GENERIC:
				break;
			}
		}

		let new_dom = DOMFactory.get_device_marker_popup(
			id,
			data && data.name ? data.name : `Device: ${id}`,
			data.type,
			data.type == InterfaceDeviceType.ROTASENSOR ? this.handle_device_marker_callback.bind(this, id) : null
		);
		m.setPopupContent(new_dom);

		m.setIcon(icon ? icon : get_cosec_map_marker_severity(SEVERITY.NONE));
		// ev.popup.setContent(new_dom);

		// if(data_warnings.length)
		// 	m.icon

		for(const el of new_dom.children) {
			if(el.id.includes('reading')) {
				el.innerHTML = '';
				for(const ew of warnings)
					el.appendChild(ew);

				if(extra_info)
					el.appendChild(extra_info);
			}
			if(el.id.includes('information')) {
				if(data && data.location && (data.location.altitude != null)) {
					let info_altitude = document.createElement("div");
					info_altitude.className = 'map-popup-info';
					info_altitude.append(document.createTextNode(`Altitude: ${data.location.altitude.toFixed(2)}m AMSL`));
					el.appendChild(info_altitude);
				}
			}


		}

		//Trigger a refresh of the popup
		m.openPopup();
	}

	async update_device_marker(dev_id:string, data:InterfaceDeviceBase|InterfaceDeviceStatic|InterfaceDeviceSensor) {
		// console.log(data);
		const marker = this.#markers.find(x => x.get_id() == dev_id);

		if(data) {
			if(marker) {
				marker.set_latest_data(data);
				const m = marker.get_marker();
				if(m) {
					// //Trigger refresh if currently open
					if(m.isPopupOpen())
						this.populate_device_marker_popup(marker);

						let icon = null;

						let max_warning = SEVERITY.NONE;
						switch(data.type) {
							case InterfaceDeviceType.ROTAFLAG: {
								const static_data = (data as InterfaceDeviceStatic);
								let review = DBTools.get_time_span_severity(static_data.date_review, static_data.review);
								let renewal = DBTools.get_time_span_severity(static_data.date_install, static_data.renewal);
								let warranty = DBTools.get_time_span_severity(static_data.date_install, static_data.warranty);
								max_warning = this.max_severity([review.severity, renewal.severity, warranty.severity]);

								icon = get_cosec_map_marker_for_device(data.type, DEFAULT_MAP_ICON_SIZE, 'pulse-' + this.severity_shortname(max_warning));

								break;
							}
							case InterfaceDeviceType.ROTAMARKER_BW: {
								const static_data = (data as InterfaceDeviceStatic);
								let review = DBTools.get_time_span_severity(static_data.date_review, static_data.review);
								let renewal = DBTools.get_time_span_severity(static_data.date_install, static_data.renewal);
								let warranty = DBTools.get_time_span_severity(static_data.date_install, static_data.warranty);
								max_warning = this.max_severity([review.severity, renewal.severity, warranty.severity]);

								icon = get_cosec_map_marker_for_device(data.type, DEFAULT_MAP_ICON_SIZE, 'pulse-' + this.severity_shortname(max_warning));

								break;
							}
							case InterfaceDeviceType.ROTAMARKER_RW: {
								const static_data = (data as InterfaceDeviceStatic);
								let review = DBTools.get_time_span_severity(static_data.date_review, static_data.review);
								let renewal = DBTools.get_time_span_severity(static_data.date_install, static_data.renewal);
								let warranty = DBTools.get_time_span_severity(static_data.date_install, static_data.warranty);
								max_warning = this.max_severity([review.severity, renewal.severity, warranty.severity]);

								icon = get_cosec_map_marker_for_device(data.type, DEFAULT_MAP_ICON_SIZE, 'pulse-' + this.severity_shortname(max_warning));

								break;
							}
							case InterfaceDeviceType.ROTASENSOR: {
								const sensor_data = (data as InterfaceDeviceSensor);
								let reading = await this.#db.get_latest_reading(dev_id);
								const warnings = DBTools.get_reading_warnings(reading, sensor_data.thresholds);
								max_warning = warnings.max;

								icon = get_cosec_map_marker_severity(max_warning);
								break;
							}
							default: { //InterfaceDeviceType.GENERIC:
								break;
							}
						}

						m.setIcon(icon ? icon : get_cosec_map_marker_severity(SEVERITY.NONE));

					if(data.location) {
						// console.log(data.location);
						m.setLatLng([
							data.location.latitude,
							data.location.longitude
						]);

						if(!this.#map.hasLayer(m)) {
						// 	m.update();
						// } else {
							m.addTo(this.#map);
						}

						this.auto_zoom_map();
					}

				}
			}//TODO: Else?
		} else {
			if(marker) {
				const mid = this.#markers.indexOf(marker);
				this.clear_marker(marker);
				this.#markers.splice(mid, 1);
			}
		}
	}

	async load_overview() {
		//Step 1: Figure out if we have any new markers
		const hub_id = await this.#db.get_current_hub();
		const dev_ids = await this.#db.get_device_ids();

		//If hub marker exists and it's different, or if the hub ID exists and the marker doesn't
		const hub_changed = (this.#hub_marker) ? (this.#hub_marker.get_id() != hub_id) : new Boolean(hub_id);

		//If our hub marker has reset or changed
		if(!hub_id || hub_changed) {
			//We've changed hub ID, clear map
			this.clear_markers(this.#markers);
			this.#markers = [];
			if(this.#hub_marker) {
				this.clear_marker(this.#hub_marker);
				this.#hub_marker = null;
			}
			this.#last_valid_marker_count = 0;
		}

		//If we have a valid hub selected, and we have reason to regenerate the marker
		if(hub_id && hub_changed) {
			// const hub_data = await this.#db.get_current_hub_data();
			//If there is currently a hub selected and it has valid location data
			// if(hub_data && hub_data.location) {
			// const marker = Leaflet.marker();	//Location added in callback
			let m = Leaflet.marker([null, null]); //Data will be added on the location callback
			this.#hub_marker = new CosecMapMarker(hub_id, m);
			//XXX: This will also trigger our marker update so do it last
			// this.#hub_marker.set_watcher(await this.#db.watch_hub_data(this.update_hub_marker.bind(this), hub_id));
			// }
		}

		const current_ids = this.#markers.map(function(marker){
			return marker.get_id();
		});

		const old_ids = current_ids.filter(x => !dev_ids.includes(x));
		const old_markers = this.#markers.filter(x => old_ids.includes(x.get_id()));
		this.clear_markers(old_markers);

		const new_ids = dev_ids.filter(x => !current_ids.includes(x));
		for(const id of new_ids) {
			// //TODO: Get real device data
			// const name = "Device";
			// const caption = "More info!";
			// const marker = Leaflet.marker([
			// 	0.0,
			// 	0.0
			// ]);
			// marker.addTo(this.#map);
			// marker.bindPopup(`<b>${name}</b><br>${caption}`);
			let lm = Leaflet.marker([null, null]);	//Data will be added on the location callback
			lm.bindPopup(`<b>Device: ${id}</b>`);	//Bind a temporary popup
			const m_item = new CosecMapMarker(id, lm); //Create our marker class
			this.#markers.push(m_item); //Add it to our internal tracking (storing the data in to the entities that are currently displayed)
			lm.on('popupopen', this.populate_device_marker_popup.bind(this, m_item)); //Finally, bind the dynamic popup generation with all references
		}

		//XXX: This will also trigger our marker update
		await this.update_marker_data_watchers(hub_id);

		//Finally zoom map to fit all markers if new ones have been added
		// if(new_ids.length)
		this.auto_zoom_map();
	}

	async update_marker_data_watchers(hub_id:string) {
		if(this.#hub_marker && !this.#hub_marker.get_watcher()) {
			this.#hub_marker.set_watcher(await this.#db.watch_hub_data(this.update_hub_marker.bind(this), this.#hub_marker.get_id()));
		}

		for(const m_item of this.#markers) {
			if(!m_item.get_watcher())
				m_item.set_watcher(await this.#db.watch_device_data(m_item.get_id(), this.update_device_marker.bind(this), hub_id));
		}
	}

	#on_focus_lost(device:CosecMapMarker) {
		//If we haven't refocused, then clear focus
		if(this.#focus_device == device.get_id())
			this.#focus_device = null;

		//Remove this callback
		device.get_marker().off("popupclose");
	}

	auto_zoom_map() {
		let has_zoomed = false;

		const all_markers = this.#markers.map(function(marker){
			return marker;
		});

		if(this.#hub_marker)
			all_markers.push(this.#hub_marker);

		//XXX: auto zoom may be called with incomplete markers in list, so filter down before attempting a zoom
		const valid_markers = all_markers.filter(x => x.get_marker().getLatLng());

		//Try to zoom in on a specified device if it is listed
		if(this.#focus_device) {
			const focused_devices = valid_markers.filter(x => x.get_id() == this.#focus_device);

			if(focused_devices.length) {
				console.log(`Focusing on device: ${focused_devices[0].get_id()}`);
				const marker = focused_devices[0].get_marker();
				this.#map.setView(marker.getLatLng(), 19);
				marker.openPopup();
				marker.on("popupclose", this.#on_focus_lost.bind(this, focused_devices[0]));

				this.#first_location_set = true;
				has_zoomed = true;
			} else {
				console.warn(`Cannot match focus device for: ${this.#focus_device}`)
			}
		}

		//Rest of auto zoom
		if(!has_zoomed) {
			const valid_marker_items = valid_markers.map(function(marker){
				return marker.get_marker();
			});

			//Only perform the zoom if we have new markers in the pot
			if(valid_marker_items.length > this.#last_valid_marker_count) {
				this.#last_valid_marker_count = valid_marker_items.length;

				if(valid_marker_items.length > 1) {
					const group = Leaflet.featureGroup(valid_marker_items);
					this.#map.fitBounds(group.getBounds());
				} else if(valid_marker_items.length == 1) {
					//Focus on the singular marker at a reasonable zoom
					const marker = valid_marker_items[0];
					this.#map.setView(marker.getLatLng(), 13);
				}
				//Set this here just in-case we get a race condition with the location service
				this.#first_location_set = true;
			} else if (!this.#first_location_set) {
				//No markers to show, zoom to random location
				//TODO: Zoom to current location?
				this.#map.setView([-27.55, 153], 13);
				navigator.geolocation.getCurrentPosition((position) => {
					//Second check for the async-factor
					if(!this.#first_location_set)
						this.#map.setView([position.coords.latitude, position.coords.longitude], 13);
				});
				this.#first_location_set = true;
			} //else do nothing
		}
	}

	async reset() {
		if(!this.#map) {

			const tiles_grey = Leaflet.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
				attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
				maxZoom: 18,
				id: 'mapbox/streets-v11',
				tileSize: 512,
				zoomOffset: -1,
                // cspell:disable-next-line
                accessToken: 'pk.eyJ1Ijoia3llbW9ydG9uIiwiYSI6ImNreXBlczMxaTA5angyem55cnRwZno1enMifQ.ddD6u3a-KBq39DYMi8o_hw'
			});
			const tiles_satellite = Leaflet.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
				attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
				maxZoom: 18,
				id: 'mapbox/satellite-v9',
				tileSize: 512,
				zoomOffset: -1,
                // cspell:disable-next-line
				accessToken: 'pk.eyJ1Ijoia3llbW9ydG9uIiwiYSI6ImNreXBlczMxaTA5angyem55cnRwZno1enMifQ.ddD6u3a-KBq39DYMi8o_hw'
			});

            this.#map_layers = {
                Street: tiles_grey,
                Satellite: tiles_satellite
            };

            this.#line_layers = {};
			for(const feature of Object.keys(InterfaceHubGeoFeatures)) {
				const key:InterfaceHubGeoFeatures = InterfaceHubGeoFeatures[feature as keyof typeof InterfaceHubGeoFeatures];

				let geo:CosecGeoJSON = Leaflet.geoJSON(null, {style: this.get_geojson_style.bind(this, key)});
				geo.cosec_has_tried_load = false;
				geo.on('add', this.lazy_load_geo_json.bind(this, CosecGeoFeatureFiles.get(key)))
				this.#line_layers[CosecGeoFeatureNames.get(key)] = geo;
			}

			this.#map = Leaflet.map('interactive-map', {
                doubleClickZoom: false,
                layers: [
                    tiles_grey
                ],
                zoomControl: false
            });

            //Set an initial zoom
			this.#map.setView([-27.4771033, 153.0301422], 11);

			this.#map_layer_control = Leaflet.control.layers(this.#map_layers, this.#line_layers); //, otherLayers...
			this.#map_layer_control.addTo(this.#map);
		} else {
			//Already have a map, so just force a re-render
			this.#map.invalidateSize();
		}
	}

	async lazy_load_geo_json(data_path:string, event:Leaflet.LayerEvent) {
		let geo:CosecGeoJSON = event.target;

		if(!geo.cosec_has_tried_load) {
			geo.cosec_has_tried_load = true;

			let feature_data = await fetch(data_path, { cache: "force-cache" });
			if(feature_data.ok) {
				// let b:any = {a: 5};
				let geo_feature = await feature_data.json();
				let j = null;
				try{
					geo.addData(geo_feature);
					console.info(`Loaded overlay from: ${data_path}`);
				}
				catch(e) {
					console.warn(`Invalid overlay data from: ${data_path}`);
					// if(e instanceof Error)
					// 	console.warn(e.message);
				}
			} else {
				console.warn(`Unable to load overlay from: ${data_path}`);
			}
		} else {
			console.info(`Skipping loaded data for: ${data_path}`);
		}
	}

	async #refresh_internal() {
		this.reset();
		//XXX: this will zoom our page and reinstate all data callbacks
		await this.load_overview();
		// console.log("the page is trying to refresh")
		// let settings = window.cosec_core.settings.get_settings();

		// for making of the more efficient:
		//
		//let key_values = {
		//	"setting1": 'transpower/Transmission_Lines.geojson',
		//}

		// for(const (key, value) of key_values)
		// 	...


		// /** This if else loads or unloads data based on whether the checkbox parameter from page_settings.js is true/false
		//  * and loads in the geo-data by key accordingly. add more of these blocks of code and change the file from directory
		//  * and the settings.xyz to check xyz input id from page_settings.json
		//  */
		// if (CommonLib.config.get(CosecConfig.MAP_DISPLAY_NODES_A)) {
		// 	this.load_geo_data_by_key(CosecMapOverlay.NODES_A);
		// } else {
		// 	this.unload_geo_data_by_key(CosecMapOverlay.NODES_A);
		// }

		// if (CommonLib.config.get(CosecConfig.MAP_DISPLAY_NODES_B)) {
		// 	this.load_geo_data_by_key(CosecMapOverlay.NODES_B);
		// } else {
		// 	this.unload_geo_data_by_key(CosecMapOverlay.NODES_B);
		// }

		// i've excluded this file from loading because it seems to have unknown issues, suspect it's the file size
		// this.load_geo_data_by_key(CosecMapOverlay.NODES_C);
	}

	get_geojson_style(key:InterfaceHubGeoFeatures, feature:Leaflet.FeatureGroup) {
		let color = null;

		if(InterfaceHubGeoFeatureClass.has(key)) {
			const line_class = InterfaceHubGeoFeatureClass.get(key);
			if (InterfaceHubGeoFeatureClassColors.has(line_class)) {
				color = InterfaceHubGeoFeatureClassColors.get(line_class);
			}
		}

		if(!color)
			console.warn(`Unable to find line style for ${key}`)

		return color ? <Leaflet.PathOptions>{
			color: color,
		} : {};
	}

	// /** load geo-data by key, checks if each key has already been loaded with an if statement, uses the leaflet.ts function to add to the map
	//  * @param  {} key
	//  */
	// async load_geo_data_by_key(key:CosecMapOverlay) {
	// 	if (!this.#powerline_data_test.has(key)) {
	// 		this.#powerline_data_test.set(key, null);
	// 		let data_path = `/data_overlays/${key}`;
	// 		let feature_data = await fetch(data_path);
	// 		if(feature_data.ok) {
	// 			// let b:any = {a: 5};
	// 			let geo_feature = await feature_data.json();
	// 			let j = null;
	// 			try{
	// 				j = Leaflet.geoJSON(geo_feature);
	// 			}
	// 			catch(e) {
	// 				console.warn(`Invalid overlay data from: ${data_path}`);
	// 				// if(e instanceof Error)
	// 				// 	console.warn(e.message);
	// 			}

	// 			//Handle case of successful load
	// 			if(j) {
	// 				j.addTo(this.#map);
	// 				this.#powerline_data_test.set(key, j);
	// 				console.info(`Loaded overlay from: ${data_path}`);
	// 			}
	// 		} else {
	// 			console.warn(`Unable to load overlay from: ${data_path}`);
	// 		}
	// 	}
	// }

	// async unload_geo_data_by_key(key:CosecMapOverlay) {
	// 	if (this.#powerline_data_test.has(key)) {
	// 		let j = this.#powerline_data_test.get(key);
	// 		j.remove();
	// 		this.#powerline_data_test.delete(key);
	// 	}
	// }

	clearRefreshWatchers() {
		if (this.#refresh_watcher) {
			this.#refresh_watcher(); //Stop event listener
			this.#refresh_watcher = null;
		}

		if(this.#hub_marker) {
			this.#hub_marker.clear_watcher();
		}

		for(const m of this.#markers) {
			m.clear_watcher();
		}
	}

	async hub_changed(old_current_id:string, new_current_id:string) {
		this.deactivate();
		this.refresh();
	}

	async refresh(device_in_focus:string=null) {
		const focus_str = device_in_focus != null ? ` (focus: ${device_in_focus})` : "";
		console.log(`Refreshing ${this.#page_id}${focus_str}!`);
		this.clearRefreshWatchers();

		this.#focus_device = device_in_focus;

		//XXX: This will also trigger our refresh
		this.#refresh_watcher = await this.#db.watch_devices(this.#refresh_internal.bind(this));
		//Final catch to force a refresh if the "watch devices" fails
		if(!this.#refresh_watcher)
			await this.#refresh_internal();
	}

	async deactivate() {
		console.log("Deactivating map!");
		this.clearRefreshWatchers();
		this.#focus_device = null;
	}
}
