import {CosecCore, CosecCorePages} from './core';
// import { CommonLib } from './common';
import { InterfaceDatabase } from "./interface_database"
import { Chart } from 'chart.js';
import type { ChartConfiguration } from "chart.js";
import { show_login_screen } from './loader'
import { cosec_load_dom_icons } from './interface_fontawesome'
import { handleLoaderTasks } from './loader';

import "../css/index.css";

declare global {
    interface Window {
        database_interface: InterfaceDatabase;
        cosec_core: CosecCore;
        show_login_screen: CallableFunction;
        load_app_data: CallableFunction;
        login_finished:(success:boolean, failure_reason:string) => void;
        handleLoaderTasks: (context:HTMLFormElement) => void;
        navButtonOnClick: CallableFunction;
        htmlLegendPlugin: {
            id:string,
            afterUpdate:(chart:Chart, args:any, options:htmlLegendPluginOptions) => void,
        };
    }

    interface Navigator {
        mozApps: {
            install: (path:string) => any; // Or whatever is the type of the exitApp function
        }
    }
}

//XXX: These should always be populated by create_app_interfaces() before use
window.database_interface = null
window.cosec_core = null;

/** Toggles the initial application loading splash screen
 * @param  {boolean} show Sets the application to show the loader splash screen if true.
 */
 function show_loader(show:boolean) {
    const el_loader = document.getElementById('base-loader');
    const el_app = document.getElementById('base-app');
    el_loader.style.display = show ? 'flex' : 'none';
    el_app.style.display = show ? 'none' : 'flex';
}

function load_app_base() {
    cosec_load_dom_icons();
    show_login_screen();
    show_loader(false);
}

// try{
//     CommonLib.set_locale_use_imperial(Intl.DateTimeFormat().resolvedOptions().locale == 'en-US');
//     //CommonLib.set_locale_use_imperial(true);    //TODO: REMOVE
// }
// catch(err) {
//     console.warn(err);
// }

/**
 * Callback to run once the login process is completed so we can continue loading the app
 * @param  {} success Indicates the login status
 * @param  {} feedback Error message to show in the case that login was not successful
 */
 function login_finished(success=false, feedback="") {
    let but = <HTMLButtonElement>document.getElementById('loader-do-login');
    but.disabled = false;

    if(success) {
        //We're good to go and have logged in, load the application!
        // console.log(feedback);
        window.load_app_data();
    } else if(feedback) {
        let error_txt = document.getElementById('loader-login-error');
        error_txt.innerHTML = '';
        error_txt.appendChild(document.createTextNode(feedback));
        error_txt.style.visibility = "visible";
    }

    document.getElementById('loader-dots').style.display = 'none';
    document.getElementById('loader-user-area').style.display = 'flex';
}
window.login_finished = login_finished;
// window.show_login_screen = show_login_screen;

/**
 * Loads the application backend for use. This should be used as a callback from
 * the database interface when the database is connected, logged in, and ready to run.
 */
async function load_app_data() {
	window.database_interface.load();

    document.getElementById("loader").style.display = 'none';
    document.getElementById("app").style.display = 'flex';

    window.cosec_core.db_on_load();
}
window.load_app_data = load_app_data; //Bind to window for global access

/**
 * Handles the global page navigation, as well as user logout
 * @param  {} button_id The calling ID of the button pressed, used to extract the requested page_id
 */
async function navButtonOnClick(button_id:string) {
    if(!window.database_interface || !window.cosec_core)
        return;

    const id_name = button_id.split('-');
    const page_id = id_name[id_name.length-1];

    //Handle special cases, default is open a nav page
    if(page_id == 'logout') {
        await window.database_interface.user_logout();
        //Clear out any session details
        window.location.hash = '';
        //TODO: Maybe make this smoother?
        location.reload();
    } else if (page_id == 'install') {
            navigator.mozApps.install(location.href + 'manifest.webapp');
    } else if (Object.values<string>(CosecCorePages).includes(page_id)) {
        window.cosec_core.open_page(<CosecCorePages>page_id);
    } else {
        console.error(`Unknown page: ${page_id}`);
    }
}
window.navButtonOnClick = navButtonOnClick; //Bind to window for global access
window.load_app_data = load_app_data;
window.handleLoaderTasks = handleLoaderTasks; //Bind to window for global access

//====================================
// ChartJS separate legend for plots
//====================================
const getOrCreateLegendList = (chart:Chart, id:string) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');

    if (!listContainer) {
        listContainer = document.createElement('ul');
        listContainer.className = 'chart-js-legend-list';
        legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

interface htmlLegendPluginOptions {
    containerID:string;
}

const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart:Chart, args:any, options:htmlLegendPluginOptions) {
      const ul = getOrCreateLegendList(chart, options.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      for(let i=0; i<items.length; i++) {
        const item = items[i];

        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginLeft = '10px';

        li.onclick = () => {
          if ('type' in chart.config && (chart.config.type === 'pie' || chart.config.type === 'doughnut')) {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(i);
          } else {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle.toString();
        boxSpan.style.borderColor = item.strokeStyle.toString();
        boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.height = '20px';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.width = '20px';

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor.toString();
        textContainer.style.margin = "0";
        textContainer.style.padding = "0";
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      };
    }
};
window.htmlLegendPlugin = htmlLegendPlugin;

//====================================
// Web App installation button (Firefox Only)
//====================================
let installBtn = document.getElementById('nav-btn-install');
// eslint-disable-next-line no-unused-vars
let deferredInstallPrompt = null;
if(installBtn) {
    installBtn.style.display = 'none';

    //TODO!
    // This code shows the button if the apps platform is available
    // and this app isn't already installed.
    // if(navigator.mozApps) {
    //     let req = navigator.mozApps.getSelf();
    //     req.onsuccess = function() {
    //         if(!req.result) {
    //             installBtn.style.display = 'block';
    //         }
    //     };
    // }
    // window.addEventListener('beforeinstallprompt', (e) => {
    //     deferredInstallPrompt = e;
    //     installBtn.style.display = 'block';
    // });
}

//====================================
// Web App Service Worker
//====================================
const registerServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
        try {
            const registration = await navigator.serviceWorker.register(
                '/service-worker.js',
                { scope: '/', }
            );

            if (registration.installing) {
                console.log('Service worker installing');
            } else if (registration.waiting) {
                console.log('Service worker installed');
            } else if (registration.active) {
                console.log('Service worker active');
            }
        } catch (error) {
            console.error(`Registration failed with ${error}`);
        }
    }
};

registerServiceWorker();
document.addEventListener('DOMContentLoaded', load_app_base, false);
