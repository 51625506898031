import { CosecGeoPoint } from "../common";
import { InterfaceDeviceType } from "../interface_database";
import { HubDeviceLayout } from "../interface_devices";

const lake_sc_name_prefix = "AUS-GRD-LSC";
const lake_sc_install_1 = new Date("2023-04-12T09:37:08.347+10:00");
const lake_sc_install_2 = new Date("2023-04-12T09:56:23.926+10:00");
const lake_sc_install_3 = new Date("2023-04-12T13:12:58.859+10:00");
const lake_sc_install_4 = new Date("2023-04-12T14:10:48.541+10:00");
const lake_sc_install_5 = new Date("2023-04-12T14:59:59.020+10:00");

export const lake_st_clair:HubDeviceLayout = {
    hub_name: lake_sc_name_prefix,
    hub_owner: "AusGrid (Muswellbrook)",
    hub_location: new CosecGeoPoint(-32.5140285,150.8258169),
    hub_geo_features: [],
    devices: [
        {name: lake_sc_name_prefix + "-0001-0001", type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-32.3538693,151.2993094, 309.90), date_install: lake_sc_install_1},
        {name: lake_sc_name_prefix + "-0001-0002", type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-32.3540843,151.2998830, 316.74), date_install: lake_sc_install_2},
        {name: lake_sc_name_prefix + "-0001-0003", type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-32.3543163,151.3004079, 324.84), date_install: lake_sc_install_3},
        {name: lake_sc_name_prefix + "-0001-0004", type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-32.3545266,151.3009707, 333.12), date_install: lake_sc_install_4},
        {name: lake_sc_name_prefix + "-0001-0005", type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-32.3549363,151.3021009, 350.49), date_install: lake_sc_install_5},
    ],
}
