// import { DBTools } from "./db_tools";

import {CosecMap} from './page_map';
import {CosecNetwork} from './page_network';
import {CosecSensors} from './page_sensors';
import {CosecHistorySingle} from './page_history_single';
// import {CosecEvents} from './events';
import {CosecManage} from './page_manage';
import {CosecSettings} from './page_settings';
import { DatabaseMock } from './db_mock';
import { DBTools } from './db_tools';
import { InterfaceDatabase } from './interface_database';
import { InterfacePage } from './interface_page';
import { CommonLib } from './common';

export enum CosecCorePages {
	MAP = 'map',
	NETWORK = "network",
	HISTORY = "history",
	SENSORS = "sensors",
	MANAGE = "manage",
	SETTINGS = "settings",
}

export enum CosecCoreHashKey {
	PAGE_ID = 'page_id',
	HUB_ID = 'hub_id',
	DEV_ID = 'device_focus',
	DEMO = 'demo',
}

export class CosecCore {
	static HOME_PAGE = CosecCorePages.MAP;
	#db:InterfaceDatabase;
	#current_page:CosecCorePages;
	#device_in_focus:string;

	#pages:Map<CosecCorePages,InterfacePage>;
	// #page_map:CosecMap;
	// #page_network:CosecNetwork;
	// #page_sensors:CosecSensors;
	// #page_history:CosecHistory;
	// #page_manage:CosecManage;
	// #page_settings:CosecSettings;

	constructor(db_interface:InterfaceDatabase) {
		if(!db_interface) throw new Error('Provided interface is not valid!');
		this.#db = db_interface;

		this.#current_page = null;
		this.#device_in_focus = null;

		this.#pages = new Map<CosecCorePages,InterfacePage>([
			[CosecCorePages.MAP, new CosecMap(CosecCorePages.MAP, this.#db)],
			[CosecCorePages.NETWORK, new CosecNetwork(CosecCorePages.NETWORK, this.#db)],
			[CosecCorePages.SENSORS, new CosecSensors(CosecCorePages.SENSORS, this.#db)],
			[CosecCorePages.HISTORY, new CosecHistorySingle(CosecCorePages.HISTORY, this.#db)],
			[CosecCorePages.MANAGE, new CosecManage(CosecCorePages.MANAGE, this.#db, this)],
			[CosecCorePages.SETTINGS, new CosecSettings(CosecCorePages.SETTINGS, this.#db)],
		]);
	}

	get_page(page_id:CosecCorePages) {
		// let page:InterfacePage = null;
		// switch(page_id){
		// 	case CosecCorePages.MAP: {
		// 		page = this.#page_map;
		// 		break;
		// 	}
		// 	case CosecCorePages.NETWORK: {
		// 		page = this.#page_network;
		// 		break;
		// 	}
		// 	case CosecCorePages.HISTORY: {
		// 		page = this.#page_history;
		// 		break;
		// 	}
		// 	case CosecCorePages.SENSORS: {
		// 		page = this.#page_sensors;
		// 		break;
		// 	}
		// 	case CosecCorePages.MANAGE: {
		// 		page = this.#page_manage;
		// 		break;
		// 	}
		// 	case CosecCorePages.SETTINGS: {
		// 		page = this.#page_settings;
		// 		break;
		// 	}
		// }

		return this.#pages.has(page_id) ? this.#pages.get(page_id) : null;
	}

	async update_url_page() {
		let page_id = this.#current_page;
		let hub_id = await this.#db.get_current_hub();
		let dev_id = this.#device_in_focus;

		// if(!page_id && this.current_page)
		// 	page_id = this.current_page;

		let current_hub = await this.#db.get_current_hub();
		if(!hub_id && current_hub)
			hub_id = current_hub;

		let items = [];
		if(page_id)
			items.push(CosecCoreHashKey.PAGE_ID + '=' + page_id);
		if(hub_id)
			items.push(CosecCoreHashKey.HUB_ID + '=' + hub_id);
		if(dev_id)
			items.push(CosecCoreHashKey.DEV_ID + '=' + dev_id);

		//Handle demo and real databases independently
		if(this.#db instanceof DatabaseMock) {
			items.push(CosecCoreHashKey.DEMO + '=' + true);
		}

		if(items.length > 0) {
			window.location.hash = '#' + items.join('&');
		} else {
			window.location.hash = '';
		}
	}

	async reload_current_page() {
		this.open_page(this.#current_page);
	}

	async open_page(page_id:CosecCorePages, device_focus:string=null) {
		if(!page_id) {
			let u_page = DBTools.get_url_hash_item(CosecCoreHashKey.PAGE_ID);
			page_id = Object.values<string>(CosecCorePages).includes(u_page) ? <CosecCorePages>u_page : CosecCore.HOME_PAGE;
		}

		this.#device_in_focus = device_focus;

		let buttons = Array.from(document.getElementsByClassName("nav-btn"));
		buttons.forEach((el) => {
			el.classList.remove("nav-btn-active");
		});

		const buttonId = "nav-btn-" + page_id;
		let btnHit = buttons.find(element => element.id == buttonId);

		if(btnHit != null) {
			btnHit.classList.add("nav-btn-active");
		}

		//Hide the previous page
		if(this.#current_page) {
			try{
				document.getElementById(this.#current_page).style.display = 'none';
			}
			catch (error) {
				console.warn(`Could not match page ${this.#current_page}, ignoring`);
			}

			//Check to see if the current page supports deactivation
			let c_page = this.get_page(this.#current_page);
			if(c_page)
				await c_page.deactivate()
		} // Else, null, undefined, or empty

		//Display the new page
		document.getElementById(page_id).style.display = 'inline';
		this.#current_page = page_id;

		//Handle all of our dynamic refreshing
		let page = this.get_page(page_id);

		if(page) {
			try{
				await page.refresh(this.#device_in_focus);
			}
			catch(error) {
				console.error(error);
			}
		} else {
			console.error(`Unable to refresh page: ${page_id}`);
		}

		//Finally update our URL hash state
		//XXX: All options lead here, so this should take care of all URL hash updates
		await this.update_url_page();

		CommonLib.check_color_scheme_override();

	}

	async db_on_load() {
		await this.update_hub_select(DBTools.get_url_hash_item(CosecCoreHashKey.HUB_ID));
		//Force refresh to make sure we have a valid page loaded (may not happen if there's no hubs)
		await this.open_page(this.#current_page, DBTools.get_url_hash_item(CosecCoreHashKey.DEV_ID));
	}

	async update_hub_select(show_id:string) {
		let hub_content = <HTMLInputElement>document.getElementById("hub-select");
		let hub_empty = document.getElementById("hub-select-empty");
		hub_content.innerHTML = '';

		var hub_ids = await this.#db.get_hub_ids();

		if(hub_ids.length) {
			for (const hub_id of hub_ids) {
				let hub_opts = document.createElement('option');
				let hub = await this.#db.get_hub_data(hub_id);
				hub_opts.value = hub_id;
				hub_opts.appendChild(document.createTextNode(hub.name));
				hub_content.appendChild(hub_opts);
			}

			//Should also update the page as item has changed
			const selected_id = ((show_id == null) || !hub_ids.includes(show_id)) ? await this.#db.get_default_hub_id() : show_id;
			hub_content.value = selected_id;
			//XXX: This might be doubling up if .value = ... already calls, but oh well for now
			try {
				await this.#db.set_current_hub(selected_id);
			}
			catch(error) {
				console.log(error.message);
			}
		}

		//XXX: Do all our display changes at the end to not mess up the transition
		document.getElementById("hub-select-loading").style.display = "none";
		if(hub_ids.length > 0) {
			hub_empty.style.display = 'none';
			hub_content.style.display = "inline";
		} else {
			hub_content.style.display = 'none';
			hub_empty.style.display = 'flex';
		}

		//Force a refresh on the current opened page
		// await this.open_page(this.#current_page);
	}

	// async addHub() {
	// 	const hub_data = DBTools.create_hub(this.#db.get_user_id());
	// 	const hub_id = await this.#db.add_hub(hub_data);
	// 	console.log(`Created hub "${hub_id}"`);

	// 	//XXX: This will also refresh our page as the prem_id will be changed
	// 	// this.updatePremisesSelect(prem_id);
	// 	this.db_on_load();
	// }

	// addDeviceToPremises = async(prem_id) => {
	// 	const dev_id = await this.#db.add_device_to_premises(prem_id, DBTools.create_device(this.#db.get_user_id(), prem_id));
	// 	console.log(`Adding device "${dev_id}"" to premises "${prem_id}"`);
	// 	this.premises.reloadSpecificPremises(prem_id);
	// }

	async hub_selected(hub_id:string) {
		try {
			await this.#db.set_current_hub(hub_id)
		}
		catch(error) {
			console.log(error.message);
		}


		//Force a refresh on the current opened page
		await this.open_page(this.#current_page);
	}


	async archive_hub(hub_id:string) {
		await this.#db.archive_hub(hub_id);
		this.hub_list_changed(hub_id);

		const current_page = this.get_page(this.#current_page);
		if(current_page) {
			const current_hub = await this.#db.get_current_hub();
			await current_page.hub_changed(hub_id, current_hub);
		}
	}

	async hub_list_changed(hub_id:string = null) {
		// console.log(`Archiving hub "${hub_id}"" and associated devices`);
		// await this.#db.archive_hub(hub_id);
		//Update our premises select with either the new default (null) if we deleted the one we were
		//showing or with the current prem_id
		const hub_content = <HTMLInputElement>document.getElementById("hub-select")
		this.update_hub_select(hub_content.value == hub_id ? await this.#db.get_default_hub_id() : hub_content.value);
	}

	//Do a visual update to the hub names that are currently displayed
	async hub_list_update_names(hub_id:string = null) {
		//Loop through all hub ids, or just 1 case if provided
		var hub_ids = hub_id ? [hub_id] : await this.#db.get_hub_ids();
		let hub_content = <HTMLInputElement>document.getElementById("hub-select");

		if(hub_ids.length > 0) {
			for (const hub_id of hub_ids) {
				let success = false;
				let hub = await this.#db.get_hub_data(hub_id);

				//Try to match the correct option
				for(const opt of hub_content.getElementsByTagName('option')) {
					// console.log(`Checking: ${opt.value}`);
					if(opt.value == hub_id) {
						opt.text = hub.name;
						success = true;
						break;
					}
				}

				if (!success) {
					console.warn(`Unable to update hub name for ID: ${hub_id}`);
				}
			}
		}
	}

	// archiveDevice = async(dev_id) => {
	// 	console.log(`Archiving device "${dev_id}"`);
	// 	await this.#db.archive_device(dev_id);
	// 	//Update our premises page
	// 	// const premises_content = document.getElementById("premises-select");
	// 	// this.updatePremisesSelect(premises_content.value);
	// 	this.#db_on_load();
	// }

	// clearEventStatus = async(context, ev_id) => {
	// 	//Clear event and get the new data
	// 	const event = await this.#db.clear_event(ev_id);

	// 	//Update the UI if successful
	// 	if(event != null) {
	// 		this.events.updateStatus(context, ev_id, event);
	// 	} else {
	// 		console.error(`Error: event ${ev_id} could not be cleared!`);
	// 	}
	// }

	// setIdentify = async (dev_id) => {
	// 	this.#db.set_device_identify(dev_id);
	// }

	// debugCreateEvent = async (prem_id, dev_id) => {
	// 	const ev_data = DBTools.create_event(dev_id);
	// 	const ev_id = await this.#db.debug_register_event(prem_id, ev_data);
	// 	console.log(`Registered event "${ev_id}"`);

	// 	this.openPage(this.current_page);
	// }
}
