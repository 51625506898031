import { CommonLib } from "./common";
import { CosecGeoFeatureNames, DATASET_ID, DBTools, InterfaceHubGeoFeatures, LifetimeWarningData, SEVERITY } from "./db_tools";
import { InterfaceDeviceBase, InterfaceDeviceStatic, InterfaceDeviceSensor, InterfaceHub, InterfaceReading, InterfaceReadingWarningsSummary, InterfaceDeviceType, InterfaceDeviceTypeNames } from "./interface_database";
import { CosecIcons, cosec_get_icon } from "./interface_fontawesome";


export interface HubInfoCallbacks {
	update_hub_rename:EventCallback;
	update_hub_owner:EventCallback;
	update_hub_location:EventCallback;
	update_hub_overlays:EventCallback;
	show_device_list:EventCallback;
	identify_hub:EventCallback;
	archive_hub:EventCallback;
}

export interface DeviceBaseInfoCallbacks {
	update_device_nickname:EventCallback;
	update_device_location:EventCallback;
	locate_device_on_map:EventCallback;
}

//TODO: Make these callbacks call function returning new value: (value:type)=>void
export interface DeviceStaticInfoCallbacks extends DeviceBaseInfoCallbacks {
	update_device_date_install:EventCallback;
	update_device_date_review:EventCallback;
	update_device_review:EventCallback;
	update_device_warranty:EventCallback;
	update_device_renewal:EventCallback;
}

//TODO: Make these callbacks call function returning new value: (value:type)=>void
export interface DeviceSensorInfoCallbacks extends DeviceBaseInfoCallbacks {
	identify_device:EventCallback;
	update_hub_threshold:EventCallback;
}

export interface DOMGraphBlock {
	dom:HTMLElement;
	dom_collapsible:HTMLElement;
	dom_expand:HTMLElement;
	canvas_items:HTMLCanvasElement[];
	legend_items:HTMLElement[];
}

export enum WidgetMinNomMaxType {
	MINIMUM,
	NOMINAL,
	MAXIMUM
}

export interface WidgetMinNomMaxSettings {
	value:number;
	min:number;
	max:number;
}

export type EventCallback = (event:Event)=>void
export type WidgetMinNomMaxEventCallback = (context:HTMLInputElement, parameter_type:WidgetMinNomMaxType, event:Event)=>void
// export type DataWidgetMinNomMaxEventCallback = (context:HTMLInputElement, parameter_type:WidgetMinNomMaxType, data_type:DATASET_ID, event:Event)=>void

export class DOMFactory {

    static clear_list(parentDOM:HTMLElement) {
        while (parentDOM.firstChild) {
            parentDOM.firstChild.remove();
        }
    }

    static get_loading_dots() {
        const d = document.createElement("div");
        d.className = 'dot-flashing'
        return d;
    }

	//XXX: This return is a bit funky as we would like the canvas list as well
	static get_block_graph(id_prefix:string, title:string, uid:string, count:number=0, expand_plots_callback:EventCallback=null, view_map_callback:EventCallback=null) {
		let dom = document.createElement("div");
		dom.id = `${id_prefix}-${uid}-graph`;
		dom.className = `${id_prefix}-graph-item shadow-box notched-corners-x4`;

		let ret:DOMGraphBlock = {
			dom: dom,
			dom_collapsible: null,
			dom_expand: null,
			canvas_items: [],
			legend_items: []
		};

		let h = document.createElement("div");
		h.className = `${id_prefix}-graph-item-name`;

		if(expand_plots_callback) {
			let h0 = document.createElement("button");
			h0.className = `${id_prefix}-graph-item-name-item ${id_prefix}-btn-expand`;
			h0.onclick = expand_plots_callback;
			for(const h0i of cosec_get_icon(CosecIcons.ACTION_EXPAND)) {
				h0.appendChild(h0i);
			}
			ret.dom_expand = h0;
			h.appendChild(h0);
		}

		let h1 = document.createElement("span");
		h1.className = `${id_prefix}-graph-item-name-item`;
		h1.appendChild(document.createTextNode(title));
		h.appendChild(h1);

		if(view_map_callback) {
			let h2 = document.createElement("button");
			h2.className = `${id_prefix}-graph-item-name-item ${id_prefix}-btn-goto-map`;
			h2.onclick = view_map_callback;
			for(const i of cosec_get_icon(CosecIcons.MENU_MAP))
				h2.appendChild(i);
			h.appendChild(h2);
		}

		let d = document.createElement("div");
		d.id = `${id_prefix}-${uid}-graph-internal`;
		d.className = `${id_prefix}-graph-item-internal`;

		ret.dom_collapsible = d;

		for(let i=0; i<count; i++) {
			let dg = document.createElement("div");
			dg.className = `${id_prefix}-graph-item`;

			let l = document.createElement("div");
			l.id = `${id_prefix}-${uid}-legend-${i}`;
			l.className = `${id_prefix}-plot-item-legend`;

			let dc = document.createElement("div");
			dc.className = `${id_prefix}-plot-item`;
			let c = document.createElement("canvas");
			c.id = `${id_prefix}-${uid}-canvas-${i}`;
			// c.className = `${id_prefix}-canvas-item`;
			dc.appendChild(c);

			dg.appendChild(l);
			dg.appendChild(dc);
			d.appendChild(dg);

			ret.canvas_items.push(c);
			ret.legend_items.push(l);
		}

		dom.appendChild(h);
		dom.appendChild(d);

		return ret;
	}

	static get_block_device_generic_info(hub_id:string, dev_id:string, data:InterfaceDeviceBase, callbacks:DeviceBaseInfoCallbacks, enabled:boolean=true) {
		// console.log(`Loading ${dev_id}`);
		let dev = document.createElement("div");
		dev.id = 'hub-' + hub_id + '-dev-' + dev_id;
		dev.className = 'device-item shadow-box notched-corners-x4';

		let c1 = document.createElement('div');
		c1.className = 'device-item-details';

		let s = document.createElement("div");
		s.className = 'device-item-content';
		let h = DOMFactory.get_block_input_textbox(data.name, callbacks.update_device_nickname, null, dev.id + '-nickname', enabled);
		// let h = document.createElement("H3");
		// h.append(document.createTextNode(data.nickname));
		s.appendChild(h);

		let s2 = document.createElement("div");
		s2.className = 'device-item-content';
		let p2 = document.createElement("p");
		p2.append(document.createTextNode('ID: ' + dev_id));
		s2.appendChild(p2);

		for(let i of cosec_get_icon(CosecIcons.ITEM_DEVICE)) {
			i.classList.add('device-item-content');
			i.classList.add('device-item-logo');
			dev.appendChild(i);
		}
		c1.appendChild(s);
		c1.appendChild(s2);

		let bm = document.createElement("button");
		bm.id = 'identify-' + hub_id + "-" + dev_id;
		bm.className = 'device-btn-identify shadow-box notched-corners-x4';
		bm.onclick = callbacks.locate_device_on_map;
		if(!enabled) {
			bm.disabled = true;
		}
		bm.append(document.createTextNode('Show on Map'));
		c1.appendChild(bm);

		const latitude = (data.location) ? data.location.latitude : 0.0;
		const longitude = (data.location) ? data.location.longitude : 0.0;
		let s3 = DOMFactory.get_block_input_lat_lon(latitude, longitude, callbacks.update_device_location, dev.id, enabled);
		c1.appendChild(s3);

		dev.appendChild(c1);

		return dev;
	}

	static get_block_device_static_info(hub_id:string, dev_id:string, data:InterfaceDeviceStatic, callbacks:DeviceStaticInfoCallbacks, enabled:boolean=true) {
		// console.log(`Loading ${dev_id}`);
		let dev = document.createElement("div");
		dev.id = 'hub-' + hub_id + '-dev-' + dev_id;
		dev.className = 'device-item shadow-box notched-corners-x4';

		let c1 = document.createElement('div');
		c1.className = 'device-item-details';

		let s = document.createElement("div");
		s.className = 'device-item-content';
		let h = DOMFactory.get_block_input_textbox(data.name, callbacks.update_device_nickname, null, dev.id + '-nickname', enabled);
		// let h = document.createElement("H3");
		// h.append(document.createTextNode(data.nickname));
		s.appendChild(h);

		let s2 = document.createElement("div");
		s2.className = 'device-item-content';
		let p2 = document.createElement("p");
		p2.append(document.createTextNode('ID: ' + dev_id));
		s2.appendChild(p2);

		let s5 = document.createElement("div");
		s5.className = 'device-item-content';
		let p5 = document.createElement("p");
		let dec_label = 'Type: ' + data.type;
		p5.append(document.createTextNode(dec_label));
		s5.appendChild(p5);

		let s41 = document.createElement("div");
		s41.className = 'device-item-content';
		let h41 = DOMFactory.get_block_input_date(data.date_install, "Install date", callbacks.update_device_date_install, dev.id + '-install', enabled);
		// let h = document.createElement("H3");
		// h.append(document.createTextNode(data.nickname));
		s41.appendChild(h41);

		let s42 = document.createElement("div");
		s42.className = 'device-item-content';
		let h42 = DOMFactory.get_block_input_date(data.date_review, "Review date", callbacks.update_device_date_review, dev.id + '-review', enabled);
		// let h = document.createElement("H3");
		// h.append(document.createTextNode(data.nickname));
		s42.appendChild(h42);

		let s43 = document.createElement("div");
		s43.className = 'device-item-content';
		let h43 = DOMFactory.get_block_input_number(data.review, "Days until review", callbacks.update_device_review, dev.id + '-review', enabled);
		// let h = document.createElement("H3");
		// h.append(document.createTextNode(data.nickname));
		s43.appendChild(h43);

		let s44 = document.createElement("div");
		s44.className = 'device-item-content';
		let h44 = DOMFactory.get_block_input_number(data.warranty, "Days under warranty", callbacks.update_device_warranty, dev.id + '-warranty', enabled);
		// let h = document.createElement("H3");
		// h.append(document.createTextNode(data.nickname));
		s44.appendChild(h44);

		let s45 = document.createElement("div");
		s45.className = 'device-item-content';
		let h45 = DOMFactory.get_block_input_number(data.renewal, "Days until renewal", callbacks.update_device_renewal, dev.id + '-renewal', enabled);
		// let h = document.createElement("H3");
		// h.append(document.createTextNode(data.nickname));
		s45.appendChild(h45);

		// let s46 = document.createElement("div");
		// s46.className = 'device-item-content';
		// let h46 = DOMFactory.get_block_input_number(data.lifespan, "Days of lifespan", callbacks.update_device_lifespan, dev.id + '-lifespan', enabled);
		// // let h = document.createElement("H3");
		// // h.append(document.createTextNode(data.nickname));
		// s46.appendChild(h46);


		for(let i of cosec_get_icon(CosecIcons.ITEM_DEVICE)) {
			i.classList.add('device-item-content');
			i.classList.add('device-item-logo');
			dev.appendChild(i);
		}
		c1.appendChild(s);
		c1.appendChild(s2);
		c1.appendChild(s5);
		// c1.appendChild(s3);
		c1.appendChild(s41);
		c1.appendChild(s42);
		c1.appendChild(s43);
		c1.appendChild(s44);
		c1.appendChild(s45);
		// c1.appendChild(s46);

		let bm = document.createElement("button");
		bm.id = 'identify-' + hub_id + "-" + dev_id;
		bm.className = 'device-btn-identify shadow-box notched-corners-x4';
		bm.onclick = callbacks.locate_device_on_map;
		if(!enabled) {
			bm.disabled = true;
		}
		bm.append(document.createTextNode('Show on Map'));
		c1.appendChild(bm);

		const latitude = (data.location) ? data.location.latitude : 0.0;
		const longitude = (data.location) ? data.location.longitude : 0.0;
		let s3 = DOMFactory.get_block_input_lat_lon(latitude, longitude, callbacks.update_device_location, dev.id, enabled);
		c1.appendChild(s3);

		dev.appendChild(c1);

		return dev;
	}

	static get_block_device_sensor_info(hub_id:string, dev_id:string, data:InterfaceDeviceSensor, callbacks:DeviceSensorInfoCallbacks, enabled:boolean=true) {
		// console.log(`Loading ${dev_id}`);
		let dev = document.createElement("div");
		dev.id = 'hub-' + hub_id + '-dev-' + dev_id;
		dev.className = 'device-item shadow-box notched-corners-x4';

		let c1 = document.createElement('div');
		c1.className = 'device-item-details';

		let s = document.createElement("div");
		s.className = 'device-item-content';
		let h = DOMFactory.get_block_input_textbox(data.name, callbacks.update_device_nickname, null, dev.id + '-nickname', enabled);
		// let h = document.createElement("H3");
		// h.append(document.createTextNode(data.nickname));
		s.appendChild(h);

		let s2 = document.createElement("div");
		s2.className = 'device-item-content';
		let p2 = document.createElement("p");
		p2.append(document.createTextNode('ID: ' + dev_id));
		s2.appendChild(p2);

		let s4 = document.createElement("div");
		s4.className = 'device-item-content';
		let p4 = document.createElement("p");
		let dec_label = 'Last seen: ' + CommonLib.time_since(data.date_contacted);
		p4.append(document.createTextNode(dec_label));
		s4.appendChild(p4);

		for(let i of cosec_get_icon(CosecIcons.ITEM_DEVICE)) {
			i.classList.add('device-item-content');
			i.classList.add('device-item-logo');
			dev.appendChild(i);
		}
		c1.appendChild(s);
		c1.appendChild(s2);
		// c1.appendChild(s3);
		c1.appendChild(s4);

		let bi = document.createElement("button");
		bi.id = 'identify-' + hub_id + "-" + dev_id;
		bi.className = 'device-btn-identify shadow-box notched-corners-x4';
		bi.onclick = callbacks.identify_device;
		if(!enabled) {
			bi.disabled = true;
		}
		bi.append(document.createTextNode('Identify'));
		c1.appendChild(bi);

		let bm = document.createElement("button");
		bm.id = 'identify-' + hub_id + "-" + dev_id;
		bm.className = 'device-btn-identify shadow-box notched-corners-x4';
		bm.onclick = callbacks.locate_device_on_map;
		if(!enabled) {
			bm.disabled = true;
		}
		bm.append(document.createTextNode('Show on Map'));
		c1.appendChild(bm);

		const latitude = (data.location) ? data.location.latitude : 0.0;
		const longitude = (data.location) ? data.location.longitude : 0.0;
		let s3 = DOMFactory.get_block_input_lat_lon(latitude, longitude, callbacks.update_device_location, dev.id, enabled);
		c1.appendChild(s3);

		let c2 = document.createElement('div');
		c2.className = 'device-item-settings';

		for(const col_label of ['','Min.','Nom.','Max.']) {
			let col_d = document.createElement('div');
			col_d.appendChild(document.createTextNode(col_label));
			c2.appendChild(col_d);
		}

		const thresholds_ambiance_humidity = DOMFactory.create_min_nom_max(
			'Humid.',
			callbacks.update_hub_threshold.bind(this, DATASET_ID.HUMIDITY),
			{value:  data.thresholds && data.thresholds.min && (data.thresholds.min.ambience.humidity != null) ? data.thresholds.min.ambience.humidity*100 : null, min: 0.0, max: 100.0},
			{value:  data.thresholds && data.thresholds.nom && (data.thresholds.nom.ambience.humidity != null) ? data.thresholds.nom.ambience.humidity*100 : null, min: 0.0, max: 100.0},
			{value:  data.thresholds && data.thresholds.max && (data.thresholds.max.ambience.humidity != null) ? data.thresholds.max.ambience.humidity*100 : null, min: 0.0, max: 100.0},
		);
		for(const el of thresholds_ambiance_humidity)
			c2.appendChild(el);

		const thresholds_ambiance_pressure = DOMFactory.create_min_nom_max(
			'Press.',
			callbacks.update_hub_threshold.bind(this, DATASET_ID.PRESSURE),
			{value:  data.thresholds && data.thresholds.min ? data.thresholds.min.ambience.pressure : null, min: 0.0, max: null},
			{value:  data.thresholds && data.thresholds.nom ? data.thresholds.nom.ambience.pressure : null, min: 0.0, max: null},
			{value:  data.thresholds && data.thresholds.max ? data.thresholds.max.ambience.pressure : null, min: 0.0, max: null},
		);
		for(const el of thresholds_ambiance_pressure)
			c2.appendChild(el);

		const thresholds_ambiance_temperature = DOMFactory.create_min_nom_max(
			'Temp.',
			callbacks.update_hub_threshold.bind(this, DATASET_ID.TEMPERATURE),
			{value:  data.thresholds && data.thresholds.min ? data.thresholds.min.ambience.temperature : null, min: 0.0, max: null},
			{value:  data.thresholds && data.thresholds.nom ? data.thresholds.nom.ambience.temperature : null, min: 0.0, max: null},
			{value:  data.thresholds && data.thresholds.max ? data.thresholds.max.ambience.temperature : null, min: 0.0, max: null},
		);
		for(const el of thresholds_ambiance_temperature)
			c2.appendChild(el);

		const thresholds_power_batt = DOMFactory.create_min_nom_max(
			'Batt.',
			callbacks.update_hub_threshold.bind(this, DATASET_ID.VBATT),
			{value:  data.thresholds && data.thresholds.min ? data.thresholds.min.power.voltage_batt : null, min: 3.5, max: 4.2},
			{value:  data.thresholds && data.thresholds.nom ? data.thresholds.nom.power.voltage_batt : null, min: 3.5, max: 4.2},
			{value:  data.thresholds && data.thresholds.max ? data.thresholds.max.power.voltage_batt : null, min: 3.5, max: 4.2},
		);
		for(const el of thresholds_power_batt)
			c2.appendChild(el);

		const thresholds_power_solar = DOMFactory.create_min_nom_max(
			'Solar',
			callbacks.update_hub_threshold.bind(this, DATASET_ID.VSOLAR),
			{value:  data.thresholds && data.thresholds.min ? data.thresholds.min.power.voltage_solar : null, min: 0.0, max: null},
			{value:  data.thresholds && data.thresholds.nom ? data.thresholds.nom.power.voltage_solar : null, min: 0.0, max: null},
			{value:  data.thresholds && data.thresholds.max ? data.thresholds.max.power.voltage_solar : null, min: 0.0, max: null},
		);
		for(const el of thresholds_power_solar)
			c2.appendChild(el);

		const thresholds_range_angle = DOMFactory.create_min_nom_max(
			'Angle',
			callbacks.update_hub_threshold.bind(this, DATASET_ID.ANGLE),
			{value:  data.thresholds && data.thresholds.min ? data.thresholds.min.range.angle : null, min: -180, max: 180},
			{value:  data.thresholds && data.thresholds.nom ? data.thresholds.nom.range.angle : null, min: -180, max: 180},
			{value:  data.thresholds && data.thresholds.max ? data.thresholds.max.range.angle : null, min: -180, max: 180},
		);
		for(const el of thresholds_range_angle)
			c2.appendChild(el);

		const thresholds_range_distance_raw = DOMFactory.create_min_nom_max(
			'Rng.R',
			callbacks.update_hub_threshold.bind(this, DATASET_ID.DISTRAW),
			{value:  data.thresholds && data.thresholds.min ? data.thresholds.min.range.distance_raw : null, min: 0, max: null},
			{value:  data.thresholds && data.thresholds.nom ? data.thresholds.nom.range.distance_raw : null, min: 0, max: null},
			{value:  data.thresholds && data.thresholds.max ? data.thresholds.max.range.distance_raw : null, min: 0, max: null},
		);
		for(const el of thresholds_range_distance_raw)
			c2.appendChild(el);

		const thresholds_range_distance_adj = DOMFactory.create_min_nom_max(
			'Rng.A',
			callbacks.update_hub_threshold.bind(this, DATASET_ID.DISTADJ),
			{value:  data.thresholds && data.thresholds.min ? data.thresholds.min.range.distance_adj : null, min: 0, max: null},
			{value:  data.thresholds && data.thresholds.nom ? data.thresholds.nom.range.distance_adj : null, min: 0, max: null},
			{value:  data.thresholds && data.thresholds.max ? data.thresholds.max.range.distance_adj : null, min: 0, max: null},
		);
		for(const el of thresholds_range_distance_adj)
			c2.appendChild(el);

		dev.appendChild(c1);
		dev.appendChild(c2);

		return dev;
	}

	static create_min_nom_max(label:string, callback:WidgetMinNomMaxEventCallback = null, min:WidgetMinNomMaxSettings = null, nom:WidgetMinNomMaxSettings = null, max:WidgetMinNomMaxSettings = null) {
		let items:HTMLElement[] = [];

		let title = document.createElement('div');
		title.className = 'widget-min-nom-max-content';
		title.appendChild(document.createTextNode(label));
		items.push(title);

		let input_min = document.createElement('input');
		input_min.type = 'number';
		input_min.className = 'widget-min-nom-max-content';
		input_min.onchange = callback.bind(this, input_min, WidgetMinNomMaxType.MINIMUM);
		if(min) {
			if(min.value != null)
				input_min.value = min.value.toString();
			if(min.min != null)
				input_min.min = min.min.toString();
			if(min.max != null)
				input_min.max = min.max.toString();
		}
		items.push(input_min);

		let input_nom = document.createElement('input');
		input_nom.type = 'number';
		input_nom.className = 'widget-min-nom-max-content';
		input_nom.onchange = callback.bind(this, input_nom, WidgetMinNomMaxType.NOMINAL);
		if(nom) {
			if(nom.value != null)
				input_nom.value = nom.value.toString();
			if(nom.min != null)
				input_nom.min = nom.min.toString();
			if(nom.max != null)
				input_nom.max = nom.max.toString();
		}
		items.push(input_nom);

		let input_max = document.createElement('input');
		input_max.type = 'number';
		input_max.className = 'widget-min-nom-max-content';
		input_max.onchange = callback.bind(this, input_max, WidgetMinNomMaxType.MAXIMUM);
		if(max) {
			if(max.value != null)
				input_max.value = max.value.toString();
			if(max.min != null)
				input_max.min = max.min.toString();
			if(max.max != null)
				input_max.max = max.max.toString();
		}
		items.push(input_max);

		return items;
	}

	// createDeviceAddButton(id) {
	// 	//Add in the "add devices" button
	// 	let dev_add = document.createElement("button");
	// 	dev_add.id = "device-add-" + id;
	// 	dev_add.className = "device-item device-btn-add shadow-box";
	// 	dev_add.onclick = window.addDeviceToPremises;

	// 	let s1 = document.createElement("div");
	// 	s1.className = 'device-item-content';
	// 	let i = document.createElement('i');
	// 	i.className = 'device-item-logo fas fa-plus-circle';
	// 	s1.appendChild(i);

	// 	let s2 = document.createElement("div");
	// 	s2.className = 'device-item-content';
	// 	let h2 = document.createElement("H3");
	// 	h2.append(document.createTextNode('Add Device'));
	// 	s2.appendChild(h2);

	// 	dev_add.appendChild(s1);
	// 	dev_add.appendChild(s2);

	// 	return dev_add;
	// }

	static get_button_hub_devices(id:string, callback:EventCallback, enabled:boolean=true) {
		let hub_identify = document.createElement("button");
		hub_identify.id = "hub-devices-" + id;
		hub_identify.className = "hub-item-content hub-btn-devices shadow-box notched-corners-x4";
		hub_identify.onclick = callback;
		if(!enabled) {
			hub_identify.disabled = true;
		}

		let s1 = document.createElement("div");
		// s1.className = 'hub-item-content';

		for(let i of cosec_get_icon(CosecIcons.ITEM_DEVICE)) {
			i.classList.add('hub-item-logo-devices');
			s1.appendChild(i);
		}

		let s2 = document.createElement("div");
		// s2.className = 'hub-item-content';
		let h2 = document.createElement("H3");
		h2.append(document.createTextNode('Devices'));
		s2.appendChild(h2);

		hub_identify.appendChild(s1);
		hub_identify.appendChild(s2);

		return hub_identify;
	}

	static get_button_hub_identify(id:string, callback:EventCallback, enabled:boolean=true) {
		let hub_identify = document.createElement("button");
		hub_identify.id = "hub-identify-" + id;
		hub_identify.className = "hub-item-content hub-btn-identify shadow-box notched-corners-x4";
		hub_identify.onclick = callback;
		if(!enabled) {
			hub_identify.disabled = true;
		}

		let s1 = document.createElement("div");
		// s1.className = 'hub-item-content';

		for(let i of cosec_get_icon(CosecIcons.ACTION_IDENTIFY)) {
			i.classList.add('hub-item-logo-identify');
			s1.appendChild(i);
		}

		let s2 = document.createElement("div");
		// s2.className = 'hub-item-content';
		let h2 = document.createElement("H3");
		h2.append(document.createTextNode('Identify'));
		s2.appendChild(h2);

		hub_identify.appendChild(s1);
		hub_identify.appendChild(s2);

		return hub_identify;
	}

	static get_button_hub_archive(id:string, callback:EventCallback, enabled:boolean=true) {
		let hub_archive = document.createElement("button");
		hub_archive.id = "hub-archive-" + id;
		hub_archive.className = "hub-item-content hub-btn-archive shadow-box notched-corners-x4";
		hub_archive.onclick = callback;
		if(!enabled) {
			hub_archive.disabled = true;
		}

		let s1 = document.createElement("div");
		// s1.className = 'hub-item-content';

		for(let i of cosec_get_icon(CosecIcons.ACTION_ARCHIVE_ITEM)) {
			i.classList.add('hub-item-logo-archive');
			s1.appendChild(i);
		}

		let s2 = document.createElement("div");
		// s2.className = 'hub-item-content';
		let h2 = document.createElement("H3");
		h2.append(document.createTextNode('Archive'));
		s2.appendChild(h2);

		hub_archive.appendChild(s1);
		hub_archive.appendChild(s2);

		return hub_archive;
	}

	static get_block_input_textbox(text:string, callback:EventCallback, label:string=null, id_prefix:string = null, enabled:boolean=true, input_type:string="text") {
		let p = document.createElement("p");

		if(label) {
			let s = document.createElement("label");
			s.appendChild(document.createTextNode(label));
			if(id_prefix)
				s.htmlFor = id_prefix + '-input';
			p.appendChild(s);
		}

		let field = document.createElement("input");
		field.setAttribute("type", input_type);
		field.className = 'widget-text-input';
		field.value = text;
		field.onchange = callback;
		if(id_prefix)
			field.id = id_prefix + '-input';
		if(!enabled) {
			field.disabled = true;
		}

		p.appendChild(field);

		return p;
	}

	static get_block_input_date(value:Date, label:string, callback:EventCallback, id_prefix:string = null, enabled:boolean=true) {
		let p = document.createElement("p");

		let s = document.createElement("label");
		s.appendChild(document.createTextNode(label));
		p.appendChild(s);

		let field = document.createElement("input");
		field.setAttribute("type", 'date');
		field.className = 'widget-date-input';
		field.valueAsDate = value;
		field.onchange = callback;
		if(id_prefix) {
			field.id = id_prefix + '-date';
			s.htmlFor = field.id;
		}
		if(!enabled) {
			field.disabled = true;
		}

		p.appendChild(field);

		return p;
	}

	static get_block_input_number(num:number, label:string, callback:EventCallback, id_prefix:string = null, enabled:boolean=true) {
		let p = document.createElement("p");

		let s = document.createElement("label");
		s.appendChild(document.createTextNode(`${label}: `));
		p.appendChild(s);

		let n_input = document.createElement("input");
		n_input.type = 'number';
		n_input.className = 'widget-number-input';
		n_input.size = 8;
		n_input.valueAsNumber = num;
		n_input.onchange = callback;
		if(id_prefix) {
			n_input.id = id_prefix + '-num';
			s.htmlFor = n_input.id;
		}
		if(!enabled) {
			n_input.disabled = true;
		}
		p.appendChild(n_input);

		return p;
	}

	static get_block_input_select(options:Map<string,string>, selected:string, label:string, callback:EventCallback, id_prefix:string = null, enabled:boolean=true) {
		let p = document.createElement("p");

		let s = document.createElement("label");
		s.appendChild(document.createTextNode(`${label}: `));
		p.appendChild(s);

		let s_input = document.createElement("select");
		// s_input.type = 'select';
		s_input.className = 'widget-select';
		// n_input.size = 8;
		// n_input.options = num;
		for(const [key, opt] of options.entries()) {
			let s_opt = document.createElement("option");
			s_opt.value = key;
			s_opt.appendChild(document.createTextNode(opt));
			if(key == selected)
				s_opt.selected = true;
			s_input.appendChild(s_opt);
		}

		s_input.onchange = callback;
		if(id_prefix) {
			s_input.id = id_prefix + '-select';
			s.htmlFor = s_input.id;
		}
		if(!enabled) {
			s_input.disabled = true;
		}
		p.appendChild(s_input);

		return p;
	}

	static get_block_input_lat_lon(latitude:number, longitude:number, callback:EventCallback, id_prefix:string = null, enabled:boolean=true) {
		let p = document.createElement("p");

		let s = document.createElement("span");
		s.appendChild(document.createTextNode("Location: "));
		p.appendChild(s);

		let lat = document.createElement("input");
		lat.type = 'number';
		lat.className = 'widget-lat-lon-input';
		lat.size = 8;
		lat.value = latitude.toString();
		lat.onchange = callback;
		if(id_prefix)
			lat.id = id_prefix + '-lat';
		if(!enabled) {
			lat.disabled = true;
		}
		p.appendChild(lat);

		let lon = document.createElement("input");
		lon.type = 'number';
		lon.className = 'widget-lat-lon-input';
		lon.size = 8;
		lon.value = longitude.toString();
		lon.onchange = callback;
		if(id_prefix)
			lon.id = id_prefix + '-lon';
		if(!enabled) {
			lon.disabled = true;
		}
		p.appendChild(lon);

		return p;
	}

	static get_block_hub_info (id:string, data:InterfaceHub, name_admin:string, names_users:string[], callbacks:HubInfoCallbacks, is_current:boolean = false, enabled:boolean=true) {
		let dom = document.createElement("div");
		dom.className = "hub-item shadow-box notched-corners-x4";
		dom.id = 'hub-' + id;
		if(is_current)
			dom.classList.add('hub-item-current');

		let row1 = document.createElement('div');
		row1.classList.add('hub-item-row');

		let s1 = document.createElement("div");
		s1.className = 'hub-item-content';
		for(let i of cosec_get_icon(CosecIcons.ITEM_HUB)) {
			i.classList.add('hub-item-logo');
			s1.appendChild(i);
		}

		let s2 = document.createElement("div");
		s2.className = 'hub-item-content hub-item-content-grow';
		let h = DOMFactory.get_block_input_textbox(data.name, callbacks.update_hub_rename, null, dom.id + '-nickname', enabled);
		// let h = document.createElement("H3");
		// h.append(document.createTextNode(data['name']));
		let p0 = document.createElement("P");
		let dec_label = (data.archived) ?
			'Hub archived'
			: (data.activation_secret) ?
				'Key: ' + data.activation_secret
			: (data.date_contacted) ?
				'Last seen: ' + CommonLib.time_since(data.date_contacted)
			: 'Device has not connected';
		p0.append(document.createTextNode(dec_label));
		let p1 = document.createElement("P");
		p1.appendChild(document.createTextNode('ID: ' + id));
		let p2 = DOMFactory.get_block_input_textbox(data.owner, callbacks.update_hub_owner, 'Owner: ', dom.id + '-owner', enabled);
		let p3 = DOMFactory.get_block_input_lat_lon(data.location.latitude, data.location.longitude, callbacks.update_hub_location, dom.id, enabled);

		let overlay_options:Map<string,string> = new Map();
		for(const f of Object.keys(InterfaceHubGeoFeatures)){
			const key:InterfaceHubGeoFeatures = InterfaceHubGeoFeatures[f as keyof typeof InterfaceHubGeoFeatures];
			overlay_options.set(key, CosecGeoFeatureNames.get(key));
		}
		let p31 = DOMFactory.get_block_input_select(overlay_options, data.geo_features.length > 0 ? data.geo_features[0] : "", 'Overlay', callbacks.update_hub_overlays, dom.id + '-overlays', enabled);
		let p4 = document.createElement("P");
		p4.appendChild(document.createTextNode('Administrator: ' + name_admin));
		let p5 = document.createElement("P");
		p5.appendChild(document.createTextNode('Users: ' + names_users.join(', ')));

		s2.appendChild(h);
		s2.appendChild(p0);
		s2.appendChild(p1);
		s2.appendChild(p2);
		s2.appendChild(p3);
		s2.appendChild(p31);
		s2.appendChild(p4);
		s2.appendChild(p5);

		// let s3 = document.createElement("div");
		// s3.className = 'hub-item-spacer';

		// let s4 = document.createElement("div");
		// s4.className = 'hub-item-content';

		let dev_list = document.createElement("div");
		dev_list.id = `device-list-hub-${id}`;
		dev_list.className = "device-list hub-item-row";

		row1.appendChild(s1);
		row1.appendChild(s2);
		// dom.appendChild(s3);
		// dom.appendChild(s4);

		// dom.appendChild(this.createDeviceAddButton(id));
		row1.appendChild(DOMFactory.get_button_hub_devices(id, callbacks.show_device_list, !data.archived && enabled));
		row1.appendChild(DOMFactory.get_button_hub_identify(id, callbacks.identify_hub, !data.archived && enabled));
		row1.appendChild(DOMFactory.get_button_hub_archive(id, callbacks.archive_hub, !data.archived && enabled));

		dom.appendChild(row1);
		dom.appendChild(dev_list);

		return dom;
	}

	static get_button_hub_add(callback:EventCallback) {
		let dom = document.createElement("button");
		dom.id = "hub-btn-add";
		dom.className = "hub-item shadow-box notched-corners-x4";
		dom.onclick = callback;

		let s1 = document.createElement("div");
		s1.className = 'hub-item-content';

		for(let i of cosec_get_icon(CosecIcons.ACTION_ADD_ITEM)) {
			i.classList.add('hub-item-logo');
			s1.appendChild(i);
		}

		let s2 = document.createElement("div");
		s2.className = 'hub-item-content';
		let h = document.createElement("H2");
		h.append(document.createTextNode('Register new hub'));

		s2.appendChild(h);

		dom.appendChild(s1);
		dom.appendChild(s2);

		return dom;
	}

	static get_button_device_add(type_name:string, callback:EventCallback) {
		let dom = document.createElement("button");
		// dom.id = "hub-dev-btn-add";
		dom.className = "hub-item device-btn-add shadow-box notched-corners-x4";
		dom.onclick = callback;

		let s1 = document.createElement("div");
		s1.className = 'hub-item-content';

		for(let i of cosec_get_icon(CosecIcons.ACTION_ADD_ITEM)) {
			i.classList.add('hub-item-logo');
			s1.appendChild(i);
		}

		let s2 = document.createElement("div");
		s2.className = 'hub-item-content';
		let h = document.createElement("H2");
		h.append(document.createTextNode(`Add new ${type_name}`));

		s2.appendChild(h);

		dom.appendChild(s1);
		dom.appendChild(s2);

		return dom;
	}

	static get_reading_warning(label:string, icon:CosecIcons, severity:SEVERITY, condition:string=null) {
		let dom = document.createElement('div');
		dom.className = `warning-text warning-text-${severity}`;

		for(let el of cosec_get_icon(icon)) {
			let i = document.createElement('div');
			i.classList.add('warning-text-icon');
			i.appendChild(el);
			dom.appendChild(i);
		}

		let s1 = document.createElement('div');
		s1.className = `warning-item-title`;
		s1.appendChild(document.createTextNode(label + (condition ? `: ` : "")));
		dom.appendChild(s1);

		if(condition != null) {
			let s2 = document.createElement('div');
			s2.className = `warning-item-text`;
			s2.appendChild(document.createTextNode(condition));
			dom.appendChild(s2);
		}

		return dom;
	}

	static get_date_time_span_message(stamp:Date, days:number, out_of_days:string = "expired") {
		let msg = `(${out_of_days})`;

		if(days > 0) {
			if (days <= 30) {
				msg = `(+${days}d)`;
				// msg = `(+${days} day${days > 1 ? "s" : ""})`;
			} else if (days <= 365) {
				msg = `(+${Math.floor(days/30)}m)`;
				// msg = `(+${Math.floor(days/30)} month${days > 60 ? "s" : ""})`;
			} else {
				msg = `(+${Math.floor(days/365)}y)`;
				msg = `(+${Math.floor(days/365)}y)`;
			}
		}

		return msg + " " + stamp.toLocaleDateString();
	}

	static get_device_marker_popup_static_warnings(install:Date, review:LifetimeWarningData, renewal:LifetimeWarningData, warranty:LifetimeWarningData) {
		let warnings_elements:HTMLElement[] = [];

		warnings_elements.push(DOMFactory.get_reading_warning(
			"Install",
			CosecIcons.ACTION_ADD_ITEM,
			SEVERITY.NONE,
			install.toLocaleDateString()
		));

		warnings_elements.push(DOMFactory.get_reading_warning(
			"Review",
			CosecIcons.TIMEOUT,
			review.severity,
			DOMFactory.get_date_time_span_message(review.stamp, review.days, "overdue")
		));

		warnings_elements.push(DOMFactory.get_reading_warning(
			"Renewal",
			CosecIcons.TIMEOUT,
			renewal.severity,
			DOMFactory.get_date_time_span_message(renewal.stamp, renewal.days, "overdue")
		));

		warnings_elements.push(DOMFactory.get_reading_warning(
			"Warranty",
			CosecIcons.TIMEOUT,
			warranty.severity,
			DOMFactory.get_date_time_span_message(warranty.stamp, warranty.days)
		));

		return warnings_elements;
	}

	static get_device_marker_popup_sensor_warnings(warnings:InterfaceReadingWarningsSummary) {
		let warnings_elements:HTMLElement[] = [];

		//Under min warnings
		if(warnings.low.ambience.temperature != SEVERITY.NONE)
			warnings_elements.push(DOMFactory.get_reading_warning("Low temperature", CosecIcons.DATASET_TEMPERATURE, warnings.low.ambience.temperature));

		if(warnings.low.ambience.pressure != SEVERITY.NONE)
			warnings_elements.push(DOMFactory.get_reading_warning("Low pressure", CosecIcons.DATASET_PRESSURE, warnings.low.ambience.pressure));

		if(warnings.low.ambience.humidity != SEVERITY.NONE)
			warnings_elements.push(DOMFactory.get_reading_warning("Low humidity", CosecIcons.DATASET_HUMIDITY, warnings.low.ambience.humidity));

		if(warnings.low.power.voltage_batt != SEVERITY.NONE)
			warnings_elements.push(DOMFactory.get_reading_warning("Low battery", CosecIcons.DATASET_VBATT, warnings.low.power.voltage_batt));

		if(warnings.low.power.voltage_solar != SEVERITY.NONE)
			warnings_elements.push(DOMFactory.get_reading_warning("Low solar", CosecIcons.DATASET_VSOLAR, warnings.low.power.voltage_solar));

		if(warnings.low.range.angle != SEVERITY.NONE)
			warnings_elements.push(DOMFactory.get_reading_warning("Low angle", CosecIcons.DATASET_ANGLE, warnings.low.range.angle));

		if(warnings.low.range.distance_raw != SEVERITY.NONE)
			warnings_elements.push(DOMFactory.get_reading_warning("Low distance (direct)", CosecIcons.DATASET_DISTRAW, warnings.low.range.distance_raw));

		if(warnings.low.range.distance_adj != SEVERITY.NONE)
			warnings_elements.push(DOMFactory.get_reading_warning("Low distance (vertical)", CosecIcons.DATASET_DISTADJ, warnings.low.range.distance_adj));

		//Over max warnings
		if(warnings.high.ambience.temperature != SEVERITY.NONE)
			warnings_elements.push(DOMFactory.get_reading_warning("High temperature", CosecIcons.DATASET_TEMPERATURE, warnings.high.ambience.temperature));

		if(warnings.high.ambience.pressure != SEVERITY.NONE)
			warnings_elements.push(DOMFactory.get_reading_warning("High pressure", CosecIcons.DATASET_PRESSURE, warnings.high.ambience.pressure));

		if(warnings.high.ambience.humidity != SEVERITY.NONE)
			warnings_elements.push(DOMFactory.get_reading_warning("High humidity", CosecIcons.DATASET_HUMIDITY, warnings.high.ambience.humidity));

		if(warnings.high.power.voltage_batt != SEVERITY.NONE)
			warnings_elements.push(DOMFactory.get_reading_warning("High battery", CosecIcons.DATASET_VBATT, warnings.high.power.voltage_batt));

		if(warnings.high.power.voltage_solar != SEVERITY.NONE)
			warnings_elements.push(DOMFactory.get_reading_warning("High solar", CosecIcons.DATASET_VSOLAR, warnings.high.power.voltage_solar));

		if(warnings.high.range.angle != SEVERITY.NONE)
			warnings_elements.push(DOMFactory.get_reading_warning("High angle", CosecIcons.DATASET_ANGLE, warnings.high.range.angle));

		if(warnings.high.range.distance_raw != SEVERITY.NONE)
			warnings_elements.push(DOMFactory.get_reading_warning("High distance (direct)", CosecIcons.DATASET_DISTRAW, warnings.high.range.distance_raw));

		if(warnings.high.range.distance_adj != SEVERITY.NONE)
			warnings_elements.push(DOMFactory.get_reading_warning("High distance (vertical)", CosecIcons.DATASET_DISTADJ, warnings.high.range.distance_adj));

		return warnings_elements;
	}

	static get_device_marker_popup_reading(last_seen:Date, reading:InterfaceReading) {
		let dom = document.createElement("div");

		const seen_msg = (last_seen) ? 'Last seen: ' + CommonLib.time_since(last_seen) : 'Device not active'
		let d2 = document.createElement("div");
		// d2.className = 'hub-item-content';
		d2.append(document.createTextNode(seen_msg));
		dom.append(d2);

		let d3 = document.createElement("div");
		dom.appendChild(d3);

		//XXX: This should have items arranged in a Nx4 pattern, starting with a label
		let caption_items:string[] = [];

		if(reading) {
			if(reading.ambience) {
				caption_items.push('Ambience');
				caption_items.push(`${CommonLib.locale_get_temperature(reading.ambience.temperature).toFixed(2)}${CommonLib.locale_get_temperature_unit()}`);
				caption_items.push(`${(100*reading.ambience.humidity).toFixed(0)}%`);
				caption_items.push(`${CommonLib.locale_get_pressure(reading.ambience.pressure).toFixed(2)}${CommonLib.locale_get_pressure_unit()}`);
			}

			if(reading.power) {
				caption_items.push('Charge');
				caption_items.push(`${reading.power.voltage_batt.toFixed(2)}V`);
				caption_items.push(`${reading.power.voltage_solar.toFixed(2)}V`);
				caption_items.push('');
			}

			if(reading.range) {
				caption_items.push('Range');
				caption_items.push(`${reading.range.angle.toFixed(2)}°`);
				caption_items.push(`${CommonLib.locale_get_distance(reading.range.distance_raw).toFixed(2)}${CommonLib.locale_get_distance_unit()}`);
				caption_items.push(`${CommonLib.locale_get_distance(reading.range.distance_adj).toFixed(2)}${CommonLib.locale_get_distance_unit()}`);
			}
		}
		if(caption_items.length) {
			d3.className = 'map-popup-data-reading';

			for(const item of caption_items) {
				let dom_sub = document.createElement("div");
				dom_sub.appendChild(document.createTextNode(item));
				d3.appendChild(dom_sub);
			}
		} else {
			d3.className = 'map-popup-data-no-reading';
			let dom_sub = document.createElement("div");
			dom_sub.appendChild(document.createTextNode('No readings have been received!'));
			d3.appendChild(dom_sub);
		}

		dom.appendChild(d3);

		return dom;
	}

	static get_device_marker_popup(device_id:string, device_name:string, device_type:InterfaceDeviceType, view_history_callback:EventCallback=null) {
		let dom = document.createElement("div");
		dom.className = 'map-popup-container'

		let d1 = document.createElement("div");
		d1.className = 'map-popup-name';
		d1.append(document.createTextNode(device_name));

		let d3 = document.createElement('div');
		d3.id = `map-popup-${device_id}-information`;
		d3.className = 'map-popup-info-container';

		let d31 = document.createElement("div");
		d31.className = 'map-popup-info';
		d31.append(document.createTextNode(`Type: ${InterfaceDeviceTypeNames.get(device_type)}`));
		d3.appendChild(d31);

		let d2 = document.createElement('div');
		d2.id = `map-popup-${device_id}-reading`;
		// if(reading) {
		// 	if(thresholds) {
		// 		const warnings = DBTools.get_reading_warnings(reading, thresholds);
		// 		for(const el of DOMFactory.get_device_marker_popup_warnings(warnings))
		// 			d2.appendChild(el);
		// 	}
		// 	d2.appendChild(DOMFactory.get_device_marker_popup_reading(reading));
		// } else {
		let d2_sub = document.createElement("div");
		d2_sub.className = "map-popup-data-loading";
		d2_sub.appendChild(DOMFactory.get_loading_dots());
		d2.appendChild(d2_sub);
		// }

		dom.appendChild(d1);
		dom.appendChild(d3);
		dom.appendChild(d2);
		if(view_history_callback) {
			let but_hist = document.createElement("button");
			but_hist.onclick = view_history_callback;
			but_hist.append(document.createTextNode("View device history"));
			dom.appendChild(but_hist);
		}

		return dom;
	}
}
