import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';
import { SEVERITY } from "./db_tools";
import { InterfaceDeviceType } from "./interface_database";
import { CosecIcons, cosec_get_icon } from "./interface_fontawesome";
// @ts-ignore
//import * as ContextMenu from "leaflet-contextmenu";
// import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';\
// @ts-ignore
// import * as FileLoader from 'leaflet-filelayer'

// delete L.Icon.Default.prototype._getIconUrl;
//XXX: Fix the image paths loaded by leaflet

export const DEFAULT_MAP_ICON_SIZE = 36;

const default_icon_options = {
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
}
L.Icon.Default.mergeOptions(default_icon_options);

export function get_cosec_map_marker(class_name:string = "") {
    return new L.Icon({
        ...L.Icon.Default.prototype.options,
        className: class_name
    });
}

export function get_cosec_map_marker_severity(severity:SEVERITY) {
    return severity > SEVERITY.NONE ? get_cosec_map_marker(`warning-marker-${severity}`) : get_cosec_map_marker();
}


export function get_cosec_map_marker_for_hub(size:number, class_name:string = null) {
    let bounds:L.PointExpression = [size, size];
    let anchor:L.PointExpression = [Math.floor(size/2), Math.floor(size/2)];

    let options:L.IconOptions = {
        iconUrl: "img/logo-maskable-circle.svg",
        iconSize:     bounds,
        iconAnchor:   anchor,
        className: class_name
    };

    return L.icon(options);
}

/** Returns a new icon for this object based on the device type, size, and any additional display classes
 * @param  {InterfaceDeviceType} device_type Type of device to load resource for
 * @param  {number} size Size of the marker to render resource as a square or long-side images
 * @param  {string} class_name Additional class information to load into the icon
 */
export function get_cosec_map_marker_for_device(device_type:InterfaceDeviceType, size:number, class_name:string) {
    let resource_name:string = default_icon_options.iconUrl;
    let bounds:L.PointExpression = [size, size];
    let anchor:L.PointExpression = [Math.floor(size/2), Math.floor(size/2)];

    switch(device_type) {
        case InterfaceDeviceType.ROTAFLAG: {
            resource_name = "img/icons/flag.svg";
            //XXX: Square size and center bounds
            break;
        }
        case InterfaceDeviceType.ROTAMARKER_BW: {
            resource_name = "img/icons/rota_bw.svg";
            //XXX: Square size and center bounds
            break;
        }
        case InterfaceDeviceType.ROTAMARKER_RW: {
            resource_name = "img/icons/rota_rw.svg";
            //XXX: Square size and center bounds
            break;
        }
        case InterfaceDeviceType.ROTASENSOR: {
            resource_name = default_icon_options.iconUrl; //TODO: Add custom marker for this
            //Square marker, but anchor at bottom
            anchor = [Math.floor(size/2),size];
            break;
        }
        //default: GENERIC
            //default_icon_options.iconUrl
            //centre anchor
    }

    let options:L.IconOptions = {
        iconUrl: resource_name,
        iconSize:     bounds,
        iconAnchor:   anchor,
        className: class_name
    }
    return L.icon(options);
}

//require('leaflet/dist/images/layers.png')
//require('leaflet/dist/images/layers-2x.png')

//const cm = ContextMenu;
// const fl = FileLoader;

export { L as Leaflet };

export class LeafletContextMenuItem {
    text:string = "";
    icon:CosecIcons;
    callback: (contextTarget:L.Marker) => null;

    constructor(text:string = "", icon:CosecIcons = null, callback:(contextTarget:L.Marker) => null = null) {
        this.text = text;
        this.icon = icon;

        if(callback)
            this.callback = callback;
    }
}

export function create_popup_context_dom(popup_title:string = "Marker", context_items:LeafletContextMenuItem[] = [], marker_context:L.Marker = null) {
    let dom = document.createElement("div");
    dom.className = 'cosec-marker-popup';

    let t = document.createElement("div");
    t.className = 'cosec-marker-popup-title';
    t.appendChild(document.createTextNode(popup_title));
    dom.appendChild(t);

    for(const i of context_items) {
        if(i) {
            let d = document.createElement("div");
            d.className = 'cosec-marker-popup-item';

            if(i.icon) {
                for(let ic of cosec_get_icon(i.icon)) {
                    ic.classList.add('cosec-marker-popup-icon');
                    d.appendChild(ic);
                }
                // let o = document.createElement('i');
                // o.className = `fas ${i.icon} cosec-marker-popup-icon`;
                // o.appendChild(document.createTextNode(i.text));
                // d.appendChild(o);
            }

            let s = document.createElement('span');
            s.className = 'cosec-marker-popup-item-text';
            s.appendChild(document.createTextNode(i.text));
            d.appendChild(s);

            if(i.callback) {
                d.onclick = function() {
                    return i.callback(marker_context);
                };
            }

            dom.appendChild(d);
        } else {
            let d = document.createElement("hr");
            d.className = 'cosec-marker-popup-break';
            dom.appendChild(d);
        }
    }

    return dom;
}
