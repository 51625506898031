import { DatabaseFirebase } from "./db_firebase";
import { DatabaseMock } from "./db_mock";

export function create_interface(interface_name:string) {
    let i = null;
    switch(interface_name) {
        case 'mock': {
            i = new DatabaseMock();
            break;
        }
        case 'firebase': {
            i = new DatabaseFirebase();
            break;
        }
    }

    return i;
}
