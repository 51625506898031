// import { DBTools } from "./db_tools";

import { CommonLib, CONFIG_NAMES, CosecConfig } from "./common";
import { CosecCorePages } from "./core";
import { InterfaceDatabase } from "./interface_database";
import { InterfacePage } from "./interface_page";

export class CosecSettings implements InterfacePage {
	#page_id:CosecCorePages;
	#first_load:boolean;
	#db:InterfaceDatabase

	/** the first load parameter here has something to do with preventing things reloading on the
	 * second load and further i think, to be honest i have no idea.
	 *
	 * @param  {} db_interface
	 */
	constructor(page_id:CosecCorePages, db_interface:InterfaceDatabase) {
		this.#page_id = page_id;
		this.#first_load = true;
		this.#db = db_interface;
	}

	update_toggle_setting(id:CosecConfig) {
		let el = <HTMLInputElement>document.getElementById(this.#get_element_id_from_config_id(id));
		if(el && CommonLib.config.has(<CosecConfig>id)) {
			const val = el.checked;
			CommonLib.config.set(<CosecConfig>id, val);
			console.log(`Update ${CONFIG_NAMES.get(id)}: ${val}`);
		} else {
			console.log(`Invalid id: ${id}`);
		}

		CommonLib.check_color_scheme_override();
	}

	#get_element_id_from_config_id(id:CosecConfig) {
		return `setting-toggle-${id}`;
	}

	#get_config_id_element_id(id:string) {
		let cid:CosecConfig = null;
		let parts = id.split('-');
		if(parts.length) {
			let candidate = Number(parts.pop());
			if(!Number.isNaN(candidate))
				cid = candidate;
		}

		return cid;
	}

	add_setting_toggle(parent:HTMLElement, id:CosecConfig, label:string) {
		let html_id = this.#get_element_id_from_config_id(id);

		let onoff_slider = document.createElement("div");
		onoff_slider.className = "grid-item grid-item-center";

		let onoff_slider_button = document.createElement("label");
		onoff_slider_button.className = "onoff-switch";

		let onoff_slider_button_input = document.createElement("input");
		onoff_slider_button_input.setAttribute("type","checkbox");
		onoff_slider_button_input.id = html_id;
		onoff_slider_button_input.checked = CommonLib.config.get(id);
		onoff_slider_button_input.onchange = this.update_toggle_setting.bind(this, id);

		let onoff_slider_button_casing = document.createElement("div");
		onoff_slider_button.appendChild(onoff_slider_button_input);
		onoff_slider_button.appendChild(onoff_slider_button_casing);
		onoff_slider.appendChild(onoff_slider_button);
		parent.appendChild(onoff_slider);

		let label_box = document.createElement("label");
		label_box.className = "grid-item";
		label_box.htmlFor = html_id;

		let label_text = document.createTextNode(label);
		label_box.appendChild(label_text);
		parent.appendChild(label_box);
	}

	async reset() {
		let settings_list = document.getElementById('setting-list');
		let settings_list_loading = document.getElementById('setting-list-loading');
		settings_list.style.display = 'none';
		settings_list_loading.style.display = 'flex';
	}

	async load() {
		let settings_list = document.getElementById('setting-list');
		// const settings = this.db.get_setting_ids();
		settings_list.innerHTML = ''
		/**
		 * The below code is a template for adding the appearance side of the settings checkboxes. The ID assigned is used
		 * in other areas of the code to allow the checkbox to have functionality beyond just appearing on the settings page
		 */
		let settings_title_box = document.createElement("div");
		settings_title_box.className = "page-title-div";
		let settings_title = document.createTextNode('Configuration');
		settings_title_box.appendChild(settings_title);
		settings_list.appendChild(settings_title_box);

		let subtitle_box_1 = document.createElement("div");
		subtitle_box_1.className = "page-subtitle-div";
		subtitle_box_1.appendChild(document.createTextNode('Overlay Settings'));
		settings_list.appendChild(subtitle_box_1);


		// this.add_setting_toggle(settings_list, CosecConfig.MAP_DISPLAY_NODES_A, "Transpower ⁠— Transmission Lines");
		// this.add_setting_toggle(settings_list, CosecConfig.MAP_DISPLAY_NODES_B, "Transpower ⁠— Cook Strait Protection Zone");
		// this.add_setting_toggle(settings_list, CosecConfig.MAP_DISPLAY_NODES_C, "Energy Queensland ⁠— ARP/TR");
		// this.add_setting_toggle(settings_list, CosecConfig.MAP_DISPLAY_NODES_D, "Energy Queensland ⁠— High Voltage");
		// this.add_setting_toggle(settings_list, CosecConfig.MAP_DISPLAY_NODES_E, "Energy Queensland ⁠— Low Voltage");
		// this.add_setting_toggle(settings_list, CommonLib.SETTING_LOCALE_USE_IMPERIAL, "Use Imperial Units") # include later, consider the creation of the settings page and storage

		let subtitle_box_2 = document.createElement("div");
		subtitle_box_2.className = "page-subtitle-div";
		subtitle_box_2.appendChild(document.createTextNode('Display Settings'));
		settings_list.appendChild(subtitle_box_2);

		this.add_setting_toggle(settings_list, CosecConfig.FORCE_LIGHT_MODE, "Force light mode")
		this.add_setting_toggle(settings_list, CosecConfig.FORCE_DARK_MODE, "Force dark mode")

		// let settings_list = document.getElementById('setting-list');
		let settings_list_loading = document.getElementById('setting-list-loading');
		settings_list_loading.style.display = 'none';
		settings_list.style.display = 'grid';
		// if(settings.size > 0) {
		// 	events.forEach( function(value, key) {
		// 		const dom = this.parseSettingToDOM(key, value);
		// 		settings_list.appendChild(dom);
		// 	});
		// } else {
		// 	let el = document.createElement("div");
		// 	let p = document.createElement("P");
		// 	p.appendChild(document.createTextNode('There are no settings to configure!'));
		// 	el.className = 'setting-none setting'
		// 	el.appendChild(p);
		// 	settings_list.appendChild(el);
		// }
	}

	// eslint-disable-next-line no-unused-vars
	async refresh(device_in_focus:string=null) {
		const focus_str = device_in_focus != null ? ` (focus: ${device_in_focus})` : "";
		console.log(`Refreshing ${this.#page_id}${focus_str}!`);
		this.reset();
		await this.load();
	}

	async hub_changed(old_current_id:string, new_current_id:string) {
		this.deactivate();
		this.refresh();
	}

	async deactivate() {
		console.log("Deactivating Settings!");
	}
}
