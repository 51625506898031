import { CosecGeoPoint } from "../common";
import { InterfaceHubGeoFeatures } from "../db_tools";
import { InterfaceDeviceType } from "../interface_database";
import { HubDeviceLayout } from "../interface_devices";

export const nz_rotasense_example:HubDeviceLayout = {
    hub_name: null,
    hub_location: new CosecGeoPoint(-41.71465069445395, 173.092323922665),
    hub_geo_features: [InterfaceHubGeoFeatures.TRANSPOWER_TRANSMISSION_LINES],
    devices: [
        {type: InterfaceDeviceType.ROTASENSOR, location: new CosecGeoPoint(-41.71373767495389, 173.0817896675091)},
        {type: InterfaceDeviceType.ROTASENSOR, location: new CosecGeoPoint(-41.71947951829985, 173.06296927445544)},
        {type: InterfaceDeviceType.ROTASENSOR, location: new CosecGeoPoint(-41.72874452580026, 173.03303922094304)},
        {type: InterfaceDeviceType.ROTASENSOR, location: new CosecGeoPoint(-41.74477068621225, 173.003400424484)},
        {type: InterfaceDeviceType.ROTASENSOR, location: new CosecGeoPoint(-41.763629223594, 172.96903125403463)},
        {type: InterfaceDeviceType.ROTASENSOR, location: new CosecGeoPoint(-41.77189083914382, 172.93131006923002)},
    ],
}
