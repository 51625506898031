import {CommonLib, download_file, get_filename} from "./common"
import { CosecCorePages } from "./core";

import { Chart, ChartConfiguration, ChartOptions, ChartType } from "./interface_chart";
import { InterfaceDatabase, InterfaceReading } from "./interface_database";
import { InterfacePage } from "./interface_page";

//XXX: Should match HTML option elements for 'history-select-time'
const TIME_DATA_LENGTH: {[id: string]: number;} = {
    'LIVE':    0,
    'DAY':     1,
    'DAY7':    7,
    'DAY30':  30,
    'DAY90':  90,
    'YEAR':  365,
};

enum DATASET_TEMP {
    TEMPERATURE = 0,
};

enum DATASET_HUMID {
    HUMIDITY = 0,
};

enum DATASET_PRESS {
    PRESSURE = 0,
};

enum DATASET_POWER {
    VBATT = 0,
    VSOLAR = 1,
};

enum DATASET_ANGLE {
    ANGLE = 0
};

enum DATASET_RANGE {
    DISTRAW = 0,
    DISTADJ = 1
};

type DATASETS = DATASET_TEMP | DATASET_HUMID | DATASET_PRESS | DATASET_POWER | DATASET_ANGLE | DATASET_RANGE;

function get_dataset_labels_temp () {
    return [
        `Temperature (${CommonLib.locale_get_temperature_unit()})`,
    ];
}


function get_dataset_labels_humid () {
    return [
        "Humidity (%)",
    ];
}

function get_dataset_labels_press () {
    return [
        `Pressure (${CommonLib.locale_get_pressure_unit()})`,
    ];
}

function get_dataset_labels_power () {
    return [
        "Battery Voltage (V)",
        "Solar Voltage (V)",
    ];
}

function get_dataset_labels_angle () {
    return [
        "Angle (°)",
    ];
}

function get_dataset_labels_range () {
    return [
        `Raw Distance (${CommonLib.locale_get_distance_unit()})`,
        `Adj. Distance (${CommonLib.locale_get_distance_unit()})`,
    ];
}

const dataset_colors_temp = [
    'rgba(255,  50,  50, 1.0)',
];

const dataset_colors_humid = [
    'rgba(  0, 200, 200, 1.0)',
];

const dataset_colors_press = [
    'rgba(  0, 255, 100, 1.0)',
];

const dataset_colors_power = [
    'rgba(255, 100,   0, 1.0)',
    'rgba(255, 200,   0, 1.0)',
];

const dataset_colors_angle = [
    'rgba(  0, 200, 255, 1.0)',
];

const dataset_colors_range = [
    'rgba( 50, 100, 255, 1.0)',
    'rgba(  0,  50, 255, 1.0)',
];

CommonLib.assert_dataset_layout(DATASET_TEMP, get_dataset_labels_temp(), 'temp labels');
CommonLib.assert_dataset_layout(DATASET_TEMP, dataset_colors_temp, 'temp colors');
CommonLib.assert_dataset_layout(DATASET_HUMID, get_dataset_labels_humid(), 'humid labels');
CommonLib.assert_dataset_layout(DATASET_HUMID, dataset_colors_humid, 'humid colors');
CommonLib.assert_dataset_layout(DATASET_PRESS, get_dataset_labels_press(), 'press labels');
CommonLib.assert_dataset_layout(DATASET_PRESS, dataset_colors_press, 'press colors');
CommonLib.assert_dataset_layout(DATASET_POWER, get_dataset_labels_power(), 'power labels');
CommonLib.assert_dataset_layout(DATASET_POWER, dataset_colors_power, 'power colors');
CommonLib.assert_dataset_layout(DATASET_ANGLE, get_dataset_labels_angle(), 'angle labels');
CommonLib.assert_dataset_layout(DATASET_ANGLE, dataset_colors_angle, 'angle colors');
CommonLib.assert_dataset_layout(DATASET_RANGE, get_dataset_labels_range(), 'range labels');
CommonLib.assert_dataset_layout(DATASET_RANGE, dataset_colors_range, 'range colors');

export class CosecHistorySingle implements InterfacePage {
    //Get the newest set of readings, or if a refresh, the newest since our stamp
    static NUM_DATAPOINTS = 60;

    #page_id:CosecCorePages;
    #db:InterfaceDatabase;

    #hub_id:string;
    // #dev_ids:string[];
    // #dev_id_selected:string;

    #plot_temp:Chart;
    #plot_humid:Chart;
    #plot_press:Chart;
    #plot_power:Chart;
    #plot_angle:Chart;
    #plot_range:Chart;

    #refresh_watcher_devices:CallableFunction;
    #refresh_watcher_readings:CallableFunction;
    #latest_reading:Date;  //XXX: Cleared when the watcher is cleared

    constructor(page_id:CosecCorePages, db_interface:InterfaceDatabase) {
        this.#page_id = page_id;
        this.#db = db_interface;

        let but_map = <HTMLButtonElement>document.getElementById('history-header-goto-map');
        let but_download = <HTMLButtonElement>document.getElementById('history-header-download');
        but_map.onclick = this.#show_selected_device_on_map.bind(this);
        but_download.onclick = this.download_current_dataset.bind(this);

        let dev_select = <HTMLSelectElement>document.getElementById('history-select-device');
        let time_select = <HTMLSelectElement>document.getElementById('history-select-time');
        dev_select.onchange = this.update_graphs.bind(this);
        time_select.onchange = this.update_graphs.bind(this);

        this.#hub_id = null;
        // this.#dev_ids = null;
        // this.#dev_id_selected = null;

        //XXX: This canvas generation should match the HTML elements!
        const canvas_temp = <HTMLCanvasElement>document.getElementById("history-canvas-temperature");
        const canvas_humid = <HTMLCanvasElement>document.getElementById("history-canvas-humidity");
        const canvas_press = <HTMLCanvasElement>document.getElementById("history-canvas-pressure");
        const canvas_power = <HTMLCanvasElement>document.getElementById("history-canvas-power");
        const canvas_angle = <HTMLCanvasElement>document.getElementById("history-canvas-angle");
        const canvas_range = <HTMLCanvasElement>document.getElementById("history-canvas-range");

        this.#plot_temp = this.create_line_graph(canvas_temp, `Temp. (${CommonLib.locale_get_temperature_unit()})`, DATASET_TEMP, get_dataset_labels_temp(), dataset_colors_temp);
        this.#plot_humid = this.create_line_graph(canvas_humid, `Humid. (%)`, DATASET_HUMID, get_dataset_labels_humid(), dataset_colors_humid);
        this.#plot_press = this.create_line_graph(canvas_press, `Press. (${CommonLib.locale_get_pressure_unit()})`, DATASET_PRESS, get_dataset_labels_press(), dataset_colors_press);
        this.#plot_power = this.create_line_graph(canvas_power, `Power (V)`, DATASET_POWER, get_dataset_labels_power(), dataset_colors_power);
        this.#plot_angle = this.create_line_graph(canvas_angle, `Angle (°)`, DATASET_ANGLE, get_dataset_labels_angle(), dataset_colors_angle);
        this.#plot_range = this.create_line_graph(canvas_range, `Range (${CommonLib.locale_get_distance_unit()})`, DATASET_RANGE, get_dataset_labels_range(), dataset_colors_range);

        this.#refresh_watcher_devices = null;
        this.#refresh_watcher_readings = null;
        this.#latest_reading = CommonLib.t_zero;
    }

    has_recent_reading() {
        return this.#latest_reading != CommonLib.t_zero;
    }

    #set_reading_stamp(stamp:number) {
        this.#latest_reading = new Date(stamp);
    }

    get_reading_stamp() {
        return this.#latest_reading;
    }

    #set_reading_watcher(watcher:CallableFunction) {
        this.#clear_reading_watcher();
        this.#refresh_watcher_readings = watcher;
    }

    #clear_reading_watcher() {
        if(this.#refresh_watcher_readings) {
            this.#refresh_watcher_readings();
            this.#refresh_watcher_readings = null;
        }

        this.#latest_reading = CommonLib.t_zero;
    }

    clear_graphs() {
        this.#plot_temp.data.labels = [];
        this.#plot_temp.data.datasets[DATASET_TEMP.TEMPERATURE].data = [];

        this.#plot_humid.data.labels = [];
        this.#plot_humid.data.datasets[DATASET_HUMID.HUMIDITY].data = [];

        this.#plot_press.data.labels = [];
        this.#plot_press.data.datasets[DATASET_PRESS.PRESSURE].data = [];

        this.#plot_power.data.labels = [];
        this.#plot_power.data.datasets[DATASET_POWER.VBATT].data = [];
        this.#plot_power.data.datasets[DATASET_POWER.VSOLAR].data = [];

        this.#plot_angle.data.labels = [];
        this.#plot_angle.data.datasets[DATASET_ANGLE.ANGLE].data = [];

        this.#plot_range.data.labels = [];
        this.#plot_range.data.datasets[DATASET_RANGE.DISTRAW].data = [];
        this.#plot_range.data.datasets[DATASET_RANGE.DISTADJ].data = [];

        this.#plot_temp.update();
        this.#plot_humid.update();
        this.#plot_press.update();
        this.#plot_power.update();
        this.#plot_angle.update();
        this.#plot_range.update();
    }

    cyclic_push_datapoint(dataset:any[], point:any, max_points:number) {
        //Push in the newest element
        dataset.push(point);
        //Drop the first element (oldest) if it exists and we have enough points
        if(dataset.length > max_points)
            dataset.shift();
    }

    async #get_latest_data(hub_id:string, dev_id:string) {
        const readings = await this.#db.get_readings(dev_id, CosecHistorySingle.NUM_DATAPOINTS, hub_id);
        return Array.from(readings.values());
    }

    async #get_historic_data(hub_id:string, dev_id:string, date_min:Date=CommonLib.t_zero, date_max:Date=new Date(), num_samples:number) {
        let data:InterfaceReading[] = [];

        //Get total time in ms between now and the start time of the duration set
        const time_max = date_max.getTime();
        const time_min = date_min.getTime();
        const delta = time_max - time_min;

        //We're going to sample NUM_DATAPOINTS at regular intervals
        //so figure out the step size in ms between each sample
        const step = Math.floor(delta / num_samples);
        //Keep track of the last date we got (in case we run out of new data)
        let date_last = CommonLib.t_zero;

        //XXX:  Do the loop in reverse (find oldest sample first) as
        //      it will allow use to break out early if we don't find
        //      any data or we have reached the most recent sample
        for(let i=num_samples-1; i>=0; i--) {
            //Get the datetime point of our sample
            const sample_time = time_max - step*i;
            const sample_date = new Date(sample_time);

            //Check to see that this sample /should be/ newer than our last collected data point
            if(sample_date > date_last) {
                //Get the next reading (after -- closer to now) that is available just before this time point.
                const sample_readings = await this.#db.get_readings_after_asc(dev_id, sample_date, 1, hub_id);
                const data_raw = Array.from(sample_readings.values());

                //Check to see if we got any data back at all
                //If not, we have no more samples to collect, so don't bother
                if(data_raw.length <= 0)
                    break;

                //Filter out any data points that are not newer than our last sample
                //(of the 1, I know, but arrays... the compiler will figure it out)
                //This makes sure we aren't getting the same points over and over,
                //i.e. the sample /is/ newer than our last collected data point, /and/ does not exceed our maximum date
                const data_new = data_raw.filter(r => (r.info.timestamp > date_last) && (r.info.timestamp <= date_max))

                //At this point, we have new data and it's definitely good!
                if(data_new.length > 0) {
                    //Add this data to our list
                    data.push(...data_new);
                    //Update our stamp to the "latest" datapoint that was collected
                    date_last = Math.max.apply(Math, data_new.map(r => r.info.timestamp));
                }

            } //Else, we're going to get the same datapoint so don't bother
        }

        //Return the reverse of the data (newest to oldest)
        return data.reverse();
    }

    async download_current_dataset() {
        let dev_select = <HTMLSelectElement>document.getElementById('history-select-device');
        let time_select = <HTMLSelectElement>document.getElementById('history-select-time');

        const hub_id = await this.#db.get_current_hub();
        const dev_id = dev_select.value;

        if(hub_id && dev_id && time_select) {
            let lines:string[][] = [
                ["#Stamp", "Date", "Time", "ID", "Temperature", "Humidity", "Pressure", "V.Batt", "V.Solar", "Angle", "Dist.Raw", "Dist.Adj"],
            ];

            let data_period = this.#get_time_period();

            let readings:Map<string, InterfaceReading> = new Map();
            if(data_period == TIME_DATA_LENGTH.LIVE) {
                readings = await this.#db.get_readings(dev_id, CosecHistorySingle.NUM_DATAPOINTS, hub_id);
            } else {
                const date_end = new Date();
                const date_start = this.#date_start_from_period(date_end, data_period)
                //Load correct dataset for the selections
                //TODO: This could break in firestore for big datasets! Needs more testing
                readings = await this.#db.get_readings_between_asc(dev_id, date_start, date_end, 0, hub_id);
            }

            for(const [id, reading] of readings) {
                const line = [
                    reading.info.timestamp.getTime().toString(),
                    `${reading.info.timestamp.getFullYear()}/${CommonLib.zero_pad(reading.info.timestamp.getMonth(),2)}/${CommonLib.zero_pad(reading.info.timestamp.getDate(),2)}`,
                    `${CommonLib.zero_pad(reading.info.timestamp.getHours(),2)}:${CommonLib.zero_pad(reading.info.timestamp.getMinutes(),2)}:${CommonLib.zero_pad(reading.info.timestamp.getSeconds(),2)}`,
                    id,
                    reading.ambience.temperature.toString(),
                    reading.ambience.humidity.toString(),
                    reading.ambience.pressure.toString(),
                    reading.power.voltage_batt.toString(),
                    reading.power.voltage_solar.toString(),
                    reading.range.angle.toString(),
                    reading.range.distance_raw.toString(),
                    reading.range.distance_adj.toString(),
                ]

                lines.push(line);
            }

            let text = "";
            for(const line of lines) {
                text += line.join(",") + "\r\n" //Better have support for Windows...
            }

            const file = new Blob(
                [text],
                {
                    type: "text/csv;charset=utf-8"
                }
            );

            const dev_data = await this.#db.get_device_data(dev_id, hub_id);
            const suffix = dev_data.name ? `_${dev_data.name}` : `_${hub_id}_${dev_id}`;
            download_file(get_filename('csv', suffix), file);
        }
    }

    #show_selected_device_on_map() {
        let dev_select = <HTMLSelectElement>document.getElementById('history-select-device');
        if(dev_select.value)
            window.cosec_core.open_page(CosecCorePages.MAP, dev_select.value);
    }

    async #update_live_data(hub_id:string, dev_id:string) {
        const start_fresh = this.has_recent_reading();

        if(start_fresh)
            this.clear_graphs();

        const data = this.has_recent_reading() ?
            await this.#db.get_readings_before_desc(dev_id, this.get_reading_stamp(), CosecHistorySingle.NUM_DATAPOINTS, hub_id) :  //We're updating, get last recent set
            await this.#db.get_readings(dev_id, CosecHistorySingle.NUM_DATAPOINTS, hub_id) ;                                        //We're starting fresh, get the last full set

        await this.update_device_graph_data(Array.from(data.values()));
    }

    async update_device_graph_data(new_readings:InterfaceReading[]) {
        // console.log(`New data for: ${hub_id}/${dev_id}`);
        // const graph = this.#graphs.find(x => x.get_id() == dev_id);

        // let update_ambience = false;
        // let update_power = false;
        // let update_range = false;

        const np = CosecHistorySingle.NUM_DATAPOINTS;
        const latest_stamp = this.get_reading_stamp();
        let fresh_readings = new_readings.filter(r => r.info.timestamp > latest_stamp);

        for(let i = fresh_readings.length-1; i >= 0; i--) {
            const r = fresh_readings[i];

            const stamp = r.info ? r.info.timestamp : CommonLib.t_zero;

            if(r.ambience) {
                const temp = CommonLib.locale_get_temperature(r.ambience.temperature);
                const humid = r.ambience.humidity;
                const pressure = CommonLib.locale_get_pressure(r.ambience.pressure);

                this.cyclic_push_datapoint(this.#plot_temp.data.labels, stamp, np);
                this.cyclic_push_datapoint(this.#plot_temp.data.datasets[DATASET_TEMP.TEMPERATURE].data, {x: stamp, y: temp}, np);

                this.cyclic_push_datapoint(this.#plot_humid.data.labels, stamp, np);
                this.cyclic_push_datapoint(this.#plot_humid.data.datasets[DATASET_HUMID.HUMIDITY].data, {x: stamp, y: humid}, np);

                this.cyclic_push_datapoint(this.#plot_press.data.labels, stamp, np);
                this.cyclic_push_datapoint(this.#plot_press.data.datasets[DATASET_PRESS.PRESSURE].data, {x: stamp, y: pressure}, np);

                // update_ambience = true;
            }

            if(r.power) {
                const vbatt = r.power.voltage_batt;
                const vsolar = r.power.voltage_solar;

                this.cyclic_push_datapoint(this.#plot_power.data.labels, stamp, np);
                this.cyclic_push_datapoint(this.#plot_power.data.datasets[DATASET_POWER.VBATT].data, {x: stamp, y: vbatt}, np);
                this.cyclic_push_datapoint(this.#plot_power.data.datasets[DATASET_POWER.VSOLAR].data, {x: stamp, y: vsolar}, np);

                // update_power= true;
            }

            if(r.range) {
                const angle = r.range.angle;
                const distraw = CommonLib.locale_get_distance(r.range.distance_raw);
                const distadj = CommonLib.locale_get_distance(r.range.distance_adj);

                this.cyclic_push_datapoint(this.#plot_angle.data.labels, stamp, np);
                this.cyclic_push_datapoint(this.#plot_angle.data.datasets[DATASET_ANGLE.ANGLE].data, {x: stamp, y: angle}, np);

                this.cyclic_push_datapoint(this.#plot_range.data.labels, stamp, np);
                this.cyclic_push_datapoint(this.#plot_range.data.datasets[DATASET_RANGE.DISTRAW].data, {x: stamp, y: distraw}, np);
                this.cyclic_push_datapoint(this.#plot_range.data.datasets[DATASET_RANGE.DISTADJ].data, {x: stamp, y: distadj}, np);

                // update_range = true;
            }
        }

        // if(update_ambience) {
        this.#plot_temp.update();
        this.#plot_humid.update();
        this.#plot_press.update();
        // }

        // if(update_power) {
        this.#plot_power.update();
        // }

        // if(update_range) {
        this.#plot_angle.update();
        this.#plot_range.update();
        // }

        //Try get the first reading (latest reading)
        const r1 = fresh_readings.values().next().value;
        if(r1 && r1.info && r1.info.timestamp)
            this.#set_reading_stamp(r1.info.timestamp);
    }

    create_line_graph(canvas:HTMLCanvasElement, data_label:string, dataset:any, labels:string[], colors:string[]):Chart {
        let ctx = canvas.getContext('2d');

        //We'll input data during the watcher callback, but pre-fill as much as we can
        const datasets = [];
        for(let i=0; i<CommonLib.keys_from_dataset(dataset).length; i++)
            datasets.push({
                label: labels[i],
                data: <number[]>[],
                // pointRadius: 0,
                // fill: false,
                backgroundColor: colors[i],
                borderColor: colors[i],
                tension: 0.1,
            });

        let chart_type:ChartType = 'line';
        let data = {
            datasets: datasets,
            labels: <string[]>[],
        };
        let options:ChartOptions = {
            plugins: {
            //   title: {
            //     text: title,
            //     display: true,
            //     padding: {
            //         top: 10,
            //         bottom: 30
            //     }
            //   },
                legend: {
                    // position: 'top',
                    display: false
                },
            },
            scales: {
                x: {
                    type: 'time',
                    title: {
                        text: 'Time',
                        display: true
                    },
                    // ticks: {
                        //     autoSkip: true,
                        //     maxTicksLimit: 8.1
                        // display: false //this will remove only the label
                    // }
                },
                y: {
                    title: {
                        text: data_label,
                        display: true
                    }
                }
            },
        };

        let config:ChartConfiguration = {
            type: chart_type,
            data: data,
            options: options,
        };

        return new Chart(ctx, config);
    }

    async #add_device_option(hub_id:string, dev_id:string, select:HTMLSelectElement) {
        const data = await this.#db.get_device_data(dev_id, hub_id);
        let opt = document.createElement('option');
        opt.value = dev_id;
        opt.appendChild(document.createTextNode(data.name));
        select.options.add(opt);
    }

    #sort_select(select:HTMLSelectElement) {
        const optionNodes = Array.from(select.children);
        // const comparator = new Intl.Collator(lang.slice(0, 2)).compare;

        optionNodes.sort((a, b) => a.textContent.localeCompare(b.textContent));
        optionNodes.forEach((option) => select.appendChild(option));
    }

    async load_overview() {
        //Thread lock
        // while(this.#performing_update) {
        //     DBTools.delay(100);
        // }

        let dev_select = <HTMLSelectElement>document.getElementById('history-select-device');
        const current_dev = dev_select.value;

        //Figure out if we have changes to hubs or devices
        const hub_id = await this.#db.get_current_hub();
        const dev_ids = await this.#db.get_device_ids();

        //If hub or device exists and it's different, or if the IDs exist but haven't been captured
        const hub_changed = (this.#hub_id != hub_id);

        //First check to see if we should drop all selections available
        if(!hub_id || hub_changed) {
            dev_select.innerHTML = '';
        }

        const dev_changed = !dev_ids.includes(current_dev);

        //If our hub marker has reset or changed
        if(!hub_id || hub_changed || dev_changed) {
            //We've changed hub ID, clear map
            this.clear_graphs();
        }

        //If we have a valid hub selected, and we have reason to regenerate the marker
        if(hub_id && hub_changed) {
            this.#hub_id = hub_id;
        }

        //Generate options for device dropdown

        let old_opts = Array.from(dev_select.options).filter(o => !dev_ids.includes(o.value));
        for(const opt of old_opts)
            dev_select.removeChild(opt);

        //Only do the rest if we have a hub id
        if(hub_id) {
            let remaining_opts = Array.from(dev_select.options).map((o) => o.value);
            let promises:Promise<void>[] = [];
            for(const dev_id of dev_ids) {
                if(!remaining_opts.includes(dev_id)) {
                    promises.push(this.#add_device_option(hub_id, dev_id, dev_select));
                }
            }

            //Wait for all our options to add
            await Promise.all(promises);
            this.#sort_select(dev_select);

            if(dev_changed) {
                //Select first device (if available) as previous not available (or has changed)
                if(dev_select.children.length) {
                    dev_select.selectedIndex = 0;

                    //Update graph data if a device is selected
                    this.update_graphs();
                }
            } else {
                //Select the device that matches
                dev_select.value = current_dev;
                //XXX: Don't refresh, nothing has changed on this side
            }
        }
    }

	async handle_device_graph_callback(device_id:string) {
		window.cosec_core.open_page(CosecCorePages.MAP, device_id);
	}

    async update_graphs() {
        this.clear_graphs();
        this.#clear_reading_watcher();

        //Get hub id and dev id from page (db and device dropdown)
        let dev_select = <HTMLSelectElement>document.getElementById('history-select-device');
        const dev_id = dev_select.value;
        const hub_id = await this.#db.get_current_hub();

        //Get view mode from page
        let data_period = this.#get_time_period();

        if(hub_id && dev_id) {
            if(data_period == TIME_DATA_LENGTH.LIVE) {
                // const data = await this.#get_live_data(hub_id, dev_id);
                this.#set_auto_scale();
                //XXX: This will also trigger the device update
                this.#set_reading_watcher(await this.#db.watch_readings(dev_id, this.#update_live_data.bind(this, hub_id, dev_id), hub_id));
            } else {
                const date_end = new Date();
                const date_start = this.#date_start_from_period(date_end, data_period)
                //Load correct dataset for the selections
                const data = await this.#get_historic_data(hub_id, dev_id, date_start, date_end, CosecHistorySingle.NUM_DATAPOINTS);
                this.#set_scales(date_start, date_end);
                this.update_device_graph_data(data);
            }
        }
    }

    //Returns the data period in number of days, or 'TIME_DATA_LENGTH.LIVE' if live mode selected
    #get_time_period() {
        let time_select = <HTMLSelectElement>document.getElementById('history-select-time');
        return Object.keys(TIME_DATA_LENGTH).includes(time_select.value) ? TIME_DATA_LENGTH[time_select.value] : TIME_DATA_LENGTH.LIVE;
    }

    #date_start_from_period(date_end:Date = new Date(), offset_days:number) {
        const date_start = new Date();
        date_start.setDate(date_end.getDate() - offset_days);
        return date_start;
    }

    async #set_scales(min:Date, max:Date) {
        const t_min = min.getTime();
        const t_max = max.getTime();

        this.#plot_temp.scales.x.min = t_min;
        this.#plot_humid.scales.x.min = t_min;
        this.#plot_press.scales.x.min = t_min;
        this.#plot_power.scales.x.min = t_min;
        this.#plot_angle.scales.x.min = t_min;
        this.#plot_range.scales.x.min = t_min;

        this.#plot_temp.scales.x.max = t_max;
        this.#plot_humid.scales.x.max = t_max;
        this.#plot_press.scales.x.max = t_max;
        this.#plot_power.scales.x.max = t_max;
        this.#plot_angle.scales.x.max = t_max;
        this.#plot_range.scales.x.max = t_max;
    }

    async #set_auto_scale() {
        this.#plot_temp.scales.x.min = null;
        this.#plot_temp.scales.x.max = null;
        this.#plot_humid.scales.x.min = null;
        this.#plot_humid.scales.x.max = null;
        this.#plot_press.scales.x.min = null;
        this.#plot_press.scales.x.max = null;

        this.#plot_power.scales.x.min = null;
        this.#plot_power.scales.x.max = null;

        this.#plot_angle.scales.x.min = null;
        this.#plot_angle.scales.x.max = null;

        this.#plot_range.scales.x.min = null;
        this.#plot_range.scales.x.max = null;
    }

    async reset() {
        let graph_list = document.getElementById('history-graph-list');
        let graph_list_loading = document.getElementById('history-graph-list-loading');
        graph_list.style.display = 'none';
        graph_list_loading.style.display = 'flex';
    }

    async refreshPage() {
        await this.load_overview();
    }

    clearRefreshWatchers() {
        if (this.#refresh_watcher_devices) {
            this.#refresh_watcher_devices(); //Stop event listener
            this.#refresh_watcher_devices = null;
        }

        this.#clear_reading_watcher();
    }

    async refresh(device_in_focus:string=null) {
		const focus_str = device_in_focus != null ? ` (focus: ${device_in_focus})` : "";
		console.log(`Refreshing ${this.#page_id}${focus_str}!`);

        await this.reset();
        // this.#scroll_to_device = device_in_focus;

        this.clearRefreshWatchers();
        //XXX: This will also trigger our refresh
        this.#refresh_watcher_devices = await this.#db.watch_devices(this.refreshPage.bind(this));
        //Final catch to force a refresh if the "watch devices" fails
        if(!this.#refresh_watcher_devices)
            await this.refreshPage();

        let graph_list = document.getElementById('history-graph-list');
        let graph_list_loading = document.getElementById('history-graph-list-loading');
        graph_list_loading.style.display = 'none';
        graph_list.style.display = 'flex';
    }

	async hub_changed(old_current_id:string, new_current_id:string) {
		this.deactivate();
		this.refresh();
	}

    async deactivate() {
        console.log("Deactivating history!");
        this.clearRefreshWatchers();
    }
}
