import { DOMFactory } from "./dom_factory";
import { CommonLib } from "./common";
import { Chart } from "./interface_chart";
import { InterfaceDatabase } from "./interface_database";
import { ChartConfiguration, ChartData, ChartOptions, ChartType } from "chart.js";
import { CosecCorePages } from "./core";
import { InterfacePage } from "./interface_page";

class CosecSensorsDevice {
    #dev_id:string;
    #refresh_watcher:()=>void;
    #latest_reading:Date;  //XXX: Cleared when the watcher is cleared

    constructor(dev_id:string, watcher:()=>void=null) {
        this.#dev_id = dev_id;
        this.#refresh_watcher = watcher;
        this.#latest_reading = CommonLib.t_zero;
    }

    get_id() {
        return this.#dev_id;
    }

    has_recent_reading() {
        return this.#latest_reading != CommonLib.t_zero;
    }

    set_reading_stamp(stamp:Date) {
        this.#latest_reading = new Date(stamp);
    }

    get_reading_stamp() {
        return this.#latest_reading;
    }

    get_watcher() {
        return this.#refresh_watcher;
    }

    set_watcher(watcher:()=>void) {
        this.clear_watcher();
        this.#refresh_watcher = watcher;
    }

    clear_watcher() {
        if(this.#refresh_watcher) {
            this.#refresh_watcher();
            this.#refresh_watcher = null;
        }

        this.#latest_reading = CommonLib.t_zero;
    }

    destroy() {
        this.clear_watcher();
    }

}

enum CosecSensorsPlots {
    TEMPERATURE,
    HUMIDITY,
    PRESSURE,
    VBATT,
    VSOLAR,
    ANGLE,
    DISTRAW,
    DISTADJ,
    __LENGTH
};

function get_plot_labels() {
    return [
        `Temperature (${CommonLib.locale_get_temperature_unit()})`,
        "Humidity (%)",
        `Pressure (${CommonLib.locale_get_pressure_unit()})`,
        "Battery Voltage (V)",
        "Solar Voltage (V)",
        "Angle (°)",
        `Raw Distance (${CommonLib.locale_get_distance_unit()})`,
        `Adj. Distance (${CommonLib.locale_get_distance_unit()})`,
    ];
}

console.assert(get_plot_labels().length == CosecSensorsPlots.__LENGTH, "Mismatch in variable definitions (plot labels)");

export class CosecSensors implements InterfacePage {
    static UPDATE_TRIGGER_DELAY = 500; //ms

    #refresh_watcher:()=>void;
    #page_id:CosecCorePages;
    #db:InterfaceDatabase;
    #hub_id:string;
    #devices:CosecSensorsDevice[];
    #plots:Chart[];
    #plot_triggers:NodeJS.Timeout[];

    constructor(page_id:CosecCorePages, db_interface:InterfaceDatabase) {
        this.#page_id = page_id;
        this.#db = db_interface;
        this.#hub_id = null;
        this.#devices = [];
        this.#refresh_watcher = null;
        this.#plots = [];
        this.#plot_triggers = [];

        // eslint-disable-next-line no-unused-vars
        for(const i of Array(CosecSensorsPlots.__LENGTH).keys()) {
            this.#plots.push(null);
            this.#plot_triggers.push(null);
        }
    }

    async update_graph(trigger:CosecSensorsPlots) {
        //Update the plot
        this.#plots[trigger]?.update();
        this.clear_trigger(trigger);
    }

    async update_all_graphs() {
        //Collect all of our promises
        let updates = [];

        for(const i of Array(CosecSensorsPlots.__LENGTH).keys())
            updates.push(this.update_graph(i));

        await Promise.all(updates);
    }

    clear_trigger(trigger:CosecSensorsPlots) {
        //Clear the timeout if it is active (in case we fire manually)
        if(this.#plot_triggers[trigger])
            clearTimeout(this.#plot_triggers[trigger]);

        //Wipe the timeout variable to allow a new one to be set
        this.#plot_triggers[trigger] = null;
    }

    set_update_trigger(trigger:CosecSensorsPlots) {
        //Schedule an update shortly
        if(this.#plots[trigger] && !this.#plot_triggers[trigger])
            this.#plot_triggers[trigger] = setTimeout(this.update_graph.bind(this, trigger), CosecSensors.UPDATE_TRIGGER_DELAY);
    }

    clear_devices(ids:string[]) {
        for(const id of ids) {
            const ind = this.#devices.findIndex(x => x.get_id() == id);

            //Remove items from data structures
            if(ind || ind === 0) {
                const dev = this.#devices.splice(ind, 1)[0];

                //Stop watchers and remove and associated DOM
                if(dev)
                    dev.destroy();

                //Clean plots
                for(const i of Array(CosecSensorsPlots.__LENGTH).keys())
                    if(this.#plots[i])
                        this.#plots[i].data.datasets.splice(ind, 1);
            } else {
                console.warn("Could not clean up device id: " + id)
            }
        }

        //Update all plots afterwards
        this.update_all_graphs();
    }

    async update_device_graph(hub_id:string, dev_id:string) {
        // console.log(`New data for: ${hub_id}/${dev_id}`);
        const ind = this.#devices.findIndex(x => x.get_id() == dev_id);

        //If there is no match, this is an old device
        if(ind || ind === 0) {
        // if(ind === 0) {
            //Get the latest reading
            const device = this.#devices[ind];
            const r = await this.#db.get_latest_reading(dev_id, hub_id);   //We're starting fresh, get the latest reading

            //Make sure that we only update if this reading is newer than any previous ones
            if(r) {
                if(r.ambience) {
                    if(this.#plots[CosecSensorsPlots.TEMPERATURE])
                        this.#plots[CosecSensorsPlots.TEMPERATURE].data.datasets[ind].data = [
                            CommonLib.locale_get_temperature(r.ambience.temperature),
                        ];

                    if(this.#plots[CosecSensorsPlots.HUMIDITY])
                        this.#plots[CosecSensorsPlots.HUMIDITY].data.datasets[ind].data = [
                            r.ambience.humidity*100,
                        ];

                    if(this.#plots[CosecSensorsPlots.PRESSURE])
                        this.#plots[CosecSensorsPlots.PRESSURE].data.datasets[ind].data = [
                            CommonLib.locale_get_pressure(r.ambience.pressure),
                        ];

                    this.set_update_trigger(CosecSensorsPlots.TEMPERATURE);
                    this.set_update_trigger(CosecSensorsPlots.PRESSURE);
                    this.set_update_trigger(CosecSensorsPlots.HUMIDITY);
                }

                if(r.power) {
                    if(this.#plots[CosecSensorsPlots.VBATT])
                        this.#plots[CosecSensorsPlots.VBATT].data.datasets[ind].data = [
                            r.power.voltage_batt,
                        ];

                    if(this.#plots[CosecSensorsPlots.VSOLAR])
                        this.#plots[CosecSensorsPlots.VSOLAR].data.datasets[ind].data = [
                            r.power.voltage_solar,
                        ];

                    this.set_update_trigger(CosecSensorsPlots.VBATT);
                    this.set_update_trigger(CosecSensorsPlots.VSOLAR);
                }

                if(r.range) {
                    if(this.#plots[CosecSensorsPlots.ANGLE])
                        this.#plots[CosecSensorsPlots.ANGLE].data.datasets[ind].data = [
                            r.range.angle,
                        ];

                    if(this.#plots[CosecSensorsPlots.DISTRAW])
                        this.#plots[CosecSensorsPlots.DISTRAW].data.datasets[ind].data = [
                            CommonLib.locale_get_distance(r.range.distance_raw),
                        ];

                    if(this.#plots[CosecSensorsPlots.DISTADJ])
                        this.#plots[CosecSensorsPlots.DISTADJ].data.datasets[ind].data = [
                            CommonLib.locale_get_distance(r.range.distance_adj),
                        ];

                    this.set_update_trigger(CosecSensorsPlots.ANGLE);
                    this.set_update_trigger(CosecSensorsPlots.DISTRAW);
                    this.set_update_trigger(CosecSensorsPlots.DISTADJ);
                }

                //Try get the first reading (latest reading)
                if(r.info && r.info.timestamp)
                    device.set_reading_stamp(r.info.timestamp);
            }
        }
    }

    // on_chart_resize(instance, size) {
    //     instance.legend.options.display = size.width > 400;
    //     instance.update();
    // }

    create_bar_graph(canvas:HTMLCanvasElement, title:string, label:string, legend_id:string) {
        //TODO: https://www.chartjs.org/docs/3.3.2/samples/legend/html.html

        let ctx = canvas.getContext('2d');

        let chart_type:ChartType = 'bar';
        // let datasets = [];
        // for(let i=0; i<dataset.COUNT; i++)
        //     datasets.push({
        //         data: [],	//XXX: These get filled out later with the device data
        //         label: [],
        //         // backgroundColor: colors[i],
        //         // borderColor: colors[i],
        //     });

        let data:ChartData = {
            datasets: [],
            labels: [label],
        };
        let title_info = title ? {
                text: title,
                display: true,
                padding: {
                    top: 10,
                    bottom: 30
                }
            } :
            {
                display: false,
            };
        let options:ChartOptions = {
            // onResize: this.on_chart_resize,
            plugins: {
                title: title_info,
                //@ts-ignore
                htmlLegend: {
                  // ID of the container to put the legend in
                  containerID: legend_id,
                },
                legend: {
                  display: false,
                }
            },
            aspectRatio: 0,
            // scales: {
            //     x: {
            //         title: {
            //             text: label,
            //             display: true
            //         }
            //     }
            // },
            onClick: (e, elements, chart) => {
                if(elements.length) {
                    let datasetIndex = elements[0].datasetIndex;
                    // let dataIndex = activeEls[0].index;
                    //@ts-ignore XXX: Custom set ID
                    let datasetID = chart.data.datasets[datasetIndex].id;
                    // let value = e.chart.data.datasets[datasetIndex].data[dataIndex];
                    // let label = e.chart.data.labels[dataIndex];
                    // console.log("In click", datasetLabel, label, value);
                    window.cosec_core.open_page(CosecCorePages.MAP, datasetID);
                }
            }
        };

        let config:ChartConfiguration = {
            type: chart_type,
            data: data,
            options: options,
            //@ts-ignore
            plugins: [window.htmlLegendPlugin],
        };

        return new Chart(ctx, config);
    }

    async load_overview() {
        const dom_graph_list = document.getElementById('sensors-graph-list');
        const labels = get_plot_labels();
        const id_prefix = 'sensors';
        const uid_ambience = 'ambience';
        const uid_power = 'power';
        const uid_range = 'range';

        if(!document.getElementById(`${id_prefix}-${uid_ambience}-graph`)) {
            let plots = [CosecSensorsPlots.TEMPERATURE, CosecSensorsPlots.HUMIDITY, CosecSensorsPlots.PRESSURE];
            const graph = DOMFactory.get_block_graph('sensors', 'Sensor Ambience Statistics', uid_ambience, plots.length);
            for(const p of plots) {
                const canvas = graph.canvas_items.shift();
                const legend = graph.legend_items.shift();
                this.#plots[p] = this.create_bar_graph(canvas, "", labels[p], legend.id);
            }
            dom_graph_list.appendChild(graph.dom);
        }

        if(!document.getElementById(`${id_prefix}-${uid_power}-graph`)) {
            let plots = [CosecSensorsPlots.VBATT, CosecSensorsPlots.VSOLAR];
            const graph = DOMFactory.get_block_graph(id_prefix, 'Sensor Power Statistics', uid_power, plots.length);
            for(const p of plots) {
                const canvas = graph.canvas_items.shift();
                const legend = graph.legend_items.shift();
                this.#plots[p] = this.create_bar_graph(canvas, "", labels[p], legend.id);
            }
            dom_graph_list.appendChild(graph.dom);
        }

        if(!document.getElementById(`${id_prefix}-${uid_range}-graph`)) {
            let plots = [CosecSensorsPlots.ANGLE, CosecSensorsPlots.DISTRAW, CosecSensorsPlots.DISTADJ];
            const graph = DOMFactory.get_block_graph(id_prefix, 'Sensor Range Statistics', uid_range, plots.length);
            for(const p of plots) {
                const canvas = graph.canvas_items.shift();
                const legend = graph.legend_items.shift();
                this.#plots[p] = this.create_bar_graph(canvas, "", labels[p], legend.id);
            }
            dom_graph_list.appendChild(graph.dom);
        }

        //Step 1: Figure out if we have any new markers
        const hub_id = await this.#db.get_current_hub();
        const dev_ids = await this.#db.get_device_ids();

        //If hub marker exists and it's different, or if the hub ID exists and the marker doesn't
        const hub_changed = (this.#hub_id != hub_id);

        //If our hub marker has reset or changed
        if(!hub_id || hub_changed) {
            //We've changed hub ID, clear everything
            this.clear_devices(this.#devices.map(x => x.get_id()));
        }

        //If we have a valid hub selected, and we have reason to regenerate the marker
        if(hub_id && hub_changed) {
            this.#hub_id = hub_id;
        }

        const current_ids = this.#devices.map(function(device){
            return device.get_id();
        });

        const old_ids = current_ids.filter(x => !dev_ids.includes(x));
        // const old_devices = this.#devices.filter(x => old_ids.includes(x.dev_id));
        if(old_ids.length)
            this.clear_devices(old_ids);
        // }

        const new_ids = dev_ids.filter(x => !current_ids.includes(x));
        for(const id of new_ids) {
            const dev = new CosecSensorsDevice(id);
            this.#devices.push(dev);
            const dev_data = await this.#db.get_device_data(id, hub_id);
            const dev_name = dev_data ? dev_data.name : 'Device: ' + id;
            const color = CommonLib.rand_color(id);

            for(const i of Array(CosecSensorsPlots.__LENGTH).keys())
                if(this.#plots[i])
                    this.#plots[i].data.datasets.push({
                        data: [0],
                        label: dev_name,
                        backgroundColor: color,
                        borderColor: color,
                        //@ts-ignore XXX: Custom data ID
                        id: id,
                    });

            // this.#plot_ambience.data.datasets.push({
            //     data: [0,0,0],
            //     label: dev_name,
            //     backgroundColor: color,
            //     borderColor: color,
            //     id: id, //XXX: Custom data ID
            // });
        }

        //XXX: This will also trigger our initial update
        await this.update_graph_data_watchers(hub_id);
    }

    async update_graph_data_watchers(hub_id:string) {
        for(const device of this.#devices) {
            if(!device.get_watcher())
                device.set_watcher(await this.#db.watch_readings(device.get_id(), this.update_device_graph.bind(this, hub_id, device.get_id()), hub_id));
        }
    }

    async reset() {
        let graph_list = document.getElementById('sensors-graph-list');
        let graph_list_loading = document.getElementById('sensors-graph-list-loading');
        graph_list.style.display = 'none';
        graph_list_loading.style.display = 'flex';
    }

    async refreshPage() {
        // this.reset();

        await this.load_overview();
    }

    clearRefreshWatchers() {
        if (this.#refresh_watcher) {
            this.#refresh_watcher(); //Stop event listener
            this.#refresh_watcher = null;
        }

        for(const d of this.#devices) {
            d.clear_watcher();
        }

        for(const i of Array(CosecSensorsPlots.__LENGTH).keys())
            this.clear_trigger(i);
    }

    // eslint-disable-next-line no-unused-vars
    async refresh(device_in_focus:string=null) {
		const focus_str = device_in_focus != null ? ` (focus: ${device_in_focus})` : "";
		console.log(`Refreshing ${this.#page_id}${focus_str}!`);

        this.reset();

        this.clearRefreshWatchers();
        //XXX: This will also trigger our refresh
        this.#refresh_watcher = await this.#db.watch_devices(this.refreshPage.bind(this));
        //Final catch to force a refresh if the "watch devices" fails
        if(!this.#refresh_watcher)
            await this.refreshPage();

        let graph_list = document.getElementById('sensors-graph-list');
        let graph_list_loading = document.getElementById('sensors-graph-list-loading');
        graph_list_loading.style.display = 'none';
        graph_list.style.display = 'flex';
    }

	async hub_changed(old_current_id:string, new_current_id:string) {
		this.deactivate();
		this.refresh();
	}

    async deactivate() {
        console.log("Deactivating sensors!");
        this.clearRefreshWatchers();
    }
}
