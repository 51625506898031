import { CosecGeoPoint } from "../common";
import { InterfaceHubGeoFeatures } from "../db_tools";
import { InterfaceDeviceType } from "../interface_database";
import { HubDeviceLayout } from "../interface_devices";

const lng_rvr_name_prefix = "SES-LNG-RVR";
const lng_rvr_install_1_1 = new Date("2022-09-06T10:22:47.540+10:00");
const lng_rvr_install_1_2 = new Date("2022-09-06T12:27:45.960+10:00");
const lng_rvr_install_2_1 = new Date("2022-09-07T10:04:32.821+10:00");
const lng_rvr_install_2_2 = new Date("2022-09-07T11:11:20.800+10:00");
const lng_rvr_install_2_3 = new Date("2022-09-07T13:32:34.321+10:00");
const lng_rvr_review_1_1 = new Date("2023-10-03T13:45:00.000+10:00");
const lng_rvr_review_1_2 = new Date("2023-10-03T13:45:00.000+10:00");
const lng_rvr_review_2_1 = new Date("2023-10-03T13:45:00.000+10:00");
const lng_rvr_review_2_2 = new Date("2023-10-03T13:45:00.000+10:00");
const lng_rvr_review_2_3 = new Date("2023-10-03T13:45:00.000+10:00");

export const logan_river:HubDeviceLayout = {
    hub_name: lng_rvr_name_prefix,
    hub_owner: "State Emergency Service (SES)",
    hub_location: new CosecGeoPoint(-27.6384151,153.1020117),
    hub_geo_features: [InterfaceHubGeoFeatures.ENERGEX_LV_METRO_SOUTH_GROUP8, InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP4, InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP9, InterfaceHubGeoFeatures.ENERGEX_11KV_METRO_SOUTH_GROUP10],
    devices: [
        {name: lng_rvr_name_prefix + "-0001-0001", type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-27.7543381,153.1049975, 26.23), date_install:lng_rvr_install_1_1, date_review: lng_rvr_review_1_1},
        // {type: InterfaceDeviceType.MARKER, location: new CosecGeoPoint(-27.555629,153.0270528), date_install:lng_rvr_install_1, date_review: lng_rvr_install_1},
        {name: lng_rvr_name_prefix + "-0001-0003", type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-27.7035148,153.1250171, 16.17), date_install:lng_rvr_install_1_2, date_review: lng_rvr_review_1_2},
        // {type: InterfaceDeviceType.MARKER, location: new CosecGeoPoint(-27.730375341920315,153.10975033418845), date_install:lng_rvr_install_2, date_review: lng_rvr_install_2},
        {name: lng_rvr_name_prefix + "-0001-0005", type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-27.7325506,153.1097974, 25.20), date_install:lng_rvr_install_2_1, date_review: lng_rvr_review_2_1},
        {name: lng_rvr_name_prefix + "-0001-0006", type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-27.7323636,153.1093910, 24.99), date_install:lng_rvr_install_2_2, date_review: lng_rvr_review_2_2},
        {name: lng_rvr_name_prefix + "-0001-0007", type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-27.6976853,153.1282010, 18.56), date_install:lng_rvr_install_2_3, date_review: lng_rvr_review_2_3},
    ],
}
