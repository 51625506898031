import { CosecGeoPoint } from "../common";
import { InterfaceDeviceType } from "../interface_database";
import { HubDeviceLayout } from "../interface_devices";

const name_prefix = "TNS-GRD-HLW";

export const holsworthy:HubDeviceLayout = {
    hub_name: name_prefix,
    hub_owner: "TransGrid (Holsworthy)",
    hub_location: new CosecGeoPoint(-33.9683709,150.9100093),
    hub_geo_features: [],
    devices: [
        {name: name_prefix + "-TL78-1", type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-33.9738841,150.9148277, 83.8), },
        {name: name_prefix + "-TL78-2", type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-33.9737722,150.9150011, 83.8), date_install: new Date("2024-10-22T07:45:00.000+11:00") },
        {name: name_prefix + "-TL78-3", type: InterfaceDeviceType.ROTAMARKER_RW, location: new CosecGeoPoint(-33.9733504,150.9150118, 83.8), },
    ],
}
