import { icon, dom, library } from '@fortawesome/fontawesome-svg-core'
import { faBatteryHalf, faChartLine, faClockFour, faCloud, faCogs, faDownload, faDraftingCompass, faExclamationTriangle, faFlag, faMapMarkedAlt, faMinus, faMinusCircle, faNetworkWired, faPager, faPlus, faPlusCircle, faPoll, faSignOutAlt, faSun, faTape, faThermometerHalf, faTint } from '@fortawesome/free-solid-svg-icons'

// const faPlusIcon = icon(faFolderOpen)

// // Get the first element out of the HTMLCollection
// document.appendChild(faPlusIcon.node[0])

export enum CosecIcons {
    MENU_MAP,
    MENU_SENSORS,
    MENU_HISTORY,
    MENU_MANAGE,
    MENU_SETTINGS,
    MENU_LOGOUT,
    ITEM_HUB,
    ITEM_DEVICE,
    ACTION_IDENTIFY,
    ACTION_ADD_ITEM,
    ACTION_ARCHIVE_ITEM,
    ACTION_EXPAND,
    ACTION_MINIMIZE,
    ACTION_DOWNLOAD,
    DATASET_TEMPERATURE,
    DATASET_HUMIDITY,
    DATASET_PRESSURE,
    DATASET_VBATT,
    DATASET_VSOLAR,
    DATASET_ANGLE,
    DATASET_DISTRAW,
    DATASET_DISTADJ,
    TIMEOUT
}

function _cosec_get_icon_fa(cosec_icon:CosecIcons) {
    let i = null;

    switch(cosec_icon) {
        case CosecIcons.MENU_MAP: {
            i = faMapMarkedAlt;
            break
        }
        case CosecIcons.MENU_SENSORS: {
            i = faPoll;
            break;
        }
        case CosecIcons.MENU_HISTORY: {
            i = faChartLine;
            break;
        }
        case CosecIcons.MENU_MANAGE: {
            i = faNetworkWired;
            break;
        }
        case CosecIcons.MENU_SETTINGS: {
            i = faCogs;
            break;
        }
        case CosecIcons.MENU_LOGOUT: {
            i = faSignOutAlt;
            break;
        }
        case CosecIcons.ITEM_DEVICE: {
            i = faPager;
            break;
        }
        case CosecIcons.ITEM_HUB: {
            i = faNetworkWired;
            break;
        }
        case CosecIcons.ACTION_IDENTIFY: {
            i = faFlag;
            break;
        }
        case CosecIcons.ACTION_ADD_ITEM: {
            i = faPlusCircle;
            break;
        }
        case CosecIcons.ACTION_ARCHIVE_ITEM: {
            i = faMinusCircle;
            break;
        }
        case CosecIcons.ACTION_EXPAND: {
            i = faPlus;
            break;
        }
        case CosecIcons.ACTION_MINIMIZE: {
            i = faMinus;
            break;
        }
        case CosecIcons.ACTION_DOWNLOAD: {
            i = faDownload;
            break;
        }
        case CosecIcons.DATASET_TEMPERATURE: {
            i = faThermometerHalf;
            break;
        }
        case CosecIcons.DATASET_HUMIDITY: {
            i = faTint;
            break;
        }
        case CosecIcons.DATASET_PRESSURE: {
            i = faCloud;
            break;
        }
        case CosecIcons.DATASET_VBATT: {
            i = faBatteryHalf;
            break;
        }
        case CosecIcons.DATASET_VSOLAR: {
            i = faSun;
            break;
        }
        case CosecIcons.DATASET_ANGLE: {
            i = faDraftingCompass;
            break;
        }
        case CosecIcons.DATASET_DISTRAW: {
            i = faTape;
            break;
        }
        case CosecIcons.DATASET_DISTADJ: {
            i = faTape;
            break;
        }
        case CosecIcons.TIMEOUT: {
            i = faClockFour;
            break;
        }
        default: {
            break;
        }
    }

    return i;
}

export function cosec_load_dom_icons() {
    //Handle the DOM conversions for hard-coded icons
    for(const icon_name in CosecIcons) {
        const icon_id = Number(icon_name);
        if (!isNaN(icon_id)) {
            //XXX: Slight abuse of the enum, but it gets results!
            const i = _cosec_get_icon_fa(icon_id);
            if(i)
                library.add(i);
        }
    }

    dom.i2svg();
}

export function cosec_get_icon(cosec_icon:CosecIcons, is_small:boolean = false) {
    let i = _cosec_get_icon_fa(cosec_icon);
    let opts = {
        transform: {
          size: is_small ? 10 : 16,     // small is fa-2xs
        }
    }
    return i ? icon(i, opts).node : new HTMLCollection();
}
